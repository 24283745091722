// Zircon - utility functionss

import TCODES from '../data/tcodes'
import { msg } from './message';


/**
 * Safely accesses nested properties of an object.
 * 
 * @param {object} obj - The object to access properties from.
 * @param {...(string|number)} props - The nested properties to access.
 * @returns {*} The value of the nested property if found, otherwise undefined.
 */
function safeAccess(obj, ...props) {
  // Using reduce to iteratively access nested properties
  return props.reduce((accumulator, prop) => {
      // Check if the current accumulator is an array and if the current prop is an integer
      if (Array.isArray(accumulator) && Number.isInteger(prop)) {
          // If so, access the element at the specified index
          return accumulator[prop];
      } else if (accumulator && prop in accumulator) {
          // If the accumulator is an object and the prop exists, access the property
          return accumulator[prop];
      }
      // Otherwise, return undefined
      return ''; // NB! Consider returning undefined
  }, obj); // Initial accumulator is set to the provided object
}


/**
* Retrieves all values from a nested property of an object and returns them in an array.
* 
* @param {object} obj - The object to access properties from.
* @param {...(string|number)} props - The nested properties to access.
* @returns {Array} An array containing the values of the nested property.
*/
export function xTract(obj, ...props) {
  // Safely access the specified nested property
  const result = safeAccess(obj, ...props);
  
  // Check if the result is an array
  if (Array.isArray(result)) {
      // If so, return the array directly
      return result;
  } else if (typeof result === 'object' && result !== null) {
      // If the result is an object, return an array of its values
      return Object.values(result);
  } else {
      // Otherwise, wrap the single value in an array and return it
      return [result];
  }
}


// Write to console depending on program mode
export function logStatus(mode, ...messages) {
  if (mode.split(',').includes(process.env.PROGRAM_MODE)) {  // Support multiple modes
    const timestamp = new Date().toISOString();
    console.log(`${timestamp} [${mode}]`, ...messages);
  }
}

// Write to console depending on program mode
// export function logStatus(mode, message) {
//   if (mode === process.env.REACT_APP_PROGRAM_MODE) {
//     console.log(new Date(), message);
//   }
// };


// Returns a string with hh:mm:ss:ms
export const getTimeStamp = () => {
  const now = new Date();
  return `${now.toLocaleTimeString([], { hour12: false })}.${now.getMilliseconds()}`;
};


// Check, in a safe manner, if an object contains a property.
//
// export function hasProperty(obj, property) {
//   // Early return for undefined or null values
//   if (obj === undefined || obj === null || property === undefined || property === null) {
//     return false;
//   }
//   // Check if the property exists on the object itself using hasOwnProperty
//   return Object.prototype.hasOwnProperty.call(obj, property);
// }


// Check if an object contains a property using lodash.
//
// import _ from 'lodash';

// export function hasProperty(obj, property) {
//   return _.has(obj, property);
// }



// Check if a property exists recursively
//
export function hasProperty(obj, property) {
  if (obj === undefined || obj === null || property === undefined || property === null) {
    return false;
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === property) {
        return true;
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        if (hasProperty(obj[key], property)) {
          return true;
        }
      }
    }
  }
  return false;
}


// Receives a code value, such as "1" and a code, like "KdAtpLaji" and retrieves
// a matching text from TCODES. 
// TCODES includes different language versions of texts.
export function dcode(codeValue, code, lng) {

  // NB! codeValue may be an array

  let result = 'Not found';

  if (undefined === codeValue || null === codeValue || undefined === code || null === code) {
    result = '!!!';
    return;
  }
  if (Array.isArray(codeValue) && codeValue[0].length > 0 && code.length > 0) {
    const matchedCode = TCODES.find(codes => codes.codeValue === codeValue[0] && codes.code === code && codes.lng === lng) ?? null;

    if (matchedCode) {
      result = matchedCode.text;
    } else {
      result = '*' + codeValue[0];
    }
  }
  if (!Array.isArray(codeValue)) {
    const matchedCode = TCODES.find(codes => codes.codeValue === codeValue && codes.code === code && codes.lng === lng) ?? null;

    if (matchedCode) {
      result = matchedCode.text;
    } else {
      result = '**' + codeValue;
    }
  }
  // // console.log('dcode, result', result, codeValue, code, lng);
  return result;
}


// deprecate 12/23
// function codeValue(value) {
//   if (Array.isArray(value) && value[0].length > 0) {
//     return value[0];
//   }
//   return value;
// }


// Check if obj or its property is undefined or null.
export function chk(obj, propArray) {
  if (obj === undefined || obj === null) {
    return '';
  }

  let currentObj = obj;
  for (let i = 0; i < propArray.length; i++) {
    const prop = propArray[i];
    if (!currentObj.hasOwnProperty(prop)) {
      return '';
    }
    currentObj = currentObj[prop];
  }

  if (Array.isArray(currentObj)) {
    return currentObj.length > 0 ? currentObj : '';
  }

  return currentObj !== undefined ? currentObj : '';
}



// export function chk(obj, propArray) {
//   if (obj === undefined || obj === null) {
//     return '';
//   }

//   let currentObj = obj;
//   for (let i = 0; i < propArray.length; i++) {
//     const prop = propArray[i];
//     if (!currentObj.hasOwnProperty(prop)) {
//       return '';
//     }
//     currentObj = currentObj[prop];
//   }

//   return currentObj !== undefined ? currentObj : '';
// }


// export function chk(value) {

//   if (undefined === value || null === value) {
//     return '';
//   }
//   return value;
// }


export function hyphenateDateString(date) {
  if (!date || (Array.isArray(date) && date.length === 0)) {
    return ''; // Return an empty string if date is null, undefined, or an empty array
  }

  let target = Array.isArray(date) ? date[0].toString() : date.toString();

  if (target.length !== 8) {
    return ''; // Return an empty string if date format is invalid
  }

  const s = `${target.substring(0, 4)}-${target.substring(4, 6)}-${target.substring(6, 8)}`;
  return s;
}


// export function hyphenateDateString(date) {
//   var target = '';
//   if (Array.isArray(date))
//     target = date[0].toString();
//   else
//     target = date.toString();

//   let s = target.substring(0, 4) + "-" + target.substring(4, 6) + "-" + target.substring(6, 8);
//   return s.toString();
// }


export function dformat(dateString) {

  // // console.log('util, dformat, dateString', dateString, typeof (dateString));

  if (undefined === dateString) {
    return '';
  }
  if (typeof dateString === 'string' && '00' === dateString.substring(8, 10)) {
    return dateString.substring(0, 4);
  }
  const date = new Date(dateString);
  return date.toLocaleDateString('fi-FI');
}


export function compareDates(startDate, endDate) {

  let dStart = new Date(startDate);
  let dEnd = new Date(endDate);
  let elapsed = dEnd - dStart;
  let totalDays = Math.floor(elapsed / 86400000);
  let totalMonths = Math.floor(elapsed / 2592000000);
  let years = Math.floor(totalMonths / 12);
  let months = totalMonths % 12;

  return {
    totalDays,
    totalMonths,
    years,
    months
  };
}


// deprecate 12/23
// function monthsTo(targetDate) {

//   const dStart = new Date();  // obtain the current date
//   let dEnd = new Date(targetDate);
//   let elapsed = dEnd - dStart;
//   let totalMonths = Math.floor(elapsed / 2592000000);
//   // let years = Math.floor(totalMonths / 12);
//   // let months = totalMonths % 12;

//   // console.log('util, monthsTo, targetDate, dStart, dEnd, totalMonths', targetDate, dStart, dEnd, totalMonths);

//   return totalMonths;
// }


export function daysTo(targetDate) {

  const dStart = new Date();  // obtain the current date
  let dEnd = new Date(targetDate);
  let elapsed = dEnd - dStart;
  let totalDays = Math.floor(elapsed / 86400000);

  // console.log('util, daysTo, targetDate, dStart, dEnd, totalMonths', targetDate, dStart, dEnd, totalDays);

  return totalDays;
}


function addHyphen(str) {
  // Define the pattern
  const pattern = /(^[A-Z|Å|Ä|Ö]{2,3}[0-9]{1,3}$)/; // AAA111
  const patternSecondary = /(^[0-9]{1,3}[A-Z|Å|Ä|Ö]{2,3}$)/; //123ABC
  // const patternTertiary = /(^[A-Z]{1,2}[0-9]{1,4}$)/; //AA1234

  // Check if the input string matches the pattern
  if (pattern.test(str)) {
    // Add a hyphen before the first digit
    const modifiedStr = str.replace(/^([A-Z|Å|Ö]{2,3})([0-9]{1,3})$/, '$1-$2');
    return modifiedStr;
  } 
  else if (patternSecondary.test(str)) {
    // Add a hyphen before the first letter
    const modifiedStr = str.replace(/(^[0-9]{1,3})([A-Z|Ä|Ö]{2,3}$)/, '$1-$2');
    return modifiedStr;
  // } else if (patternTertiary.test(str)) {
  //   // Add a hyphen before the first letter
  //   const modifiedStr = str.replace(/(^[A-Z]{1,2}[0-9]{1,4}$)/, '$1-$2');
  //   return modifiedStr;
  } else {
    // Return the original string if it doesn't match the pattern
    return str;
  }
}


export function cleanseRegNumber(regNumber) {

  const regNumberPattern = /(^[A-Z|Å|Ä|Ö]{2,3}-[0-9]{1,3}$)/; // AAA-111
  const regNumberPatternSecondary = /(^[0-9]{1,3}-[A-Z|Å|Ä|Ö]{2,3}$)/; //123-ABC
  const regNumberPatternTertiary = /(^[A-Z]{1,2}-[0-9]{1,4}$)/; //AA-1234
  
  if (regNumberPattern.test(regNumber.toUpperCase()) || regNumberPatternSecondary.test(regNumber.toUpperCase()) || regNumberPatternTertiary.test(regNumber.toUpperCase())) {
    return regNumber.toUpperCase().trim();
  } else {
    return addHyphen(regNumber.toUpperCase().trim());
  }
}


export function regNumberOrVinIsGood(regNumber, vin) {

  const regNumberPattern = /(^[A-Z|Å|Ä|Ö]{2,3}-?[0-9]{1,3}$)/; // hyphen not required
  const regNumberPatternSecondary = /(^[0-9]{1,3}-?[A-Z|Å|Ä|Ö]{2,3}$)/; 
  const regNumberPatternTertiary = /(^[A-Z]{1,2}-?[0-9]{1,4}$)/;

  if (regNumber.length > 0) {
    if (regNumberPattern.test(regNumber) || regNumberPatternSecondary.test(regNumber) || regNumberPatternTertiary.test(regNumber)) {
      return true;
    } else {
      return false;
    }
  } else if (vin.length > 0) {
    // TODO: consider adding min/max length
    return true;
  } else {
    return false;
  }
}


export const driveType = (akseliTiedot, lng) => {

  if (!akseliTiedot) {
    // console.log('util, driveType, ei akseliTietoa');
    return '';
  }
  if (!akseliTiedot[0]?.akseli) {
    // console.log('util, driveType, ei akselia');
    return '';
  }
  if (!akseliTiedot[0]?.akseli[0]?.vetava) {
    // console.log('util, driveType, ei vetävää');
    return '';
  }    

  // TODO: check logic!
  if (akseliTiedot === undefined || !akseliTiedot[0]?.akseli[0]?.vetava || !akseliTiedot[0]?.akseli[1]?.vetava) {
    // console.log('util, nothing driving')
    return ""; // nothing driving
  } else {
    if (akseliTiedot[0].akseli[0].sijainti[0] === '1' && akseliTiedot[0].akseli[0].vetava[0] === 'true' &&
      akseliTiedot[0].akseli[1].sijainti[0] === '2' && akseliTiedot[0].akseli[1].vetava[0] === 'true') {
      return msg("Neliveto", lng);
    }
    if (akseliTiedot[0].akseli[0].sijainti[0] === '1' && akseliTiedot[0].akseli[0].vetava[0] === 'true') {
      return msg("Etuveto", lng);
    }
    if (akseliTiedot[0].akseli[1].sijainti[0] === '2' && akseliTiedot[0].akseli[1].vetava[0] === 'true') {
      return msg("Takaveto", lng);
    }
    return "";
  }
}


export const trueFalse = (value, lng) => {

  if (undefined === value || null === value) {
    return "";
  }
  if ("true" === value[0]) {
    return msg("kyllä", lng);
  }
  if ("false" === value[0]) {
    return msg("ei", lng);
  }
  else {
    return "";
  }
}


export const showDate = (msgId, value, lng) => {

  if (undefined === value || null === value) {
    return "";
  }
  return msg(msgId, lng) + " " + dformat(value);
}


export function checkForAlerts(setAlerts, auto, lng) {

  let alerts = [];
  let counter = 0;

  // TODO: rekisteröintikatsastus

  // muu kuin tavallinen poisto
  if ('poisto' in auto && Array.isArray(auto.poisto)) {
    auto.poisto.forEach(obj => {
      if ('poistonSyy' in obj && Array.isArray(obj.poistonSyy)) {
        if (obj.poistonSyy[0] !== '01') {
          alerts.push({
            id: counter++, labelMsg: 'huomMuuPoisto', value: dcode(obj.poistonSyy, 'KdRekiLiikenteestaPoistonSyy', lng) + ' ' + dformat(obj.poistonAlkupvm),
            moreInfo: true, additionalMsg: 'huomMuuPoistoSelite',
            highLight: ['Poistot'], link: '#Poistot'
          });
        }
      }
    });
  }

  // muutoskatsastus
  if ('muutoskatsastus' in auto && Array.isArray(auto.muutoskatsastus)) { // muutoskatsastus
    auto.muutoskatsastus.forEach(obj => {
      alerts.push({
        id: counter++, labelMsg: 'huomMuutoskatsastus', value: dformat(obj.$.katsastusajankohta) + ' ' + dcode(obj.katsastuspaatos, 'KdKatsaKatsastuspaatos', lng),
        moreInfo: true, additionalMsg: 'huomMuutoskatsastusSelite'
      });
    });
  }

  // autossa vaihdettuja osia
  if (hasProperty(auto, 'vaihdOsienKokonaispros')) {
    alerts.push({
      id: counter++, labelMsg: 'huomVaihdettujaOsia', value: auto.vaihdOsienKokonaispros[0],
      moreInfo: true, additionalMsg: 'huomVaihdettujaOsiaSelite'
    });
  }

  // katsastusaika mennyt ohi
  if (hasProperty(auto, 'mkAjanLoppupvm') && daysTo(auto.mkAjanLoppupvm[0]) < 0) {
    alerts.push({
      id: counter++, labelMsg: 'huomKatsastusEraantynyt', value: dformat(auto.mkAjanLoppupvm[0]),
      moreInfo: true, additionalMsg: 'huomKatsastusEraantynytSelite',
      highLight: ['Katsastus'], link: '#Katsastus'
    });
  }

  // katsastus 6 kk sisällä
  if (hasProperty(auto, 'mkAjanLoppupvm')) {
    const daysToCheck = daysTo(auto.mkAjanLoppupvm);
    if (daysToCheck > 0 && daysToCheck < 180) {
      alerts.push({
        id: counter++, labelMsg: 'huomKatsastusEraantyy', value: daysToCheck + ' (' + dformat(auto.mkAjanLoppupvm[0]) + ')',
        moreInfo: true, additionalMsg: 'huomKatsastusEraantyySelite',
        highLight: ['Katsastus'], link: '#Katsastus'
      });
    }
  }

  // autoverovapaus
  if ('rajoitustieto' in auto && Array.isArray(auto.rajoitustieto)) {
    auto.rajoitustieto.forEach(obj => {
      if ('rajoitusLaji' in obj && Array.isArray(obj.rajoitusLaji)) {
        // console.log('rajoitusLaji exists in this object:', obj.rajoitusLaji);
        if (obj.rajoitusLaji[0] === '04') {
          alerts.push({
            id: counter++, labelMsg: 'huomAutoverovapaus', value: '',
            moreInfo: true, additionalMsg: 'huomAutoverovapausSelite'
          });
        }
      } else {
        // console.log('rajoitusLaji does not exist in this object:', obj);
      }
    });
  }

  // useita tunnuksia
  if ('tunnus' in auto && Array.isArray(auto.tunnus) && auto.tunnus.length > 1) {
    alerts.push({
      id: counter++, labelMsg: 'huomUseitaTunnuksia', value: '',
      moreInfo: true, additionalMsg: 'huomUseitaTunnuksiaSelite',
      highLight: ['Tunnukset'], link: '#Tunnukset'
    });
  }

  // rakennettu ajoneuvo
  if ('rakennettuAjoneuvo' in auto && Array.isArray(auto.rakennettuAjoneuvo) && auto.rakennettuAjoneuvo[0] === 'true') {
    alerts.push({
      id: counter++, labelMsg: 'huomRakennettuAjoneuvo', value: '',
      moreInfo: true, additionalMsg: 'huomRakennettuAjoneuvoSelite'
    });
  }

  // muu kuin yksityinen käyttö tai myyntivarasto
  let muuKaytto = false;
  if ('kaytto' in auto && Array.isArray(auto.kaytto)) {
    auto.kaytto.forEach(obj => {
      if ('ajoneuvonKaytto' in obj && Array.isArray(obj.ajoneuvonKaytto)) {
        if (obj.ajoneuvonKaytto[0] !== 'Yksityinen' && obj.ajoneuvonKaytto[0] !== 'Myyntivarasto') {
          muuKaytto = true;
        }
      }
    });
    if (muuKaytto) {
      alerts.push({
        id: counter++, labelMsg: 'huomMuuKaytto', value: '',
        moreInfo: true, additionalMsg: 'huomMuuKayttoSelite',
        highLight: ['Kayttohistoria'], link: '#Käyttöhistoria'
      });
    }
  }

  // autolla on erikoisehtoja
  // if ('erikoisehto' in auto && Array.isArray(auto.erikoisehto)) {
  //   alerts.push({
  //     id: counter++, labelMsg: 'huomErikoisehto', value: '',
  //     moreInfo: true, additionalMsg: 'huomErikoisehtoSelite'
  //   });
  // }

  // yksittäin maahantuotu
  let privateImport = false;
  if ('yksittainMaahantuotu' in auto && Array.isArray(auto.yksittainMaahantuotu)
    && auto.yksittainMaahantuotu[0] === '1') {
    privateImport = true;
    alerts.push({
      id: counter++, labelMsg: 'huomYksittainTuotu', value: '',
      moreInfo: true, additionalMsg: 'huomYksittainTuotuSelite',
      highLight: ['Päivämäärät', 'ensirekisterointipvm', 'kayttoonottopvm', 'yksittainMaahantuotu'], link: '#Päivämäärät'
    });
  }

  // yksittäin maahantuotu uutena
  if ('yksittainMaahantuotu' in auto && Array.isArray(auto.yksittainMaahantuotu)
    && auto.yksittainMaahantuotu[0] === '2') {
    alerts.push({
      id: counter++, labelMsg: 'huomYksittainTuotuUutena', value: '',
      moreInfo: true, additionalMsg: 'huomYksittainTuotuUutenaSelite'
    });
  }

  // käyttöönotto ja ensirekisteröinti eroavat
  if (!privateImport && hasProperty(auto, 'kayttoonottopvm') && hasProperty(auto, 'ensirekisterointipvm')) {
    const { totalDays } = compareDates(hyphenateDateString(auto.kayttoonottopvm[0]), auto.ensirekisterointipvm[0]);
    if (totalDays > 0) {
      alerts.push({
        id: counter++, labelMsg: 'huomPvmero', value: dformat(hyphenateDateString(auto.kayttoonottopvm[0])) + ' vs. ' + dformat(auto.ensirekisterointipvm[0]),
        moreInfo: true, additionalMsg: 'huomPvmeroSelite',
        highLight: ['Päivämäärät', 'ensirekisterointipvm', 'kayttoonottopvm'], link: '#Päivämäärät'
      });
    }
  }

  // ennakkoilmoitus ja ensirekisteröinti eroavat
  if (hasProperty(auto, 'ennakkoilmoituspvm') && hasProperty(auto, 'ensirekisterointipvm')) {
    const { totalDays } = compareDates(auto.ennakkoilmoituspvm[0], auto.ensirekisterointipvm[0]);
    if (totalDays > 180) {
      alerts.push({
        id: counter++, labelMsg: 'huomEnnakkoilm', value: dformat(auto.ennakkoilmoituspvm[0]) + ' vs. ' + dformat(auto.ensirekisterointipvm[0]),
        moreInfo: true, additionalMsg: 'huomEnnakkoilmSelite'
      });
    }
  }

  setAlerts(alerts);
}