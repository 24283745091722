// Zircon - Header component.js

import React from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { msg } from './message';


function Header({ onChange, tMode, lng }) {

  const [isOpen, setIsOpen] = React.useState(false);

  let tModeIndicator = '';
  if (tMode === 'TEST') {
    tModeIndicator = tMode;
  }

  const toggleNav = () => {
    // console.log('toggleNav, isOpen', isOpen)
    setIsOpen(!isOpen)
  }

  const toggleLanguage = (event) => {
    event.preventDefault()
    // console.log('HeaderDemo, toggleLang (1), language', lng)
    if (lng === 'fi')
      onChange('sv');
    else
      onChange('fi');
    // console.log('HeaderDemo, toggleLang (2), language', lng)
  };

  return (
    <div>
      <Navbar expand="md" className="navbar-light" style={{ backgroundColor: "#8c8d93" }}>
        <NavbarToggler onClick={toggleNav} />
        <NavbarBrand className="mr-auto" href="/">
          <div>
            <img src='./autokanta.svg' height="40" width="40" alt='Autokanta*' />
            <span>Autokanta.fi {tModeIndicator}</span>
          </div>
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink className="nav-link" to='/regnumber'><span className="fa fa-home fa-lg"></span> {msg('Aloitus', lng)}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to='/terms'><span className="fa fa-info fa-lg"></span> {msg('Käyttöehdot', lng)}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to='/ask'><span className="fa fa-question fa-lg"></span> {msg('Kysymykset', lng)}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="nav-link" to='/contact'><span className="fa fa-address-card fa-lg"></span> {msg('Yhteystiedot', lng)}</NavLink>
            </NavItem>
          </Nav>
          <Nav navbar className='navbar-right'>
            <NavItem>
              <NavLink className="nav-link" to='/' onClick={toggleLanguage}>
                {lng === 'fi' ? 'sv' : 'fi'}
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Header


