// Zircon
//
// TODO: Comment Header before deploying to Cloudflare, for the time being...

// The App component is the main entry point of a React application designed for handling vehicle registration and related tasks. The component has a comprehensive structure that supports navigation, state management, and form handling, integrating several child components and functions to manage its workflow. Below is an overview of its key functionalities:

//     Imports:
//         Uses various React hooks (useState, useNavigate) and components from third-party libraries (reactstrap for UI layout).
//         Imports child components (Home, Header, RegNumber, Terms, etc.) and utility functions (preCheck, cleanseRegNumber, etc.).

//     State M

//     anagement:
//         Utilizes multiple useState hooks to track various states:
//             appStatus, language, regNumber, regType, vin, agree, promoToken, auto, moreOptions, and prevRegNumber.
//         These states are used for managing user input, tracking agreement terms, and handling vehicle data.

//     Document Title:
//         Sets the document title to "Autokanta".

//     Event Handlers:
//         handleLanguageChange: Updates the current language.
//         handleRegNumberChange: Cleanses and updates the regNumber state and resets the vin.
//         handleRegTypeChange: Updates the registration type.
//         handleAgreeChange: Manages the "agree" checkbox state.
//         handleVinChange: Updates the VIN and resets regNumber.
//         handlePromoTokenChange: Updates the promo token input.

//     Main Functionality (validateRegNumber):
//         Handles the validation process when a user submits a registration number:
//             Prevents default form submission.
//             If promoToken is non-empty, navigates to the /verifypromotoken route.
//             Calls preCheck to verify the registration number or VIN.
//             If preCheck is successful, initiates a payment process via initPaymentProcess.
//             Handles failure by showing an alert with a localized error message and navigating back to the registration page.
//             Catches and handles any unexpected errors with a general error message.

//     Routing:
//         Uses react-router-dom to define routes:
//             /: Displays the Home component.
//             /regnumber: Renders the RegNumber component for user input and validation.
//             /verifypromotoken: Validates promo tokens using the VerifyPromoToken component.
//             /verifypayment: Verifies payments via the VerifyPayment component.
//             /show: Displays vehicle information using the Show component.
//             /terms: Shows the terms and conditions using the Terms component.

//     UI Structure:
//         Includes a Header that displays the current version and provides language-changing capabilities.
//         Uses Container, Row, and Col from reactstrap to display the footer with the application version and credits.

//     Versioning:
//         The current version (zirconVersion) is defined as 0.1.21, indicating iterative deployments.

// Summary:

// The App component is a complex React component that serves as the backbone of an application for handling 
// vehicle registrations and payments. It integrates stateful logic, navigational routes, and event handlers,
// ensuring a user-friendly interface with robust error handling and external component integration.


import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';

import Start from './components/Start';
import Header from "./components/Header"
import preCheck from "./components/preCheck"
import RegNumber from "./components/RegNumber"
import Terms from "./components/Terms"
import Ask from "./components/Ask"
import Contact from "./components/Contact"
import { cleanseRegNumber } from "./components/util"
import { msg } from './components/message';
import initPaymentProcess from './components/paymentProcess';
import VerifyPromoToken from './components/VerifyPromoToken';
import VerifyPayment from './components/VerifyPayment';
import Show from './components/Show';
import { regNumberOrVinIsGood } from './components/util';


// const zirconVersion = '0.1.19'; // 2024-09-03 - deploy 2024-10-09
// const zirconVersion = '0.1.20'; // 2024-10-10
// const zirconVersion = '0.1.21'; // 2024-10-10
// const zirconVersion = '0.1.22'; // 2024-11-11
// const zirconVersion = '0.1.23'; // 2024-11-19
// const zirconVersion = '0.1.24'; // 2024-11-21
// const zirconVersion = '0.1.25'; // 2024-12-02
// const zirconVersion = '0.1.26'; // 2025-01-02
// const zirconVersion = '0.1.27'; // 2025-01-16
// const zirconVersion = '0.1.28'; // 2025-03-03
// const zirconVersion = '0.1.29'; // 2025-03-05
const zirconVersion = '0.1.30'; // 2025-03-17


// A p p  component
//
function App() {

  const navigate = useNavigate();
  // const [appStatus, setAppStatus] = useState('initial');
  const [language, setLanguage] = useState('fi');
  const [regNumber, setRegNumber] = useState('');
  const [regType, setRegType] = useState('1');
  const [vin, setVin] = useState('');
  const [agree, setAgree] = useState(false);
  const [promoToken, setPromoToken] = useState('');
  const [auto, setAuto] = useState({});
  const [moreOptions, setMoreOptions] = useState(false);
  const [prevRegNumber, setPrevRegNumber] = useState('');
  const [tMode, setTMode] = useState('TEST');

  document.title = 'Autokanta';

  // Test if running against Traficom test or prod
  useEffect(() => {
    const fetchPing = async () => {
      try {
        const pingResponse = await axios.get(process.env.REACT_APP_BACKEND_PING, { timeout: 15000 });
        if (pingResponse.data) {
          setTMode(pingResponse.data.tMode);
        }
      } catch (error) {
        alert('Initialization error ' + error);
      }
    };  
    fetchPing(); // Call the async function inside useEffect
  }, []); // Empty dependency array ensures it runs only once
  

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const handleRegNumberChange = (event) => {
    setRegNumber(cleanseRegNumber(event.target.value));
    setVin('');
  }

  const handleRegTypeChange = (event) => {
    setRegType(event.target.value);
  }

  const handleAgreeChange = (event) => {
    setAgree(event.target.checked);
  }

  const handleVinChange = (event) => {
    setVin(event.target.value);
    setRegNumber('');
  }

  const handlePromoTokenChange = (event) => {
    const value = event.target.value;
    setPromoToken(value.toString());
  };

  // If the preCheck is successful, initiate the payment process, 
  // and if the payment process fails, display an alert message and 
  // navigate back to the registration number input page. 
  // If any errors occur during the process, catch them and display 
  // a generic error message to the user
  // Note that if initPaymentProcess is successful, we never come back here.
  // Success is therefore not tested
  const validateRegNumber = async (event) => {

    event.preventDefault();

    if (!agree) {
      alert(msg('Hyväksy käyttöehdot', language));
      return;
    }

    if (!regNumberOrVinIsGood(regNumber, vin)) {
      alert(msg('Rekisterinumero virheellinen', language));
      return;
    }

    const preCheckResult = await preCheck(regNumber, vin, regType);

    if (preCheckResult.success) {

      if (promoToken.length > 0) {
        navigate('/verifypromotoken');
        return;
      }

      const initPaymentResult = await initPaymentProcess(regNumber, vin, regType);
      if (!initPaymentResult.success) { // only testing failure
        alert(msg(initPaymentResult.message, language));
        navigate('/regnumber');
      }
    } else {
      // Handle failures
      alert(msg(preCheckResult.message, language));
      return;
    }
  }

  return (
    <>
      <Header onChange={handleLanguageChange} tMode={tMode} lng={language} />
      <Routes>
        <Route path='/' element={<RegNumber regNumber={regNumber} agree={agree} handleAgreeChange={handleAgreeChange}
          handleRegNumberChange={handleRegNumberChange} validateRegNumber={validateRegNumber} vin={vin} handleVinChange={handleVinChange} regType={regType}
          handleRegTypeChange={handleRegTypeChange} promoToken={promoToken} handlePromoTokenChange={handlePromoTokenChange} moreOptions={moreOptions} setMoreOptions={setMoreOptions} lng={language} />} />
        <Route path="/regnumber" element={<RegNumber regNumber={regNumber} agree={agree} handleAgreeChange={handleAgreeChange}
          handleRegNumberChange={handleRegNumberChange} validateRegNumber={validateRegNumber} vin={vin} handleVinChange={handleVinChange} regType={regType}
          handleRegTypeChange={handleRegTypeChange} promoToken={promoToken} handlePromoTokenChange={handlePromoTokenChange} moreOptions={moreOptions} setMoreOptions={setMoreOptions} lng={language} />} />
        <Route path="/verifypromotoken" element={<VerifyPromoToken promoToken={promoToken} regNumber={regNumber} prevRegNumber={prevRegNumber} setPrevRegNumber={setPrevRegNumber} vin={vin} regType={regType} setAuto={setAuto} lng={language} />} />
        <Route path="/verifypayment" element={<VerifyPayment setAuto={setAuto} lng={language} />} />
        <Route path="show" element={<Show auto={auto} setAuto={setAuto} lng={language} />} />
        <Route path="/terms" element={<Terms lng={language} />} />
        <Route path="/ask" element={<Ask lng={language} />} />
        <Route path="/contact" element={<Contact lng={language} />} />
      </Routes>
      <Container>
        <Row>
          <Col>
            <p className="text-center mt-5">autokanta.fi by zydeemi oy 2025.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-center">({zirconVersion})</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App


// There are several areas where the App component could be improved for better readability, maintainability, and scalability:
// 1. Component Modularity

//     Extract Event Handlers: Consider moving complex event handlers like validateRegNumber to a separate utility file or custom hook (e.g., useValidation). This would keep the App component cleaner and easier to read.
//     Separate Logic from JSX: Extracting reusable UI parts or logic into subcomponents (e.g., LanguageSelector, RegForm) would make the codebase easier to maintain.

// 2. Error Handling Improvements

//     Enhanced Error Logging: Currently, error handling within validateRegNumber shows alerts with general error messages. Consider using a more robust error-handling approach, such as logging errors to a monitoring service (e.g., Sentry) for better observability.
//     User-Friendly Feedback: Instead of alert, use a modal or a toast notification to provide a better user experience.

// 3. State Management

//     State Consolidation: There are many independent useState calls. Consider consolidating related state variables into a useReducer hook or using a state management library like Redux or Recoil if the app scales further.
//     Form State Handling: Use a form management library like Formik or React Hook Form for cleaner handling of form state and validation.

// 4. Routing Structure

//     Route Protection: If there are routes that require authentication or specific conditions, implement route guards to ensure better navigation control.
//     Lazy Loading: For better performance, use React.lazy and Suspense for components that are not immediately needed. This would improve the app's initial load time.

// 5. Accessibility and Localization

//     Accessibility: Ensure the UI elements are accessible (e.g., adding aria attributes, proper labeling for inputs).
//     Improved Localization: The language state and msg function should be evaluated for extensibility. Integrating a dedicated localization library like react-intl or i18next would allow more comprehensive language support.

// 6. Documenting Code

//     Comments and Documentation: While there are some comments, adding JSDoc-style comments for functions and hooks would improve clarity and help other developers understand the codebase more quickly.
//     Consistent Logging: Replace console.log statements with a consistent logging utility that can be disabled or redirected easily.

// 7. Performance Optimizations

//     Memoization: Use React.memo or useCallback to memoize functions and components to prevent unnecessary re-renders.
//     Debouncing Inputs: For input fields like regNumber or promoToken, implement debouncing to prevent excessive re-renders or function calls during rapid input changes.

// 8. Styling and UI Enhancements

//     CSS-in-JS or Styled Components: If the app scales, consider using a CSS-in-JS solution like styled-components or Emotion for better component-scoped styling.
//     Consistent UI Framework: Ensure that the use of reactstrap aligns with the overall design language and look into customizing or theming components for a cohesive UI.

// 9. Versioning and Environment Management

//     Environment Variable Handling: Ensure all necessary environment variables are appropriately handled, and fallback logic is in place.
//     Automated Versioning: Integrate a versioning tool (e.g., semantic-release) for better control over app versioning and deployment notes.

// 10. Code Quality Enhancements

//     Type Checking: Implement TypeScript for better type safety and early detection of potential bugs.
//     Linting and Formatting: Ensure that linters (e.g., ESLint) and formatters (e.g., Prettier) are consistently applied to the codebase for uniformity and to catch errors early.

// Implementing these improvements would make the App component more scalable, maintainable, and performant while enhancing the user experience and developer productivity.
