// Zircon - Show component
//
// Display auto.

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText, CardFooter, Table, Row, Col, Button } from 'reactstrap';
import {
  hyphenateDateString, dcode, hasProperty, driveType, dformat, chk, trueFalse,
  checkForAlerts, daysTo, xTract
} from './util';
import { msg, alertMsg } from './message';


// S h o w
//
const Show = ({ auto, setAuto, lng }) => {

  // console.log(getTimeStamp(), 'Show');

  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);

  const handleGoBackClick = () => {
    navigate('/regnumber');
  };

  const handlePrint = () => {
    // Store the current document title
    const originalTitle = document.title;

    // Set a custom document title (this will become the default PDF file name)
    document.title = 'Autokanta ' + auto['tunnus']?.[0]?.rekisteritunnus?.[0];

    // Trigger the print dialog
    window.print();

    // Restore the original document title after printing
    document.title = originalTitle;
  };

  useEffect(() => {
    if (auto) {
      checkForAlerts(setAlerts, auto, lng);
    }
  }, [auto, lng]);

  // useEffect(() => {
  //   if (auto) {
  //     localStorage.setItem('autoData', JSON.stringify(auto));
  //     checkForAlerts(setAlerts, auto, lng);
  //   }
  // }, [auto, lng]);

  // useEffect(() => {
  //   if (!auto) {
  //     const savedAuto = localStorage.getItem('autoData');
  //     if (savedAuto) {
  //       setAuto(JSON.parse(savedAuto)); // Assumes `auto` is a state variable
  //     }
  //   }
  // }, []);


  if (auto === null || auto === undefined || auto.merkkiSelvakielinen === undefined) {
    // console.log('Show, exit, no auto');
    return (
      <div className="container">
        <br></br>
        <div>
          <button onClick={handleGoBackClick}>{msg('palaa alkuun', 'fi')}</button>
        </div>
        {msg('Muu virhe kyselyssä' + ' (Show 73)', lng)}
        <div>
          {msg('Palvelussa ruuhkaa tai muu', lng)}
        </div>
      </div>
    )
  }

  // check for content error
  if (hasProperty(auto, "virhekoodi")) {
    // console.log('Show, exit, virhekoodi', auto[0]?.virhekoodi?.[0], auto[0]?.virheselite?.[0]);
    return (
      <div className="container">
        <br></br>
        <div>
          <button onClick={handleGoBackClick}>{msg('palaa alkuun', 'fi')}</button>
        </div>
        {msg('Rekisterinumeroa ei löydy', lng)}
        {auto[0]?.virhekoodi?.[0]} {auto[0]?.virheselite?.[0]}
      </div>
    )
  }

  // so far so good, show vehicle details
  return (

    <div className="container">
      <br></br>

      <Row className="justify-content-end align-items-center">
        <Col xs="auto" className="button-spacing">
          <Button color="primary" onClick={handleGoBackClick}> {/* go back to beginning */}
            {msg('palaa alkuun', lng)}
          </Button>
          <Button onClick={handlePrint}> {/* print */}
            {msg('Tulosta', lng)}
          </Button>
        </Col>
      </Row>

      {Heading(auto, lng)}

      <div className="row justify-content-evenly ">
        <ShowRestrictions auto={auto} lng={lng} />
      </div>
      <div className="row justify-content-evenly ">
        <ShowAlerts alerts={alerts} lng={lng} />
      </div>
      {/* display cards side by side in two columns on a desktop screen  */}
      {/* and one on top of the other on a small screen.  */}
      <div className="row row-content">
        <div className="col-12 col-md-6">
          <ShowDates auto={auto} lng={lng} alerts={alerts} />
        </div>
        <div className="col-12 col-md-6">
          <ShowOwners auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowInspection auto={auto} lng={lng} alerts={alerts} />
        </div>
        {auto.luovutusilmoitus && (
          <div className="col-12 col-md-6">
            <ShowTransfer auto={auto} lng={lng} alerts={alerts} />
          </div>
        )}
        <div className="col-12 col-md-6">
          <ShowTech auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowConsumptionEmission auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowDeregistrations auto={auto} lng={lng} alerts={alerts} />
        </div>
        <div className="col-12 col-md-6">
          <ShowUsage auto={auto} lng={lng} alerts={alerts} />
        </div>
        <div className="col-12 col-md-6">
          <ShowInsurance auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowMileage auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowId auto={auto} lng={lng} alerts={alerts} />
        </div>
        <div className="col-12 col-md-6">
          <ShowDimensionsAndWeights auto={auto} lng={lng} />
        </div>
        <div className="col-12 col-md-6">
          <ShowAxlesAndTyres auto={auto} lng={lng} />
        </div>
        {/* <div className="col-12 col-md-6">
          <ShowSpecialTerms auto={auto} lng={lng} />
        </div> */}
        <div className="col-12 col-md-6">
          <ShowSecurityEquipment auto={auto} lng={lng} />
        </div>
      </div>
    </div >
  )
}


// H e a d i n g
//
function Heading(auto, lng) {

  const modelString = auto['mallimerkinta']?.[0]?.split(' ') || [];
  const modelFirst = modelString[0] || ''; // Default to empty string if undefined
  const modelSecond = modelString[1] || ''; // Default to empty string if undefined

  let model = '';
  if (modelFirst === '2D' || modelFirst === '3D' || modelFirst === '4D' || modelFirst === '5D') {
    model = modelSecond;
  } else {
    model = modelFirst;
  }

  let cardTitle = '';
  if (auto?.rekisteritunnus) {
    cardTitle = auto?.rekisteritunnus;
  } else {
    cardTitle = auto['tunnus']?.[0]?.rekisteritunnus?.[0];
  }
  cardTitle = cardTitle + ' ' + auto.merkkiSelvakielinen + ' ' + model;

  const fuel = (auto.kayttovoima?.[0]) ? ' ' + dcode(auto.kayttovoima, 'KdTyytiPolttoaine', lng) : '';
  const transmission = (auto.vaihteisto?.[0]) ? ' ' + dcode(auto.vaihteisto, 'KdTyytiVaihteisto', lng) : '';
  const drive = (auto.akselitiedot?.[0]) ? ' ' + driveType(auto.akselitiedot, lng) : '';
  const color = ' ' + dcode(auto['tekninen-tieto']?.[0]?.vari?.[0] ?? 'no color', 'KdTyytiVari', lng);

  // ajoneuvon tila not registered
  const vehicleStatus = (auto.tila?.[0] === '3') ? '' : ' - ' + dcode(auto.tila, 'KdAtpAjoneuvonTila', lng);

  return (
    <Card className="border-0 custom-card">
      <CardBody>
        <CardTitle className="display-4">{cardTitle}</CardTitle>
        <CardText className="card-text-content" style={{ fontSize: '18px' }}>
        </CardText>
        <Table size="sm" hover>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}> {auto.mallimerkinta} -
                {fuel}
                {transmission}
                {drive}
                {color}
                {/* {dcode(auto.kayttovoima, 'KdTyytiPolttoaine', lng)}
                {dcode(auto.vaihteisto, 'KdTyytiVaihteisto', lng)}
                {driveType(auto.akselitiedot, lng)} 
                {dcode(color, 'KdTyytiVari', lng)} */}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold' }}> {msg('kayttoonotto', lng)} {dformat(hyphenateDateString(auto.kayttoonottopvm))} {vehicleStatus}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'normal' }}> {msg('Tietolähde', lng)}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}


// S h o w R e s t r i c t i o n s
//
function ShowRestrictions(props) {
  // Highlight restrictions.

  const obj = props.auto.rajoitustieto;
  const lng = props.lng;

  if (undefined === obj || !Array.isArray(obj) || typeof obj !== 'object' || null === obj) {
    return null;
  } else {
    const serious = obj.filter(row => Number(row.vakavuus) <= 3);
    const notSerious = obj.filter(row => Number(row.vakavuus) > 3);

    return (
      <Card className='card-red-border'>
        <CardBody>
          {showTitle(notSerious > 0, msg('Rajoitukset', lng), null, null, lng)}
          <CardText className="card-text-content"></CardText>
          <Table size="sm" striped bordered hover>
            <tbody>
              {serious.map((row, index) => (
                <tr key={`serious-${index}`}>
                  <td style={{ width: '30%' }}>{dcode(row.rajoitusLaji, 'KdAtpRajoituslaji', lng)}  </td>
                  <td style={{ width: '40%' }}>{dcode(row.vakavuus, 'KdAtpRajoituksenVakavuus', lng)}</td>
                </tr>
              ))}
              {notSerious.map((row, index) => (
                  <tr key={`notSerious-${index}`}>
                    <td style={{ width: '30%' }}>{dcode(row.rajoitusLaji, 'KdAtpRajoituslaji', lng)}  </td>
                    <td style={{ width: '40%' }}>{dcode(row.vakavuus, 'KdAtpRajoituksenVakavuus', lng)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}


// // S h o w R e s t r i c t i o n s
// //
// function ShowRestrictions(props) {
//   // Highlight restrictions.
//   // Allow the user to expand content for further explanation.

//   const obj = props.auto.rajoitustieto;
//   const lng = props.lng;

//   //const [showMore, setShowMore] = useState(true);
//   const [showMore, setShowMore] = useState(() => {
//     if (!obj || !Array.isArray(obj)) return true;
//     const serious = obj.filter(row => Number(row.vakavuus) <= 3);
//     return serious.length > 0;  // Always show if there are serious restrictions
//   });

//   const toggleShowMore = () => {
//     setShowMore(!showMore);
//   };

//   if (undefined === obj || !Array.isArray(obj) || typeof obj !== 'object' || null === obj) {
//     return null;
//   } else {
//     const serious = obj.filter(row => Number(row.vakavuus) <= 3);
//     const notSerious = obj.filter(row => Number(row.vakavuus) > 3);

//     // if (serious.length < 1) { // if there a no serious ones, show all at once
//     //   setShowMore(true);
//     // }

//     return (
//       <Card className='card-red-border'>
//         <CardBody>
//           {showTitle(notSerious > 0, msg('Rajoitukset', lng), toggleShowMore, showMore, lng)}
//           <CardText className="card-text-content"></CardText>
//           <Table size="sm" striped bordered hover>
//             <tbody>
//               {serious.map((row, index) => (
//                 <tr key={`serious-${index}`}>
//                   <td style={{ width: '30%' }}>{dcode(row.rajoitusLaji, 'KdAtpRajoituslaji', lng)}  </td>
//                   <td style={{ width: '40%' }}>{dcode(row.vakavuus, 'KdAtpRajoituksenVakavuus', lng)}</td>
//                   {/* <td style={{ width: '20%' }}>{dformat(row.voimoloAlkuaika)}</td> */}
//                 </tr>
//               ))}
//               {showMore &&
//                 notSerious.map((row, index) => (
//                   <tr key={`notSerious-${index}`}>
//                     <td style={{ width: '30%' }}>{dcode(row.rajoitusLaji, 'KdAtpRajoituslaji', lng)}  </td>
//                     <td style={{ width: '40%' }}>{dcode(row.vakavuus, 'KdAtpRajoituksenVakavuus', lng)}</td>
//                     {/* <td style={{ width: '20%' }}>{dformat(row.voimoloAlkuaika)}</td> */}
//                   </tr>
//                 ))}
//             </tbody>
//           </Table>
//         </CardBody>
//         {showFooter(notSerious.length > 0, toggleShowMore, showMore, lng)}
//       </Card>
//     );
//   }
// }


// S h o w A l e r t s
//
function ShowAlerts({ alerts, lng }) {
  // Highlight alerts.
  // Allow the user to expand content for further info.

  const [showMore, setShowMore] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleRow = (rowId) => {
    // keep track of whether a row is expanded or not i.e right arrow was clicked.
    // if right arrow was clicked, then display left arrow
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  if (undefined === alerts || !Array.isArray(alerts) || typeof alerts !== 'object' || null === alerts || alerts.length < 1) {
    return;
  } else {
    return (
      <Card className='card-blue-border'>
        <CardBody>
          <CardTitle className="display-4 card-title-italic" onClick={toggleShowMore}>
            <span>{msg('Huomio', lng)}</span>
          </CardTitle>
          <CardText className="card-text-content card-content-italic">
          </CardText>
          <Table size="sm" striped bordered hover>
            <tbody>
              {alerts.map((row, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ width: '40%' }}>{msg(row.labelMsg, lng)}</td>
                    <td style={{ width: '40%' }}>{row.value}</td>
                    <td style={{ width: '10%' }}>
                      <span onClick={() => toggleRow(row.id)} className="link-text">
                        {row.moreInfo && (
                          <i className={`fa ${expandedRows[row.id] ? 'fa-arrow-left blue-arrow' : 'fa-arrow-right blue-arrow'}`} />
                        )}
                      </span>
                    </td>
                  </tr>
                  {row.moreInfo && expandedRows[row.id] && (
                    <tr key={`b-${index}`}>
                      <td colSpan="3">* {msg(row.additionalMsg, lng)}
                        {row.link !== undefined && ( // link to relevant card
                          <a href={row.link}>{msg('jump here', lng)}</a>
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </CardBody>
        <CardFooter onClick={toggleShowMore} className="card-content-italic">
          {msg('autokanta-analyysi', lng)}
        </CardFooter>
      </Card>
    );
  }
}


// S h o w  D a t e s
//
function ShowDates({ auto, lng, alerts }) {

  const [showMore, setShowMore] = useState(false);
  const [rowsToRender, setRowsToRender] = useState([]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {

    let vehicleGroup = '';
    if (auto?.ajoneuvoryhmat) {
      // console.log('***', auto.ajoneuvoryhmat[0].ajoneuvoryhma[0])
      for (let i = 0; i < auto.ajoneuvoryhmat[0].ajoneuvoryhma[0].length; i++) {
        // console.log('***', auto.ajoneuvoryhmat[0].ajoneuvoryhma[i])
        const temp = dcode(auto.ajoneuvoryhmat[0].ajoneuvoryhma[i], 'KdTyytiAjoneuvoryhma', lng);
        if (temp) {
          vehicleGroup = vehicleGroup + temp + ' ';
        }
      }
    }

    const rows = [
      { id: 1, label: alertMsg(alerts, 'ennakkoilmoituspvm', lng), value: dformat(auto.ennakkoilmoituspvm) },
      { id: 2, label: alertMsg(alerts, 'ensirekisterointi', lng), value: dformat(auto.ensirekisterointipvm) },
      { id: 3, label: alertMsg(alerts, 'kayttoonotto', lng), value: dformat(hyphenateDateString(auto.kayttoonottopvm)) },
      { id: 4, label: msg('tila', lng), value: dcode(auto.tila, 'KdAtpAjoneuvonTila', lng) },
      { id: 5, label: msg('Käyttö', lng), value: dcode(auto.ajoneuvonKaytto, 'KdRekiAjoneuvoKaytto', lng) },
      { id: 6, label: msg('ajoneuvoluokka', lng), value: dcode(auto.ajoneuvoluokka, 'KdTyytiAjoneuvoluokka', lng) },
      // { id: 7, label: msg('ajoneuvoryhmä', lng), value: dcode(auto.ajoneuvoryhmat?.[0]?.ajoneuvoryhma[0], 'KdTyytiAjoneuvoryhma', lng) },
      { id: 8, label: msg('ajoneuvoryhmä', lng), value: vehicleGroup },
      { id: 9, label: msg('valmistenumero', lng), value: auto.valmistenumero },
      { id: 10, label: msg('rektodistus1', lng), value: dformat(auto.rekisterointitod1Osa?.[0].tulostuspaiva) + ' / ' + auto.rekisterointitod1Osa?.[0]?.jarjestysnumero ?? '' },
      { id: 11, label: msg('rektodistus2', lng), value: dformat(auto.rekisterointitod2Osa?.[0].tulostuspaiva) + ' / ' + auto.rekisterointitod2Osa?.[0]?.jarjestysnumero ?? '' },
      { id: 12, label: msg('yksittäinmaahantuotu', lng), value: dcode(auto.yksittainMaahantuotu, 'KdKatsaYksittainMaahantuotu', lng) },
      { id: 13, label: msg('tuontimaa', lng), value: dcode(auto.tuontimaa, 'Maat', lng) },
      { id: 14, label: msg('ulkomainenRekisteritunnus', lng), value: auto.ulkomainenRekisteritunnus },
      { id: 15, label: msg('tyyppihyväksyntä', lng), value: auto.tyyppihyvaksyntanro },
      { id: 16, label: msg('variantti', lng), value: auto.variantti },
      { id: 17, label: msg('versio', lng), value: auto.versio },
    ]

    const tempRowsToRender = [];

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      let isSpecialRow = false;
      if (row.label.includes('!')) { // display with highlight color
        isSpecialRow = true;
      } else {
        isSpecialRow = false;
      }
      if (row.value) { // skip rows with empty value
        tempRowsToRender.push(
          <tr key={row.id} className={isSpecialRow ? 'special-row' : 'regular-row'}>
            <td style={{ width: '30%' }}>{row.label}</td>
            <td style={{ width: '50%' }}>{row.value}</td>
          </tr> // Remove any extra whitespace here
        );
      }
    }
    setRowsToRender(tempRowsToRender);
  }, [alerts, auto, lng]);

  return (
    <Card id='Päivämäärät' className="bold-border-card" tabIndex="0">
      <CardBody>
        {showTitle(false, alertMsg(alerts, 'Päivämäärät', lng), toggleShowMore, showMore, lng)}
        <Table size="sm" striped bordered hover><tbody>{rowsToRender}</tbody></Table>
      </CardBody>
    </Card>
  );
}



// a n a l y z e C u r r e n t O w n e r s
//
function analyzeCurrentOwners(setCurrentOwners, allOwners, lng) {

  if (undefined === allOwners || null === allOwners) {
    return;
  }

  let currentOwnersModified = [];

  // missing suhteenLoppupvm means that this owner is current
  // except when omistajuustyyppi is 08 (previous owner) or 09 (prev user)
  allOwners.forEach(element => {

    if (element.omistajuustyyppi.includes('08') || element.omistajuustyyppi.includes('09')) {
      // console.log('*** current owner 1', element.sukunimiPaayksikko, element.omistajuustyyppi);
    } else {
      if (!element.hasOwnProperty('suhteenLoppupvm')) { // no end day - it's current
        // console.log('*** current owner 2', element.sukunimiPaayksikko, element.omistajuustyyppi);
        if (element.$.luovutusrajoitus === '1') { // don't show name etc.
          const { sukunimiPaayksikko: newSukunimiPaayksikko, ...rest } = element;
          const modifiedSukunimiPaayksikko = msg('ei saatavilla', lng);
          currentOwnersModified.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
        }
        else if (element.hasOwnProperty('kuolinpaiva')) {
          const { sukunimiPaayksikko: newSukunimiPaayksikko, ...rest } = element;
          const modifiedSukunimiPaayksikko = element.sukunimiPaayksikko + ' ' + msg('kuolinpesä', lng);
          currentOwnersModified.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
        } else {
          currentOwnersModified.push(element);
        }
      }
    }
  });
  setCurrentOwners(currentOwnersModified);
}


// a n a l y z e P r e v i o u s O w n e r s
//
function analyzePreviousOwners(setPreviousOwners, allOwners, lng) {

  if (undefined === allOwners || null === allOwners) {
    return;
  }

  const currentDate = new Date();  // obtain the current date
  const yearMinusFive = currentDate.getFullYear() - 5;  // extract the year, subtract 5 years
  const year = yearMinusFive.toString();  // convert the year to a string
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // convert month to zero-padded string
  const day = currentDate.getDate().toString().padStart(2, '0');  // convert day to zero-padded string
  const dateMinusFiveYears = year + '-' + month + '-' + day;

  // filter previous owners from all owners
  // a previous owner has suhteenLoppupvm or omistajuustyyppi is either 08 or 09 
  const previousOwners = allOwners.filter(ownr => ownr.suhteenLoppupvm !== undefined || ownr.omistajuustyyppi.includes('08') || ownr.omistajuustyyppi.includes('09'));

  let previousOwnersMinusName = [];
  let previousOwnersMinusNameEnhanced = [];
  let nbrOfPreviousPrivateOwners = 0;

  // walk through the previous owners array, remove etunimiAlayksikko, lahiosoite etc. when
  // asiakaslaji '0' (private person) unless it's second previous owner whose ownership ended less
  // than five years ago. also, modify sukunimiPaayksikko, replace the name with text 'yksityishenkilö'
  previousOwners.forEach(element => {
    if (!element.asiakaslaji || element.asiakaslaji?.includes('0')) { // it's a private person. asiakaslaji may be missing. this is interpreted as private person
      if (element.omistajuustyyppi.includes('01') || element.omistajuustyyppi.includes('02') || element.omistajuustyyppi.includes('05')) { // it's an owner
        // || element.omistajuustyyppi.includes('05') || element.omistajuustyyppi.includes('08')) { 
        nbrOfPreviousPrivateOwners++;
        if (nbrOfPreviousPrivateOwners > 1 || ((element.hasOwnProperty('suhteenLoppupvm') && element.suhteenLoppupvm[0] < dateMinusFiveYears))) { // remove name etc.
          // if (nbrOfPreviousPrivateOwners > 1 || ((element.hasOwnProperty('suhteenLoppupvm') && element.suhteenLoppupvm[0] < dateMinusFiveYears))) { // remove name etc.
          const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
          // modify sukunimiPaayksikko
          const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
          // a new object with modified properties
          previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
        } else {
          previousOwnersMinusName.push(element);
        }
      } else if (element.omistajuustyyppi.includes('03') || element.omistajuustyyppi.includes('04')) { // it's a user
        nbrOfPreviousPrivateOwners++;
        // nbrOfPreviousPrivateUsers++;
        if (nbrOfPreviousPrivateOwners > 1 || ((element.hasOwnProperty('suhteenLoppupvm') && element.suhteenLoppupvm[0] < dateMinusFiveYears))) { // remove name etc.
          const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
          // modify sukunimiPaayksikko
          const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
          // a new object with modified properties
          previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
        } else {
          previousOwnersMinusName.push(element);
        }
      } else if (element.omistajuustyyppi.includes('08') || element.omistajuustyyppi.includes('09')) { // it's a private owner or user from old LTJ
        nbrOfPreviousPrivateOwners++;
        const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
        const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
        previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
      } else {
        previousOwnersMinusName.push(element);
      }
    } else { // it's not a private person - data is not modified
      previousOwnersMinusName.push(element);
    }
  });

  // add 'kuolinpesä' to name unless name was previously set to 'yksityishenkilö'
  previousOwnersMinusName.forEach(element => {
    if (element.hasOwnProperty('kuolinpaiva') && element.sukunimiPaayksikko !== msg('yksityishenkilö', lng)) {
      const { sukunimiPaayksikko: newSukunimiPaayksikko, ...rest } = element;
      const modifiedSukunimiPaayksikko = element.sukunimiPaayksikko + ' ' + msg('kuolinpesä', lng);
      previousOwnersMinusNameEnhanced.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
    } else {
      previousOwnersMinusNameEnhanced.push(element);
    }
  });
  setPreviousOwners(previousOwnersMinusNameEnhanced);

  // console.log('previousOwnersMinusNameEnhanced', previousOwnersMinusNameEnhanced);
  return;
}


// c h e c k P r e v i o u s O w n e r s
//
// function checkPreviousOwners(setPreviousOwners, allOwners, lng) {

//   if (undefined === allOwners || null === allOwners) {
//     return;
//   }

//   const currentDate = new Date();  // obtain the current date
//   const yearMinusFive = currentDate.getFullYear() - 5;  // extract the year, subtract 5 years
//   const year = yearMinusFive.toString();  // convert the year to a string
//   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // convert month to zero-padded string
//   const day = currentDate.getDate().toString().padStart(2, '0');  // convert day to zero-padded string
//   const dateMinusFiveYears = year + '-' + month + '-' + day;

//   // filter previous owners from all owners
//   // a previous owner has (suhteenLoppupvm and omistajuustyyppi 1, 2, 5, 6, 7) or omistajuustyyppi is either 08
//   const previousOwners = allOwners.filter(ownr => (ownr.suhteenLoppupvm !== undefined &&
//     (ownr.omistajuustyyppi.includes('01') ||  // 1. om.
//       ownr.omistajuustyyppi.includes('02') ||  // muu om.
//       ownr.omistajuustyyppi.includes('05') ||  // väliom.
//       ownr.omistajuustyyppi.includes('06') ||  // jällleenmyyjä
//       ownr.omistajuustyyppi.includes('07')))   // vastaanottaja
//     || ownr.omistajuustyyppi.includes('08')); // entinen om.

//   // filter previous users from all owners
//   // a previous user has (suhteenLoppupvm and omistajuustyyppi 3, 4) or omistajuustyyppi is either 08
//   const previousUsers = allOwners.filter(ownr => (ownr.suhteenLoppupvm !== undefined &&
//     (ownr.omistajuustyyppi.includes('03') ||  // 1. halt.
//       ownr.omistajuustyyppi.includes('04')))   // muu halt.
//     || ownr.omistajuustyyppi.includes('09')); // entinen halt.

//   // filter previous from current
//   const allPrevious = allOwners.filter(ownr => (ownr.suhteenLoppupvm !== undefined ||
//     ownr.omistajuustyyppi.includes('08') || ownr.omistajuustyyppi.includes('09')));


//   const uniqueStartDates = allPrevious.filter((item, index, array) => array.findIndex(t => t.suhteenAlkupvm[0] === item.suhteenAlkupvm[0]) === index)

//   setPreviousOwners(uniqueStartDates);

//   // let previousOwnersMinusName = [];
//   // let previousOwnersMinusNameEnhanced = [];
//   // let nbrOfPreviousPrivateOwners = 0;
//   // let nbrOfPreviousPrivateUsers = 0;

//   // // walk through the previous owners collection, remove etunimiAlayksikko, lahiosoite etc. when
//   // // asiakaslaji '0' (private person) unless it's second previous owner whose ownership ended less
//   // // than five years ago. also, modify sukunimiPaayksikko, replace the name with text 'Private person'
//   // previousOwners.forEach(element => {
//   //   if (element.asiakaslaji?.includes('0')) { // it's a private person
//   //     if (element.omistajuustyyppi.includes('01') || element.omistajuustyyppi.includes('02')) { // it's an owner
//   //       // || element.omistajuustyyppi.includes('05') || element.omistajuustyyppi.includes('08')) { 
//   //       nbrOfPreviousPrivateOwners++;
//   //       if (nbrOfPreviousPrivateOwners > 1 || element.suhteenLoppupvm[0] < dateMinusFiveYears) { // remove name etc.
//   //         const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
//   //         // modify sukunimiPaayksikko
//   //         const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
//   //         // a new object with modified properties
//   //         previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
//   //       } else {
//   //         previousOwnersMinusName.push(element);
//   //       }
//   //     } else if (element.omistajuustyyppi.includes('03') || element.omistajuustyyppi.includes('04')) { // it's a user
//   //       nbrOfPreviousPrivateUsers++;
//   //       if (nbrOfPreviousPrivateUsers > 1 || ((element.hasOwnProperty('suhteenLoppupvm') && element.suhteenLoppupvm[0] < dateMinusFiveYears))) { // remove name etc.
//   //         const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
//   //         // modify sukunimiPaayksikko
//   //         const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
//   //         // a new object with modified properties
//   //         previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
//   //       } else {
//   //         previousOwnersMinusName.push(element);
//   //       }
//   //     } else if (element.omistajuustyyppi.includes('08') || element.omistajuustyyppi.includes('09')) { // it's a private owner or user from old LTJ
//   //       nbrOfPreviousPrivateUsers++;
//   //       if (nbrOfPreviousPrivateUsers > 1 || ((element.hasOwnProperty('suhteenLoppupvm') && element.suhteenLoppupvm[0] < dateMinusFiveYears))) { // remove name etc.
//   //         const { sukunimiPaayksikko: newSukunimiPaayksikko, etunimiAlayksikko, lahiosoite, postinumero, postitoimipaikka, ...rest } = element;
//   //         // modify sukunimiPaayksikko
//   //         const modifiedSukunimiPaayksikko = msg('yksityishenkilö', lng);
//   //         // a new object with modified properties
//   //         previousOwnersMinusName.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
//   //       }
//   //     } else {
//   //       previousOwnersMinusName.push(element);
//   //     }
//   //     // } else { // it's neither owner nor user
//   //     //   previousOwnersMinusName.push(element);
//   //     // }
//   //   } else { // it's not a private person - data is not modified
//   //     previousOwnersMinusName.push(element);
//   //   }
//   // });

//   // previousOwnersMinusName.forEach(element => {
//   //   if (element.hasOwnProperty('kuolinpaiva') && element.sukunimiPaayksikko !== msg('yksityishenkilö', lng)) {
//   //     const { sukunimiPaayksikko: newSukunimiPaayksikko, ...rest } = element;
//   //     const modifiedSukunimiPaayksikko = element.sukunimiPaayksikko + ' ' + msg('kuolinpesä', lng);
//   //     previousOwnersMinusNameEnhanced.push({ ...rest, sukunimiPaayksikko: modifiedSukunimiPaayksikko });
//   //   } else {
//   //     previousOwnersMinusNameEnhanced.push(element);
//   //   }
//   // });
//   // setPreviousOwners(previousOwnersMinusNameEnhanced);

//   return;
// }


// S h o w O w n e r s
//
function ShowOwners({ auto, lng }) {

  const [showMore, setShowMore] = useState(false);
  const [currentOwners, setCurrentOwners] = useState([]);
  const [previousOwners, setPreviousOwners] = useState([]);
  // const [obj, setObj] = useState({"$":{"luovutusrajoitus":"0"},"sukunimiPaayksikko":["Sonninenä"],"etunimiAlayksikko":["Aukusti"],"asiakaslaji":["0"],"omistajuustyyppi":["01"],"suhteenAlkupvm":["2018-12-19"],"markkinointikielto":["false"],"lahiosoite":["Kairatie 29 as 2"],"postinumero":["96100"],"postitoimipaikka":["ROVANIEMI"],"asiointikieli":["fi"]});

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const obj = auto.omistajaHaltija;
  // const obj = [{"$":{"luovutusrajoitus":"0"},"sukunimiPaayksikko":["Sonninenä"],"etunimiAlayksikko":["Aukusti"],"asiakaslaji":["0"],"omistajuustyyppi":["01"],"suhteenAlkupvm":["2018-12-19"],"markkinointikielto":["false"],"lahiosoite":["Kairatie 29 as 2"],"postinumero":["96100"],"postitoimipaikka":["ROVANIEMI"],"asiointikieli":["fi"]}]

  // current owners/users
  //const currentOwners = obj.filter(ownr => ownr.suhteenLoppupvm === undefined);

  // previous owners/users - this data is modified in analyzePreviousOwners
  useEffect(() => {
    analyzeCurrentOwners(setCurrentOwners, obj, lng);
    // checkPreviousOwners(setPreviousOwners, obj, lng);
    analyzePreviousOwners(setPreviousOwners, obj, lng);
  }, [obj, lng]);

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Omistajat ja haltijat', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card>
      <CardBody>
        {showTitle(previousOwners.length > 0, msg('Omistajat ja haltijat', lng), toggleShowMore, showMore, lng)}
        <CardText className="card-text-content" >
        </CardText>
        <Table size="sm" striped bordered hover>
          <tbody>
            {currentOwners.map((row, index) => (
              <React.Fragment key={`a-${index}`}>

                <tr>
                  <td style={{ width: '50%' }}>{row.sukunimiPaayksikko}, {row.etunimiAlayksikko}</td>
                  <td style={{ width: '50%' }}></td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>{dcode(row.omistajuustyyppi, 'KdRekiOmistajuusTyyppi', lng)} {msg('alkaen', lng)}</td>
                  <td style={{ width: '50%' }}>{dformat(row.suhteenAlkupvm)}</td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>{row.lahiosoite}</td>
                  <td style={{ width: '50%' }}>{row.postinumero} {row.postitoimipaikka}</td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>{row.omistajanTunnus ? msg('Tunnus', lng) : ''}</td>
                  <td style={{ width: '50%' }}>{row.omistajanTunnus ? row.omistajanTunnus[0] : ''}</td>
                </tr>
                {/* <tr><br></br></tr> */}
                <tr>
                  <td colSpan="2" style={{ height: '10px' }}></td>
                </tr>
              </React.Fragment>
            ))}

            {/* line separator */}
            <tr>
              <td colSpan="2" style={{ padding: '0' }}>
                <hr style={{ margin: '0' }} />
              </td>
            </tr>

            {showMore &&
              previousOwners.map((row, index) => (
                <React.Fragment key={`b-${index}`}>

                  {/* line separator */}
                  {index > 0 && row.suhteenAlkupvm && previousOwners[index - 1].suhteenAlkupvm && row.suhteenAlkupvm[0] < previousOwners[index - 1].suhteenAlkupvm[0] && (
                    <tr>
                      <td colSpan="2" style={{ padding: '0' }}>
                        <hr style={{ margin: '0' }} />
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td style={{ width: '50%' }}>{dcode(row.omistajuustyyppi, 'KdRekiOmistajuusTyyppi', lng)} {msg('ajalla', lng)}</td>
                    <td style={{ width: '50%' }}>{dformat(row.suhteenAlkupvm)} - {dformat(row.suhteenLoppupvm)}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%' }}>{row.sukunimiPaayksikko}, {row.etunimiAlayksikko}</td>
                    <td style={{ width: '50%' }}></td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%' }}>{row.lahiosoite}</td>
                    <td style={{ width: '50%' }}>{row.postinumero} {row.postitoimipaikka}</td>
                  </tr>
                  <tr>
                    <td style={{ width: '50%' }}>{row.omistajanTunnus ? msg('Tunnus', lng) : ''}</td>
                    <td style={{ width: '50%' }}>{row.omistajanTunnus ? row.omistajanTunnus[0] : ''}</td>
                  </tr>
                  {/* <tr>
                    <td colSpan="2" style={{ height: '10px' }}></td>
                  </tr> */}
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </CardBody>
      {/* {showFooter(previousOwners.length > 0, toggleShowMore, showMore, lng)} */}
      <CardFooter onClick={toggleShowMore} className="card-footer-link">
        {showMore ? msg('näytä vähemmän', lng) : msg('näytä lisää', lng) + msg('aikaisempia', lng) + previousOwners.length}
      </CardFooter>
    </Card>
  )
}


// S h o w I n s p e c t i o n
//
function ShowInspection({ auto, lng, alerts }) {

  const obj = auto.katsastus;
  let alert = '';
  if (hasProperty(auto, 'mkAjanLoppupvm') && daysTo(auto.mkAjanLoppupvm[0]) < 0) {
    alert = msg('huomKatsastusEraantynyt', lng);
  }
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Katsastus', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card id='Katsastus'>
      <CardBody>
        {showTitle(obj.length > 2, alertMsg(alerts, 'Katsastus', lng), toggleShowMore, showMore, lng)}
        <CardText className="card-text-content">
        </CardText>
        {alert}
        <Table size="sm" striped bordered hover>
          <tbody>
            <tr key={0}>
              <td style={{ width: '20%' }}>{dformat(auto.katsastusajankohta)}</td>
              <td style={{ width: '80%' }}>{dcode(auto.katsastuspaatos, 'KdKatsaKatsastuspaatos', lng)}, {msg('katsastusajankohta', lng)}</td>
            </tr>
            <tr key={1}>
              <td style={{ width: '20%' }}>{dformat(auto.mkAjanAlkupvm)}</td>
              <td style={{ width: '80%' }}>{msg('mkAjanAlkupvm', lng)}</td>
            </tr>
            <tr key={2}>
              <td style={{ width: '20%' }}>{dformat(auto.mkAjanLoppupvm)}</td>
              <td style={{ width: '80%' }}>{msg('mkAjanLoppupvm', lng)}</td>
            </tr>
            {obj.slice(0, 2).map((row, index) => (
              <tr key={`a-${index}`}>
                <td style={{ width: '20%' }}>{dformat(row.$.katsastusajankohta)}</td>
                <td style={{ width: '80%' }}>{dcode(row.katsastuspaatos, 'KdKatsaKatsastuspaatos', lng)}, {dcode(row.katsastuslaji, 'KdKatsaKatsastuslaji', lng).toLowerCase()}</td>
              </tr>
            ))}
            {showMore &&
              obj.slice(2).map((row, index) => (
                <tr key={`b-${index}`}>
                  <td style={{ width: '20%' }}>{dformat(row.$.katsastusajankohta)}</td>
                  <td style={{ width: '80%' }}>{dcode(row.katsastuspaatos, 'KdKatsaKatsastuspaatos', lng)}, {dcode(row.katsastuslaji, 'KdKatsaKatsastuslaji', lng).toLowerCase()}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w T r a n s f e r
//
function ShowTransfer({ auto, lng, alerts }) {

  const obj = auto.luovutusilmoitus;

  // console.log('ShowTransfer');

  if (undefined === obj || null === obj) {
    // console.log('ShowTransfer, auto.luovutusilmoitus undefined');
    return;
  }

  return (
    <Card id="Luovutus">
      <CardBody>
        {showTitle(false, alertMsg(alerts, 'Luovutus', lng), null, null, lng)}
        <CardText className="card-text-content"></CardText>
        <Table size="sm" striped bordered hover>
          <tbody>
            {obj.map((row, index) => (
              <React.Fragment key={`a-${index}`}>
                <tr>
                  <td style={{ width: '50%' }}>
                    <strong>{dcode(row.luovutusLaji, 'KdRekiLuovutusLaji', lng)}</strong>
                  </td>
                  <td style={{ width: '50%' }}>
                    {row.luovutusilmoituspvm}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>{'- ' + msg('Luovutusjärjestys', lng)}</td>
                  <td style={{ width: '50%' }}>{row.luovutusjarjestys}</td>
                </tr>
                <tr>
                  <td style={{ width: '50%' }}>{'- ' + msg('Ostaja', lng)}</td>
                  <td style={{ width: '50%' }}>
                    {/* Loop through ostajat and ostaja arrays */}
                    {row.ostajat && row.ostajat.map((ostajaGroup, ostajaGroupIndex) => (
                      // <div key={`ostajaGroup-${ostajaGroupIndex}`}>
                      <React.Fragment key={`ostajaGroup-${ostajaGroupIndex}`}>
                        {ostajaGroup.ostaja.map((ostaja, ostajaIndex) => (
                          <div key={`ostaja-${ostajaIndex}`}>
                            <div>
                              {ostaja.sukunimiPaayksikko[0]}{' '}
                              {ostaja.etunimiAlayksikko
                                ? ostaja.etunimiAlayksikko[0]
                                : ''}
                            </div>
                            {/* <tr> */}
                            <div>
                              {ostaja.lahiosoite
                                ? ostaja.lahiosoite[0]
                                : ''}
                            </div>
                            {/* </tr> */}
                            <div>
                              {/* <tr> */}
                              <td>
                                {ostaja.postinumero
                                  ? ostaja.postinumero[0] + ' '
                                  : ''}
                                {ostaja.postitoimipaikka
                                  ? ostaja.postitoimipaikka[0]
                                  : ''}
                              </td>
                              {/* </tr> */}
                            </div>
                          </div>
                        ))}
                      {/* </div> */}
                      </React.Fragment>
                    ))}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}


// S h o w T e c h
//
function ShowTech(props) {

  const auto = props.auto;
  const lng = props.lng;

  const rows = [
    { id: 11, label: msg('yksittaisKayttovoima', lng), value: dcode(auto.kayttovoima, 'KdTyytiPolttoaine', lng) },
    { id: 12, label: msg('suurinNettoteho', lng), value: auto.suurinNettoteho },
    { id: 13, label: msg('iskutilavuus', lng), value: auto.iskutilavuus },
    { id: 14, label: msg('sylintereidenLkm', lng), value: auto.sylintereidenLkm },
    { id: 15, label: msg('vaihteisto', lng), value: dcode(auto.vaihteisto, 'KdTyytiVaihteisto', lng) },
    { id: 16, label: msg('vaihteidenLkm', lng), value: auto.vaihteidenLkm },
    { id: 17, label: msg('ahdin', lng), value: trueFalse(auto.ahdin, lng) },
    { id: 18, label: msg('valijaahdytin', lng), value: trueFalse(auto.valijaahdytin, lng) },
    { id: 19, label: msg('huippunopeus', lng), value: auto.huippunopeus },
    { id: 20, label: msg('sahkohybridi', lng), value: trueFalse(auto.sahkohybridi, lng) },
    { id: 21, label: msg('sahkohybridinluokka', lng), value: dcode(auto.sahkohybridinluokka, 'KdTyytiSahkoHybridinLuokka', lng) },
  ];

  const tempRowsToRender = [];

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    if (row.value) { // skip rows with empty value
      tempRowsToRender.push(row);
    }
  }

  const [showMore, setShowMore] = useState(true);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Card>
      <CardBody>
        {showTitle(true, msg('Tekniikka', lng), toggleShowMore, showMore, lng)}
        <CardText className="card-text-content">
        </CardText>
        {/* This card comes with a table. */}
        <Table size="sm" striped bordered hover>
          <tbody>
            {tempRowsToRender.slice(0, 3).map((row, index) => (
              <tr key={index}>
                <td style={{ width: '30%' }}>{row.label}</td>
                <td style={{ width: '50%' }}>{row.value}</td>
              </tr>
            ))}
            {showMore &&
              tempRowsToRender.slice(3).map((row, index) => (
                <tr key={index}>
                  <td style={{ width: '30%' }}>{row.label}</td>
                  <td style={{ width: '50%' }}>{row.value}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w I d
//
function ShowId({ auto, lng, alerts }) {

  const obj = auto.tunnus;

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Tunnukset', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card id='Tunnukset' className="bold-border-card" tabIndex="0">
      <CardBody>
        {showTitle(false, alertMsg(alerts, 'Tunnukset', lng), null, false, lng)}
        <CardText className="card-text-content">
        </CardText>
        <Table size="sm" striped bordered hover>
          <tbody>
            <tr>
              <td style={{ width: '20%' }}>{msg('rekisteritunnus', lng)}</td>
              <td style={{ width: '20%' }}>{msg('voimassaoloAlkaa', lng)}</td>
            </tr>
            {obj.map((row, index) => (
              <tr key={index}>
                {/* <td style={{ width: '40%' }}> ({chk(row, ['$', 'laji'])}) </td> */}
                <td style={{ width: '20%' }}>{row.rekisteritunnus}</td>
                <td style={{ width: '20%' }}>{dformat(row.voimassaoloAlkaa)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
}


// S h o w C o n s u m p t i o n E m i s s i o n
//
function ShowConsumptionEmission({ auto, lng }) {
  const obj = auto.kayttovoimat;
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return noInfoCard(msg('Kulutus ja päästöt', lng), msg('Ei tietoja', lng));
  }

  const additionalRows = [{ id: 99, label: msg('paastotaso', lng), value: auto?.paastotaso ? dcode(auto.paastotaso, 'KdTyytiPaastolajinTestausmenetelma', 'fi') : msg('Ei tietoja', lng) }] // NB! override lng

  if (auto?.pakokaasunPuhdistus) {
    const temp = xTract(auto, 'pakokaasunPuhdistus', 0, 'laite');
    for (let i = 0; i < temp.length; i++) {
      additionalRows.push({ id: 100 + i, label: msg('pakokaasunpuhdistus', lng), value: dcode(temp[i], 'KdTyytiPakokaasujenPuhdistuslaite', lng) });
    }
  }
  return (
    <Card>
      <CardBody>
        {showTitle(false, msg('Kulutus ja päästöt', lng), toggleShowMore, showMore, lng)}
        <CardText className="card-text-content" />
             
        <Table size="sm" striped bordered hover>
          <tbody>
            {Array.isArray(obj) && obj.map((outerItem, outerIndex) => (
              <React.Fragment key={outerIndex}>
                {outerItem.kayttovoima.map((innerItem, innerIndex) => (
                  <React.Fragment key={innerIndex}>
                    <tr>
                      <td style={{ width: '100%' }}>
                        {msg('kayttovoima', lng)} {dcode(innerItem.yksittaisKayttovoima, 'KdTyytiPolttoaine', lng)}
                      </td>
                    </tr>
                    {innerItem.kulutukset ? 
                      innerItem.kulutukset[0].kulutus.map((kulutusItem, kulutusIndex) => (
                        <tr key={kulutusIndex}>
                          <td style={{ width: '100%' }}>
                            {dcode(kulutusItem.kulutuslaji, 'KdTyytiKulutuslaji', lng)} {kulutusItem.maara}
                                                                 
                          </td>
                        </tr>
                      )) : 
                      <tr><td>{msg('eikulutustietoa', lng)}</td></tr>
                    }
                    {innerItem.paastot ? 
                      innerItem.paastot[0].paasto.map((paastoItem, paastoIndex) => (
                        <tr key={paastoIndex}>
                          <td style={{ width: '100%' }}>
                            {dcode(paastoItem.paastolaji, 'KdTyytiPaastolaji', lng)} {paastoItem.maara} {dcode(paastoItem.tyyppi, 'KdKatsaPaastotyyppi', 'fi')}
                                                                                           
                          </td>
                        </tr>
                      )) : 
                      <tr><td>{msg('eipäästötietoa', lng)}</td></tr>
                    }
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
            {additionalRows.map((row, index) => (
              <tr key={index}>
                <td style={{ width: '100%' }}>{row.label + ' ' + row.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );

  // return (
  //   <Card>
  //     <CardBody>
  //       {showTitle(false, msg('Kulutus ja päästöt', lng), toggleShowMore, showMore, lng)}
  //       <CardText className="card-text-content">
  //       </CardText>
  //       <Table size="sm" striped bordered hover>
  //         <tbody>
  //           {Array.isArray(obj) && obj.map((outerItem, outerIndex) => (
  //             <React.Fragment key={outerIndex}>
  //               {outerItem.kayttovoima.map((innerItem, innerIndex) => (
  //                 <React.Fragment key={innerIndex}>
  //                   <tr key={innerIndex}>
  //                     <td style={{ width: '100%' }}>
  //                       {msg('kayttovoima', lng)} {dcode(innerItem.yksittaisKayttovoima, 'KdTyytiPolttoaine', lng)}
  //                     </td>
  //                   </tr>
  //                   {innerItem.kulutukset ? (
  //                     innerItem.kulutukset[0].kulutus.map((kulutusItem, kulutusIndex) => (
  //                       <tr key={kulutusIndex}>
  //                         <td style={{ width: '100%' }}>
  //                           {dcode(kulutusItem.kulutuslaji, 'KdTyytiKulutuslaji', lng)} {kulutusItem.maara}
  //                           {/* {blanks}  {dcode(kulutusItem.kulutuslaji, 'KdTyytiKulutuslaji', lng)} {kulutusItem.maara} */}
  //                         </td>
  //                       </tr>
  //                       )) : 
  //                     <tr><td>{msg('eikulutustietoa', lng)}</td></tr>
  //                   {innerItem.paastot ? (
  //                     innerItem.paastot[0].paasto.map((paastoItem, paastoIndex) => (
  //                       <tr key={paastoIndex}>
  //                         <td style={{ width: '100%' }}>
  //                           {dcode(paastoItem.paastolaji, 'KdTyytiPaastolaji', lng)} {paastoItem.maara} {dcode(paastoItem.tyyppi, 'KdKatsaPaastotyyppi', 'fi')}
  //                         </td>
  //                       </tr>
  //                     )) : msg('eipäästötietoa', lng)}
  //                   }
  //                 </React.Fragment>
  //               ))}
  //             </React.Fragment>
  //           ))}
  //           {additionalRows.map((row, index) => (
  //             <tr key={index}>
  //               <td style={{ width: '100%' }}>{row.label + ' ' + row.value}</td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </Table>
  //     </CardBody>
  //   </Card>
  // );
}


// S h o w S p e c i a l T e r m s
//
// function ShowSpecialTerms({ auto, lng }) {

//   // const obj = auto.erikoisehto;
//   const [showMore, setShowMore] = useState(true);
//   const obj = auto?.erikoisehto?.filter(sel => sel.kooditettuHuomautus === '0008/2003');

//   if (undefined === obj || null === obj || undefined === obj.length || obj.length < 1) {
//     return (noInfoCard(msg('Erikoisehdot', lng), msg('Ei tietoja', lng)));
//   }

//   const toggleShowMore = () => {
//     setShowMore(!showMore);
//   };

//   return (
//     <Card>
//       <CardBody>
//         {showTitle(obj.length > 2, msg('Erikoisehdot', lng), toggleShowMore, showMore, lng)}
//         <CardText className="card-text-content">
//         </CardText>
//         <Table size="sm" striped bordered hover>
//           <tbody>
//             {obj.slice(0, 2).map((row, index) => (
//               <tr key={index}>
//                 {/* <td style={{ width: '30%' }}>{dcode(row.erikoisehdOsaalue, 'KdTyytiHuomautus', lng)} </td> */}
//                 <td style={{ width: '40%' }}>{dcode(row.kooditettuHuomautus, 'KdTyytiKooditettuHuomautus', lng)} </td>
//                 <td style={{ width: '30%' }}>{row.teksti} </td>
//               </tr>
//             ))}
//             {showMore &&
//               obj.slice(2).map((row, index) => (
//                 <tr key={`${index}`}>
//                   {/* <td style={{ width: '30%' }}>{dcode(row.erikoisehdOsaalue, 'KdTyytiHuomautus', lng)} </td> */}
//                   <td style={{ width: '40%' }}>{dcode(row.kooditettuHuomautus, 'KdTyytiKooditettuHuomautus', lng)} </td>
//                   <td style={{ width: '30%' }}>{row.teksti} </td>
//                 </tr>
//               ))}
//           </tbody>
//         </Table>
//       </CardBody>
//       {showFooter(true, toggleShowMore, showMore, lng)}
//     </Card>
//   );
// }


// S h o w D e r e g i s t r a t i o n s
//
function ShowDeregistrations({ auto, lng, alerts }) {

  const obj = auto.poisto;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Poistot', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card id='Poistot'>
      <CardBody>
        {showTitle(obj.length > 2, alertMsg(alerts, 'Poistot', lng), toggleShowMore, showMore, lng)}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          {/* <CardText className="card-text-content"> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              <tr>
                <td style={{ width: '20%' }}>{msg('alku', lng)}</td>
                <td style={{ width: '20%' }}>{msg('loppu', lng)}</td>
                <td style={{ width: '60%' }}>{msg('syy', lng)}</td>
              </tr>
              {obj.slice(0, 2).map((row, index) => (
                <tr key={`a-${index}`}>
                  <td style={{ width: '20%' }}>{dformat(row.poistonAlkupvm)}</td>
                  <td style={{ width: '20%' }}>{dformat(row.poistonLoppupvm)}</td>
                  <td style={{ width: '60%' }}>{dcode(row.poistonSyy, 'KdRekiLiikenteestaPoistonSyy', lng)}</td>
                </tr>
              ))}
              {showMore &&
                obj.slice(2).map((row, index) => (
                  <tr key={`b-${index}`}>
                    <td style={{ width: '20%' }}>{dformat(row.poistonAlkupvm)}</td>
                    <td style={{ width: '20%' }}>{dformat(row.poistonLoppupvm)}</td>
                    <td style={{ width: '60%' }}>{dcode(row.poistonSyy, 'KdRekiLiikenteestaPoistonSyy', lng)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w U s a g e
//
function ShowUsage({ auto, lng, alerts }) {

  const obj = auto.kaytto;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    // if alerts, show all rows
    if (alertMsg(alerts, 'Kayttohistoria', lng).includes('!')) { // highlight
      setShowMore(true);
    }
  }, [alerts, lng]);

  // nothing to see here
  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Kayttohistoria', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card id='Käyttöhistoria'>
      <CardBody>
        {showTitle(obj.length > 2, alertMsg(alerts, 'Kayttohistoria', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              {obj.slice(0, 2).map((row, index) => (
                <tr key={`a-${index}`}>
                  <td style={{ width: '20%' }}>{dformat(row.alkupvm)}</td>
                  <td style={{ width: '80%' }}>{row.ajoneuvonKaytto}</td>
                </tr>
              ))}
              {showMore &&
                obj.slice(2).map((row, index) => (
                  <tr key={`b-${index}`}>
                    <td style={{ width: '20%' }}>{dformat(row.alkupvm)}</td>
                    <td style={{ width: '80%' }}>{row.ajoneuvonKaytto}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w I n s u r a n ce
//
function ShowInsurance({ auto, lng }) {

  const obj = auto.vakuutustieto;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Vakuutustiedot', lng), msg('Ei tietoja', lng)));
  }

  const historyMinusEmpty = obj.filter(ins => ins.vakuutusyhtionNimi !== undefined);

  return (
    <Card>
      <CardBody>
        {showTitle(historyMinusEmpty.length > 2, msg('Vakuutustiedot', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              {historyMinusEmpty.slice(0, 2).map((row, index) => (
                <tr key={`a-${index}`}>
                  <td style={{ width: '40%' }}>{dformat(row.vakuutuksenAlkupvm)} - {dformat(row.vakuutuksenLoppupvm)}</td>
                  <td style={{ width: '60%' }}>{row.vakuutusyhtionNimi}</td>
                </tr>
              ))}
              {showMore &&
                historyMinusEmpty.slice(2).map((row, index) => (
                  <tr key={`b-${index}`}>
                    <td style={{ width: '40%' }}>{dformat(row.vakuutuksenAlkupvm)} - {dformat(row.vakuutuksenLoppupvm)}</td>
                    <td style={{ width: '60%' }}>{row.vakuutusyhtionNimi}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w M i l e a g e
//
function ShowMileage({ auto, lng }) {

  const obj = auto.kilometrilukema;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Mittarilukemat', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card>
      <CardBody>
        {showTitle(obj.length > 2, msg('Mittarilukemat', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              {obj.slice(0, 2).map((row, index) => (
                <tr key={`a-${index}`}>
                  <td style={{ width: '40%' }}>{dformat(row.ajankohta)}</td>
                  <td style={{ width: '60%' }}>{row.matkamittarilukema}</td>
                </tr>
              ))}
              {showMore &&
                obj.slice(2).map((row, index) => (
                  <tr key={`b-${index}`}>
                    <td style={{ width: '40%' }}>{dformat(row.ajankohta)}</td>
                    <td style={{ width: '60%' }}>{row.matkamittarilukema}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w S e c u r i t y E q u i p m e n t
//
function ShowSecurityEquipment({ auto, lng }) {

  const obj = auto.turvavaruste;
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('Turvavarusteet', lng), msg('Ei tietoja', lng)));
  }

  return (
    <Card id='Turvavarusteet'>
      <CardBody>
        {showTitle(obj.length > 2, msg('Turvavarusteet', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              {obj.slice(0, 2).map((row, index) => (
                <tr key={`a-${index}`}>
                  <td style={{ width: '40%' }}>{dcode(row.laji, 'KdKatsaTurvavaruste', lng)}</td>
                  <td style={{ width: '60%' }}>{msg('penkkirivi', lng)} {row.penkkirivi}, {dcode(row.sijainti, 'KdKatsaTurvavarusteenSijainti', lng)}, {dcode(row.pakollisuus, 'KdKatsaTurvavarusteenPakollisuus', lng)}</td>
                </tr>
              ))}
              {showMore &&
                obj.slice(2).map((row, index) => (
                  <tr key={`a-${index}`}>
                    <td style={{ width: '40%' }}>{dcode(row.laji, 'KdKatsaTurvavaruste', lng)}</td>
                    <td style={{ width: '60%' }}>{msg('penkkirivi', lng)} {row.penkkirivi}, {dcode(row.sijainti, 'KdKatsaTurvavarusteenSijainti', lng)}, {dcode(row.pakollisuus, 'KdKatsaTurvavarusteenPakollisuus', lng)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w D i m e n s i o n s A n d W e i g h t s
//
function ShowDimensionsAndWeights({ auto, lng, alerts }) {

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // if (undefined === obj || null === obj) {
  //   return (noInfoCard(msg('MitatPainot', lng), msg('Ei tietoja', lng)));
  // }

  return (
    <Card id='MitatPainot'>
      <CardBody>
        {showTitle(false, alertMsg(alerts, 'MitatPainot', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          {/* {alert} */}
          <Table size="sm" striped bordered hover>
            <tbody>
              <tr key={0}>
                <td style={{ width: '60%' }}>{msg('ajonKokPituus', lng)}</td>
                <td style={{ width: '20%' }}>{auto.ajonKokPituus}</td>
              </tr>
              <tr key={1}>
                <td style={{ width: '60%' }}>{msg('ajonLeveys', lng)}</td>
                <td style={{ width: '20%' }}>{auto.ajonLeveys}</td>
              </tr>
              <tr key={2}>
                <td style={{ width: '60%' }}>{msg('ajonKorkeus', lng)}</td>
                <td style={{ width: '20%' }}>{auto.ajonKorkeus}</td>
              </tr>

              <tr key={3}>
                <td style={{ width: '60%' }}>{msg('omamassa', lng)}</td>
                <td style={{ width: '20%' }}>{auto.omamassa}</td>
              </tr>
              <tr key={4}>
                <td style={{ width: '60%' }}>{msg('teknSuurSallKokmassa', lng)}</td>
                <td style={{ width: '20%' }}>{auto.teknSuurSallKokmassa}</td>
              </tr>
              <tr key={5}>
                <td style={{ width: '60%' }}>{msg('yhdistelmanSuurSallMassa', lng)}</td>
                <td style={{ width: '20%' }}>{auto.yhdistelmanSuurSallMassa}</td>
              </tr>
              <tr key={6}>
                <td style={{ width: '60%' }}>{msg('massaJarruitta', lng)}</td>
                <td style={{ width: '20%' }}>{auto.massaJarruitta}</td>
              </tr>
              <tr key={7}>
                <td style={{ width: '60%' }}>{msg('massaJarruin', lng)}</td>
                <td style={{ width: '20%' }}>{auto.massaJarruin}</td>
              </tr>
              <tr key={8}>
                <td style={{ width: '60%' }}>{msg('massaJarruittaValmSall', lng)}</td>
                <td style={{ width: '20%' }}>{auto.massaJarruittaValmSall}</td>
              </tr>
              <tr key={9}>
                <td style={{ width: '60%' }}>{msg('massaJarruinValmSall', lng)}</td>
                <td style={{ width: '20%' }}>{auto.massaJarruinValmSall}</td>
              </tr>

            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
      {showFooter(true, toggleShowMore, showMore, lng)}
    </Card>
  );
}


// S h o w A x l e s A n d T y r e s
//
function ShowAxlesAndTyres({ auto, lng }) {

  const obj = auto.akselitiedot;
  const [showMore, setShowMore] = useState(false);
  const blanks = '- ';

  // console.log('ListRenkaat, obj', obj);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (undefined === obj || null === obj) {
    return (noInfoCard(msg('AkselitRenkaat', lng), msg('Ei tietoja', lng)));
  }

  const temp = [];
  let i = 0;
  let j = 0;
  obj.forEach((outerItem) => {
    i++;
    outerItem.akseli.forEach((innerItem) => {
      j++;
      if (innerItem.sijainti) {
        temp.push({ id: i * 100 + j, label: msg('akseli', lng), value: innerItem.sijainti });
      }

      j++;
      if (innerItem.vetava) {
        temp.push({ id: i * 100 + j, label: blanks + msg('vetava', lng), value: trueFalse(innerItem.vetava, lng) });
      }

      j++;
      if (innerItem.ohjaava) {
        temp.push({ id: i * 100 + j, label: blanks + msg('ohjaava', lng), value: trueFalse(innerItem.ohjaava, lng) });
      }

      j++;
      if (innerItem.teknSuurSallMassa) {
        temp.push({ id: i * 100 + j, label: blanks + msg('teknSuurSallMassa', lng), value: innerItem.teknSuurSallMassa });
      }

      if (innerItem.rengas) {
        temp.push({ id: i * 100 + j, label: blanks + msg('rengaskoko', lng) + ' / ' + msg('vannekoko', lng) + ' / ' + msg('offset', lng) + ' / ' + msg('luokka', lng), value: '' });
        innerItem.rengas.forEach((childItem) => {
          j++;
          const rengaskoko = childItem?.rengaskoko ? childItem?.rengaskoko : msg('eitietoa', lng);
          const vannekoko = childItem?.vannekoko ? childItem?.vannekoko : msg('eitietoa', lng);
          const offset = childItem?.offset ? childItem?.offset : msg('eitietoa', lng);
          const luokka = childItem?.kuormitusJaNopeusluokka ? childItem?.kuormitusJaNopeusluokka : msg('eitietoa', lng);
          temp.push({ id: i * 100 + j, label: blanks + rengaskoko + ' / ' + vannekoko + ' / ' + offset + ' / ' + luokka, value: '' });
        });
      }
    });
  });

  // console.log('temp', temp);

  return (
    <Card id='AkselitRenkaat'>
      <CardBody>
        {showTitle(false, msg('AkselitRenkaat', lng), toggleShowMore, showMore, lng)}
        {/* <CardText className="card-text-content"> */}
        <div className="card-text-content"> {/* Changed from <CardText> to <div> */}
          <Table size="sm" striped bordered hover>
            <tbody>
              {temp.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  <td style={{ width: '80%' }}>
                    {row.label}
                  </td>
                  <td style={{ width: '20%' }}>
                    {row.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* </CardText> */}
        </div>
      </CardBody>
    </Card>
  );
}




// G e n e r i c  d i s p l a y  f u n c t i o n s
//


// n o I n f o C a r d
//
function noInfoCard(title, message) {
  return (
    <Card>
      <CardBody>
        <CardTitle className="display-4">{title}</CardTitle>
        <CardText className="card-text-content">
          {message}
        </CardText>
      </CardBody>
    </Card>
  )
}


// s h o w T i t l e
//
const showTitle = (showChevron, title = '', toggleShowMore, showMore, lng) => {
  let cardTitleFormatting = `display-4 ${typeof title === 'string' && title.includes('!') ? 'highlight-element' : ''}`;

  if (showChevron) {
    cardTitleFormatting += ' card-title-with-icon';
    return (
      <CardTitle className={cardTitleFormatting} onClick={toggleShowMore}>
        <span>{title}</span>
        <span className="chevron-icon">
          {showMore ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}
        </span>
      </CardTitle>
    );
  } else {
    return <CardTitle className={cardTitleFormatting}>{title}</CardTitle>;
  }
};

// const showTitle = (showChevron, title, toggleShowMore, showMore, lng) => {
//   let cardTitleFormatting = `display-4 ${title.includes('!') ? 'highlight-element' : ''}`;

//   if (showChevron) {
//     cardTitleFormatting += ' card-title-with-icon';
//     return (
//       <CardTitle className={cardTitleFormatting} onClick={toggleShowMore}>
//         <span>{title}</span>
//         <span className="chevron-icon">
//           {showMore ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}
//         </span>
//       </CardTitle>
//     );
//   } else {
//     return <CardTitle className={cardTitleFormatting}>{title}</CardTitle>;
//   }
// };


// s h o w F o o t e r
//
const showFooter = (show, toggleShowMore, showMore, lng) => {
  if (show) {
    return (
      <CardFooter onClick={toggleShowMore} className="card-footer-link">
        {showMore ? msg('näytä vähemmän', lng) : msg('näytä lisää', lng)}
      </CardFooter>
    )
  }
}


export default Show;