// joiner 2023-10-24T09:31:08.415Z 

const TCODES = [

  // Added 2025-02-17
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '01', 'text': '(OVC-HEV) Sähköverkosta ladattava', 'lng': 'fi'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '02', 'text': '(NOVC-HEV) Pelkästään polttomoottorilla ladattava', 'lng': 'fi'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '03', 'text': '(OVC-FCHV) Sähköverkosta ladattava polttokennohybridi', 'lng': 'fi'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '04', 'text': '(NOVC-FCHV) Pelkästään polttokennolla ladattava', 'lng': 'fi'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '01', 'text': '(OVC-HEV) Extern uppladdning', 'lng': 'sv'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '02', 'text': '(NOVC-HEV) Ej extern uppladdning av fordonet', 'lng': 'sv'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '03', 'text': '(OVC-FCHV) Externt laddbart bränslecellshybridfordon', 'lng': 'sv'},
  {'code': 'KdTyytiSahkoHybridinLuokka', 'codeValue': '04', 'text': '(NOVC-FCHV) Ej externt laddbart bränslecellshybridfordon', 'lng': 'sv'},
  {"code": "Asiakaslajit", "codeValue": "0", "text": "Yksityinen", "lng": "fi"},
  {"code": "Asiakaslajit", "codeValue": "1", "text": "Yritys", "lng": "fi"},
  {"code": "Asiakaslajit", "codeValue": "1", "text": "Företagskund", "lng": "sv"},
  {"code": "Asiakaslajit", "codeValue": "0", "text": "Privatkund", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "fi", "text": "Finska", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "sv", "text": "Svenska", "lng": "sv"},
  {"code": "Asiointikielet", "codeValue": "fi", "text": "Suomi", "lng": "fi"},
  {"code": "Asiointikielet", "codeValue": "sv", "text": "Ruotsi", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "0", "text": "Ei rekisterissä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "1", "text": "Ennakkoilmoitettu, ei rekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "2", "text": "Rekisteröintikatsastettu, ei rekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "3", "text": "Rekisterissä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "4", "text": "Poistettu liikenteestä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "5", "text": "Poistettu rekisteristä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "6", "text": "Poistettu lopullisesti rekisteristä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "7", "text": "Siirtolupa", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "8", "text": "Vientirekisteröity", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "9", "text": "Kiinnitystarkastettu", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO08004", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "SOLSUOHUOLSEL", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO00832", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168815", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168817", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO01308", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "KAYMENOP", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168609", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ATO05824", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168610", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168826", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "KAYAUMSI", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168856", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "12168894", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "0", "text": "icke registerförd/registerfört", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "1", "text": "Förhandsanmäld/inte registrerad", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "2", "text": "registreringsbesiktad/registreringsbesiktat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "3", "text": "registrerad/registrerat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "4", "text": "avställd/avställt", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "5", "text": "avregistrerad/avregistrerat (VDS)", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "6", "text": "slutligt avregistrerad/avregistrerat (skrotad/skrotat)", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "7", "text": "förflyttningstillstånd", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "8", "text": "exportregistrerad/ exportregistrerat", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "9", "text": "granskad för inteckning", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC020000869", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ASLJUKCAROY", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "BIG11282", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010003192B", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010004629B", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC020000869", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT09748", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT15907", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16116", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16124", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16125", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "GRE_ATONOS-TRE", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "inn01120", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NETAMICOMELIUS", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00164", "text": "ei käytössä", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00509", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH02138", "text": "", "lng": "sv"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00164", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH02138", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NWH00509", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16124", "text": "ei käytössä  Lehtiset Oy", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16116", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT16125", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT15907", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "FUT09748", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "inn01120", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "NETAMICOMELIUS", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010004629B", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "CABC010003192B", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "GRE_ATONOS-TRE", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "ASLJUKCAROY", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpAjoneuvonTila", "codeValue": "BIG11282", "text": "ei käytössä", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "0", "text": "Mopo", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "1", "text": "Auto", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "2", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "3", "text": "Traktori tai moottorityökone", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "4", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "5", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "7", "text": "Siirtokilpi", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "8", "text": "Vientirekisteröinti", "lng": "fi"},
  {"code": "KdAtpLaji", "codeValue": "0", "text": "Moped", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "1", "text": "Bil", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "2", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "3", "text": "Traktor eller motorredskap", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "4", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "5", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "7", "text": "Förflyttningsskylt", "lng": "sv"},
  {"code": "KdAtpLaji", "codeValue": "8", "text": "Exportregistrering", "lng": "sv"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "0", "text": "Ei rajoitusta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "1", "text": "Henkilötietoja ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "2", "text": "HETUa ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "3", "text": "Osoitetietoja ei luovuteta", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "8", "text": "Osoitteenluovutuskielto", "lng": "fi"},
  {"code": "KdAtpLuovutusrajoitus", "codeValue": "9", "text": "Turvakielto", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "1", "text": "Erittäin vakava rajoitus (ajo- tai käyttökielto)", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "2", "text": "Vakava rajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "3", "text": "Muu rajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "4", "text": "Vakava huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "5", "text": "Muu huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "1", "text": "Mycket allvarlig begränsning (kör- eller användningsförbud)", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "2", "text": "Allvarlig begränsning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "3", "text": "Annan begränsning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "4", "text": "Allvarlig anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituksenVakavuus", "codeValue": "5", "text": "Annan anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "36", "text": "Lisävero vireillä", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "36", "text": "Anhängig tilläggsskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "37", "text": "Fordonsskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "37", "text": "Ajoneuvovero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "38", "text": "Siirtohuomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "38", "text": "Förflyttningsanmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "39", "text": "Ulkomaisten kilpien palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "39", "text": "Återlämning av utländska skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "35", "text": "Katsastuksen ajoaika <pvm> loppuun", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "35", "text": "Besiktningen gäller t.o.m. utgången av <datum>", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "101", "text": "Haltijan hallintaluovutusilmoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "102", "text": "Luovutusilmoitus tuntemattomalle", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "103", "text": "Kiinnityshakemus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "104", "text": "Diplomaattirekisteröinti", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "01", "text": "Muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "02", "text": "Vireilläolo", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "03", "text": "Ajokielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "04", "text": "Autoverovapaus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "05", "text": "Myyntirajoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "06", "text": "Valvontakatsastusvelvollisuus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "07", "text": "Valvontakatsastusvelvollisuus laiminlyöty", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "08", "text": "Muutoskatsastusvelvollisuus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "09", "text": "Takaisinkutsu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "10", "text": "Määräaikaiskatsastus suorittamatta/hyväksymättä", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "11", "text": "Ajoneuvo anastettu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "12", "text": "Kilvet anastettu", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "13", "text": "Muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "14", "text": "Muutoskielto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "15", "text": "Viretieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "16", "text": "Kiinnitystieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "17", "text": "Vakuutuksen maksamattomuustieto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "18", "text": "Liikenteestä poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "19", "text": "Lopullinen poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "20", "text": "Rekisteristä poisto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "21", "text": "Voimassaoleva vientirekisteröinti", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "22", "text": "Ajoneuvovero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "23", "text": "Ajoneuvo käyttökiellossa/Lisävero", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "24", "text": "Vanha ajoneuvovero/dieselvero erääntynyt", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "25", "text": "Kilpien haltuunotto", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "26", "text": "Autoveron palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "27", "text": "Muu huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "28", "text": "Tavallinen huomautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "29", "text": "Seisonta-aika", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "30", "text": "Kilpien palautus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "31", "text": "Kilpien säilytys", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "32", "text": "Lisäkilpi", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "33", "text": "Ajoneuvolla luovutusilmoitus", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "34", "text": "Ajokielto kilpien haltuunotosta", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "01", "text": "Ändringsförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "02", "text": "Anhängig", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "03", "text": "Körförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "04", "text": "Befrielse från bilskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "05", "text": "Försäljningsbegränsning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "06", "text": "Övervakningsbesiktningsskyldighet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "07", "text": "Övervakningsbesiktningsskyldighet försummad", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "08", "text": "Ändringsbesiktningsskyldighet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "09", "text": "Återkallelse", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "10", "text": "Periodisk besiktning har inte utförts/godkänts", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "11", "text": "Fordonet stulet", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "12", "text": "Skyltarna stulna", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "13", "text": "Ändringsförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "14", "text": "Ändringsförbud", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "15", "text": "Statusuppgift", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "16", "text": "Inteckningsuppgift", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "17", "text": "Uppgift om obetald försäkring", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "18", "text": "Avställning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "19", "text": "Slutlig avregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "20", "text": "Avregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "21", "text": "Gällande exportregistrering", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "22", "text": "Fordonsskatten har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "23", "text": "Tilläggsskatt för fordonsskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "24", "text": "Gammal fordonsskatt/dieselskatt har förfallit till betalning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "25", "text": "Besittningstagande av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "26", "text": "Returnering av bilskatt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "27", "text": "Annan anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "28", "text": "Vanlig anmärkning", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "29", "text": "Avställningstid", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "30", "text": "Returnering av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "31", "text": "Förvaring av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "32", "text": "Tilläggsskylt", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "33", "text": "Fordonet har överlåtelseanmälan", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "34", "text": "Körförbud från besittningstagande av skyltar", "lng": "sv"},
  {"code": "KdAtpRajoituslaji", "codeValue": "40", "text": "Vakuutus/rekisteröinti laiminlyöty", "lng": "fi"},
  {"code": "KdAtpRajoituslaji", "codeValue": "40", "text": "Försäkring/registrering försummad", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "1", "text": "Fram", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "2", "text": "Bak", "lng": "sv"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "1", "text": "Edessä", "lng": "fi"},
  {"code": "KdKatsaAlleajosuojanSijainti", "codeValue": "2", "text": "Takana", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "1", "text": "ABS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "2", "text": "ALB", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "3", "text": "ABS + ALB", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "4", "text": "EBS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "5", "text": "EBS", "lng": "sv"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "1", "text": "ABS", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "2", "text": "ALB", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "3", "text": "ABS + ALB", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "4", "text": "EBS", "lng": "fi"},
  {"code": "KdKatsaJarrujarjestelmanLisatieto", "codeValue": "5", "text": "EBS", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.13", "text": "Kontrollbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.1", "text": "Registreringsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.10", "text": "Kopplingsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.11", "text": "Rättelse av tekniska uppgifter", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.12", "text": "Förhandsanmälan", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.14", "text": "Vägkontroll, lätt", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.15", "text": "Annullering", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.18", "text": "Enskilt godkännande", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.19", "text": "Rättelse av enskilt godkännande", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.2", "text": "Ändringsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.3", "text": "Periodisk besiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.4", "text": "Påställningsbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.5", "text": "TFÄ/ADR-besiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.6", "text": "Exportbesiktning", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.7", "text": "Inteckningskontroll", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.8", "text": "Godkännande av enskilt fordon", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.9", "text": "Vägkontroll, tung", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "9.5.1", "text": "Uppdatering av begränsningsuppgifter", "lng": "sv"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.1", "text": "Rekisteröintikatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.10", "text": "Kytkentäkatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.11", "text": "Teknisten tietojen korjaus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.12", "text": "Ennakkoilmoitus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.13", "text": "Valvontakatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.14", "text": "Tienvarsitarkastus, kevyt", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.15", "text": "Mitätöinti", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.18", "text": "Yksittäishyväksyntä", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.19", "text": "Yksittäishyväksynnän korjaus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.2", "text": "Muutoskatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.3", "text": "Määräaikaiskatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.4", "text": "Liikenteeseenottokatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.5", "text": "VAK/ADR -katsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.6", "text": "Vientikatsastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.7", "text": "Kiinnitystarkastus", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.8", "text": "Yksittäisen ajoneuvon hyväksyntä", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "1.5.9", "text": "Tienvarsitarkastus, raskas", "lng": "fi"},
  {"code": "KdKatsaKatsastuslaji", "codeValue": "9.5.1", "text": "Rajoitustietojen ylläpito", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "1", "text": "avbruten", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "2", "text": "godkänd", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "3", "text": "avvisad", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "4", "text": "annullerad", "lng": "sv"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "1", "text": "keskeytetty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "2", "text": "hyväksytty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "3", "text": "hylätty", "lng": "fi"},
  {"code": "KdKatsaKatsastuspaatos", "codeValue": "4", "text": "mitätöity", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "01", "text": "Joutokäynti [%]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "02", "text": "Korotettu joutokäynti [%]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "03", "text": "[g/km]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "04", "text": "ETC [g/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "05", "text": "ESC [g/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "06", "text": "[mg/km]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "07", "text": "WHSC [mg/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "08", "text": "WHTC [mg/kWh]", "lng": "fi"},
  {"code": "KdKatsaPaastotyyppi", "codeValue": "09", "text": "WLTP [mg/km]", "lng": "fi"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "1", "text": "Metall", "lng": "sv"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "2", "text": "Plast", "lng": "sv"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "1", "text": "Metalli", "lng": "fi"},
  {"code": "KdKatsaPolttoainesailio", "codeValue": "2", "text": "Muovi", "lng": "fi"},
  {"code": "KdKatsaRengaslaji", "codeValue": "1", "text": "typgodkänd", "lng": "sv"},
  {"code": "KdKatsaRengaslaji", "codeValue": "2", "text": "antecknad vid besiktning", "lng": "sv"},
  {"code": "KdKatsaRengaslaji", "codeValue": "1", "text": "tyyppihyväksytty", "lng": "fi"},
  {"code": "KdKatsaRengaslaji", "codeValue": "2", "text": "katsastuksessa merkitty", "lng": "fi"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "1", "text": "Till vänster", "lng": "sv"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "2", "text": "Till höger", "lng": "sv"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "1", "text": "Vasemmalla", "lng": "fi"},
  {"code": "KdKatsaSivusuojanSijainti", "codeValue": "2", "text": "Oikealla", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "1", "text": "Metall", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "2", "text": "Gummibelagd metall", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "3", "text": "Gummi", "lng": "sv"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "1", "text": "Metalli", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "2", "text": "Kumipäällysteinen metalli", "lng": "fi"},
  {"code": "KdKatsaTelamateriaali", "codeValue": "3", "text": "Kumi", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "an", "text": "Aktivt nackstöd", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "et", "text": "Krockkudde fram", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "st", "text": "Krockkudde sida", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "vk", "text": "Bilbältessträckare", "lng": "sv"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "an", "text": "Aktiivinen niskatuki", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "et", "text": "Etuturvatyyny", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "st", "text": "Sivuturvatyyny", "lng": "fi"},
  {"code": "KdKatsaTurvavaruste", "codeValue": "vk", "text": "Turvavyön kiristin", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "1", "text": "Obligatorisk", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "2", "text": "Installerad option", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "3", "text": "Annan meddelad", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "3", "text": "Muu ilmoitettu", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "1", "text": "Pakollinen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenPakollisuus", "codeValue": "2", "text": "Asennettu valinnainen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "1", "text": "Vänster", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "2", "text": "Mitt", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "3", "text": "Höger", "lng": "sv"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "1", "text": "Vasen", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "2", "text": "Keski", "lng": "fi"},
  {"code": "KdKatsaTurvavarusteenSijainti", "codeValue": "3", "text": "Oikea", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "AT", "text": "AT", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "EXII", "text": "EXII", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "EXIII", "text": "EXIII", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "FL", "text": "FL", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "MEMU", "text": "MEMU", "lng": "fi"},
  {"code": "KdKatsaVAKADRTodistuksenTyyppi", "codeValue": "OX", "text": "OX", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "1", "text": "Korjauskehotus", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "1", "text": "Reparationsuppmaning", "lng": "sv"},
  {"code": "KdKatsaVakavuus", "codeValue": "2", "text": "Hylätty", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "2", "text": "Icke godkänd", "lng": "sv"},
  {"code": "KdKatsaVakavuus", "codeValue": "3", "text": "Ajokielto", "lng": "fi"},
  {"code": "KdKatsaVakavuus", "codeValue": "3", "text": "Körförbjud", "lng": "sv"},
  {"code": "KdKatsaYksittainMaahantuotu", "codeValue": "1", "text": "Tuotu käytettynä", "lng": "fi"},
  {"code": "KdKatsaYksittainMaahantuotu", "codeValue": "2", "text": "Tuotu uutena", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "01", "text": "Yksityinen", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "01", "text": "Privat", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "02", "text": "Luvanvarainen", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "02", "text": "Tillståndspliktig", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "03", "text": "Kouluajoneuvo", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "03", "text": "Skolfordon", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "04", "text": "Vuokraus ilman kuljettajaa", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "04", "text": "Uthyrning utan förare", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "05", "text": "Myyntivarasto", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "05", "text": "Försäljningslager (lagerförsäkring)", "lng": "sv"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "06", "text": "Luvanvarainen tavaraliikenne", "lng": "fi"},
  {"code": "KdRekiAjoneuvoKaytto", "codeValue": "06", "text": "Tillståndspliktig godstrafik", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "02", "text": "Leasing", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "03", "text": "Työsuhde", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "04", "text": "Muu", "lng": "fi"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "02", "text": "Leasing", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "03", "text": "Anställningsförhållande", "lng": "sv"},
  {"code": "KdRekiHallintaSuhde", "codeValue": "04", "text": "Annat", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "01", "text": "Tavallinen poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "01", "text": "Vanlig avregistrering", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "02", "text": "Käyttämättömyyspoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "02", "text": "Avregistrerad som obegagnad", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "03", "text": "Viranomaispoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "03", "text": "Avregistrerad av myndighet", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "04", "text": "Vaurioituneena poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "04", "text": "Avregistrerad som skadad", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "05", "text": "Kunnan poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "05", "text": "Avregistrerad av kommun", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "06", "text": "Ahvenanmaalle poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "06", "text": "Avregistrerad till Åland", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "07", "text": "Avregistrerad till utlandet", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "08", "text": "Tuhoutuneena poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "08", "text": "Avregistrerad som förstörd", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "09", "text": "Romutuspoisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "09", "text": "Skrotavregistrering", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "10", "text": "Itse purettu / tuhottu", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "10", "text": "Själv demonterat / destruerat", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "07", "text": "Ulkomaille poisto", "lng": "fi"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "11", "text": "Avställning för registrering i militärfordonsregistret", "lng": "sv"},
  {"code": "KdRekiLiikenteestaPoistonSyy", "codeValue": "11", "text": "Sotilasajoneuvorekisteriin poisto", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "01", "text": "Luovutusilmoitus", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "02", "text": "Haltijan hallintaluovutus", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "03", "text": "Luovutus tuntemattomalle", "lng": "fi"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "01", "text": "Anmälan om överlåtelse", "lng": "sv"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "02", "text": "Innehavarens överlåtelse av besittningsrätten", "lng": "sv"},
  {"code": "KdRekiLuovutusLaji", "codeValue": "03", "text": "Överlåtelse till okänd", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "01", "text": "1.omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "02", "text": "Muu omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "03", "text": "1.haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "04", "text": "Muu haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "05", "text": "Väliomistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "06", "text": "Jälleenmyyjä", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "07", "text": "Vastaanottaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "08", "text": "Entinen omistaja", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "09", "text": "Entinen haltija", "lng": "fi"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "01", "text": "1 ägaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "02", "text": "Annan ägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "03", "text": "1 innehavaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "04", "text": "Annan innehavaren", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "05", "text": "Mellanägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "06", "text": "Återförsäljare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "07", "text": "Mottagare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "08", "text": "Förutvarande ägare", "lng": "sv"},
  {"code": "KdRekiOmistajuusTyyppi", "codeValue": "09", "text": "Förutvarande innehavare", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "01", "text": "Korjaamolle siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "01", "text": "Förflyttning till verkstad", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "02", "text": "Katsastukseen siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "03", "text": "Korjaamolle/katsastukseen siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "03", "text": "Förflyttning till verkstad/besiktning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "04", "text": "Rajalle siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "04", "text": "Förflyttning till gränsen", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "05", "text": "Esittely", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "05", "text": "Demonstration", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "06", "text": "Kilpailu", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "06", "text": "Tävling", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "07", "text": "Autoveroasian käsittely kesken", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "07", "text": "Bilbeskattning under behandling", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "08", "text": "Siirto", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "08", "text": "Förflyttning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "09", "text": "Muu syy", "lng": "fi"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "09", "text": "Övrig sak", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "02", "text": "Förflyttning till besiktning", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "10", "text": "Bilaffärens demonstrationsbruk", "lng": "sv"},
  {"code": "KdRekiSiirtoKayttotarkoitus", "codeValue": "10", "text": "Autoliikkeen esittelykäyttö", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "01", "text": "Liikennevakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "02", "text": "Koenumerovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "03", "text": "Varastovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "01", "text": "Trafikförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "02", "text": "Provnummerförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusTyyppi", "codeValue": "03", "text": "Lagerförsäkring (försäljningslager)", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "26", "text": "Protector Forsikring ASA", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "26", "text": "Protector Forsikring ASA", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "03", "text": "Lähivakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "03", "text": "Lokalförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "04", "text": "A-vakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "04", "text": "A-vakuutus", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "13", "text": "Pohjantähti", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "13", "text": "Pohjantähti", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "14", "text": "IF sivuliike", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "15", "text": "Volvia", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "15", "text": "Volvia", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "19", "text": "Ingonord", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "19", "text": "Ingonord", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "20", "text": "Tryg", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "20", "text": "Tryg", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "21", "text": "Ups", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "21", "text": "Ups", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "22", "text": "Tapiola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "22", "text": "Tapiola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "29", "text": "Axa", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "29", "text": "Axa", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "30", "text": "Kansa", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "30", "text": "Kansa", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "32", "text": "Varma", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "32", "text": "Varma", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "36", "text": "Pohjola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "36", "text": "Pohjola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "39", "text": "AIG", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "39", "text": "AIG", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "40", "text": "Euro Insurances", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "40", "text": "Euro Insurances", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "41", "text": "IF", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "41", "text": "IF", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "43", "text": "Y-Sampo", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "43", "text": "F-Sampo", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "50", "text": "Fennia Skadeförsäkring", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "50", "text": "Fennia Skadeförsäkring", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "52", "text": "IF-TEVA", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "52", "text": "IF-TEVA", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "56", "text": "Turva", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "56", "text": "Turva", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "65", "text": "Fennia", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "65", "text": "Fennia", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "66", "text": "Ålands ömsesidiga försäkringsbolag", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "66", "text": "Ålands ömsesidiga", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "90", "text": "Valtiokonttori", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "90", "text": "Valtiokonttori", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "92", "text": "LVK", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "92", "text": "LVK", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "99", "text": "Ulkomainen", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "99", "text": "Utländsk", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "14", "text": "IF sivuliike", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "38", "text": "Suomen Vahinkovakuutus", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "38", "text": "Suomen Vahinkovakuutus", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "11", "text": "Greenval Insurance", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "11", "text": "Greenval Insurance", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "44", "text": "LähiTapiola", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "44", "text": "LähiTapiola", "lng": "sv"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "98", "text": "Ei vakuutusta", "lng": "fi"},
  {"code": "KdRekiVakuutusyhtio", "codeValue": "98", "text": "Ingen försäkring", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra2", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra3", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra3", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra4", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra4", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb1", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb1", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb2", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb2", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb3", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb3", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb4", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Rb4", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa1", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa1", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa2", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sa2", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb1", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb1", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb2", "text": "Vedettävä kone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Sb2", "text": "Dragen maskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T4", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T4", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T5", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T5", "text": "Traktori", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C4", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C4", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C5", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C5", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "KNP", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "KNP", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra2", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1e", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L1e", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2e", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L2e", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3e", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L3e", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4e", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L4e", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5", "text": "Kolmi- tai nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5", "text": "Tre- eller fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5e", "text": "Kolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L5e", "text": "Trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L6e", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L6e", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L7e", "text": "Nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "L7e", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "LTR", "text": "Liikennetraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "LTR", "text": "Trafiktraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1G", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M1G", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2G", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M2G", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3G", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "M3G", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MA", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MA", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MTK", "text": "Moottorityökone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MTK", "text": "Motorredskap", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MUU", "text": "MUU", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "MUU", "text": "ÖVRIG", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1G", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N1G", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2G", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N2G", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3G", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "N3G", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O1", "text": "Kevyt perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O1", "text": "Lätt släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O2", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O2", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O3", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O3", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O4", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "O4", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra1", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "Ra1", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T2b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C2b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T3b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3b", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C3b", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "T1a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1a", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "C1a", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "R3b", "text": "Traktorin perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoluokka", "codeValue": "R3b", "text": "Traktorsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "97", "text": "Lakaisukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "97", "text": "Sopningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "98", "text": "Hiekanlevitin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "98", "text": "Sandspridare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "99", "text": "Linjanrakennuskone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "99", "text": "Linjebyggnadsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "90", "text": "Bomskoplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "91", "text": "(monitoimi)metsäkone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "91", "text": "(mångssysslo) skogsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "92", "text": "Haketuskone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "92", "text": "Flisningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "90", "text": "Puomikauhakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "93", "text": "Vetotrukki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "93", "text": "Dragtruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "94", "text": "Snöslunga", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "95", "text": "Hajasääritrukki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "95", "text": "Grensletruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "96", "text": "Kuljetusalusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "94", "text": "Lumilinko", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "134", "text": "Pieni esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "134", "text": "Litet tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "135", "text": "Suuri esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "135", "text": "Stort tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "136", "text": "Autoverovapaa esteetön ajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "136", "text": "Bilskattefritt tillgängligt fordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "137", "text": "Autoverovapaa koulu- ja päivähoitokuljetusajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "137", "text": "Bilskattefritt fordon för transport av skol- och dagvårdsbarn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "926", "text": "L7e-CU tavaranelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "926", "text": "L7e-CU tung mopedbil för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "22", "text": "Butikbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "23", "text": "Nosturiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "23", "text": "Kranbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "24", "text": "Invataksi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "24", "text": "Invataxi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "25", "text": "Säiliöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "25", "text": "Tankbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "26", "text": "Maastohenkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "26", "text": "Terrängpersonbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "27", "text": "Nosturiauton alusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "27", "text": "Kranbils chassi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "28", "text": "Hevosenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "28", "text": "Hästtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "29", "text": "Matkailuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "29", "text": "Campingbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "3", "text": "Kevyt telttaperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "3", "text": "Lätt tältsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "30", "text": "Eläintenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "30", "text": "Djurtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "31", "text": "Rikka-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "515", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "32", "text": "Pelastusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "32", "text": "Räddningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "33", "text": "Ambulanssi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "33", "text": "Ambulans", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "34", "text": "Poliisiajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "34", "text": "Polisfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "35", "text": "Pienoislinja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "35", "text": "Minibus", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "36", "text": "Betonipumppuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "36", "text": "Betongpumpsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "37", "text": "Nivellinja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "37", "text": "Ledad buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "38", "text": "Nivellinja-auto (kaksikerroksinen)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "38", "text": "Ledad tvåvåningsbuss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "39", "text": "Linja-auto (kaksikerroksinen)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "39", "text": "Tvåvåningsbuss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "4", "text": "Kevyt moottoripyöränkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "4", "text": "Lätt motorcykeltransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "40", "text": "Alaluokka I (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "40", "text": "Klass I (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "41", "text": "Alaluokka II (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "41", "text": "Klass II (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "42", "text": "Alaluokka III (M2/M3)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "42", "text": "Klass III (M2/M3)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "43", "text": "Alaluokka A", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "43", "text": "Klass A", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "44", "text": "Alaluokka B", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "44", "text": "Klass B", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "45", "text": "Klass I (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "45", "text": "Alaluokka I (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "46", "text": "Alaluokka II (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "46", "text": "Klass II (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "47", "text": "Alaluokka A (matalalattialinja-auto)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "47", "text": "Klass A (låggolvsbuss)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "48", "text": "Ruumisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "48", "text": "Likbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "49", "text": "Maastoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "49", "text": "Terrängbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "5", "text": "Kevyt lentokoneenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "5", "text": "Lätt transportsläpvagn för flygplan", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "50", "text": "Paloauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "50", "text": "Brandbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "501", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "501", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "502", "text": "Linja-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "502", "text": "Buss", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "503", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "503", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "504", "text": "Kuorma-auto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "504", "text": "Lastbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "505", "text": "Erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "505", "text": "Specialbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "56", "text": "Huoltoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "506", "text": "Erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "506", "text": "Specialbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "507", "text": "Pakettiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "507", "text": "Paketbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "508", "text": "Henkilöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "508", "text": "Personbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "509", "text": "Traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "509", "text": "Traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "51", "text": "Nosturiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "51", "text": "Kranbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "510", "text": "Moottorityökone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "510", "text": "Motorredskap", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "511", "text": "Maastoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "511", "text": "Terrängfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "512", "text": "Moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "512", "text": "Motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "513", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "513", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "514", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "514", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "79", "text": "Industritraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "515", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "1", "text": "Kevyt tavarankuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "1", "text": "Lätt varutransport släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "10", "text": "Ajoneuvonkuljetusperävaunu, kokm >750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "10", "text": "Fordontransportsläpvagn, totm > 750kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "100", "text": "Ruohonleikkuukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "100", "text": "Gräsklippningsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "101", "text": "Jäänhoitokone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "101", "text": "Isskötselmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "102", "text": "Konttinosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "102", "text": "Containerlyftkran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "103", "text": "Betonisekoitin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "103", "text": "Betongblandare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "104", "text": "Monitoimikone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "104", "text": "Mångsysslomaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "105", "text": "Lumiaura-harjapuhallin", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "105", "text": "Plog-sopblåsmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "106", "text": "Moottorireki", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "106", "text": "Motorsläde", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "107", "text": "Moottorikelkka", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "107", "text": "Snöskoter", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "108", "text": "Kevyt moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "108", "text": "Lätt motorcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "109", "text": "2-pyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "109", "text": "Tvåhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "110", "text": "3-pyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "110", "text": "Trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "111", "text": "2-pyöräinen tavarapyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "111", "text": "Tvåhjulig varucykell", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "112", "text": "3-pyöräinen tavarapyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "112", "text": "Trehjulig varucykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "113", "text": "Invalidipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "113", "text": "Invalidcykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "114", "text": "Sivuvaunumoottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "114", "text": "Motorcykel med sidvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "115", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "115", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "116", "text": "Nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "116", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "117", "text": "Varsinaisen perävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "31", "text": "Avskrädebil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "118", "text": "Puoliperävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "118", "text": "Dragbil för påhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "119", "text": "Keskiakseliperävaunun vetoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "119", "text": "Dragbil för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "12", "text": "Varsinainen perävaunu, (ka)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "12", "text": "Egentlig släpvagn, (lb)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "120", "text": "Auton alustalle rakennettu työkone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "120", "text": "Motorredskap som byggts på bilunderrede", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "121", "text": "Chassis", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "121", "text": "Alusta", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "122", "text": "Matkatyöperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "122", "text": "Researbetssläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "123", "text": "Betoninkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "123", "text": "Betongtransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "124", "text": "Jätteenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "124", "text": "Sopbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "125", "text": "Koneenkuljetusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "125", "text": "Maskintransportbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "126", "text": "Nelipyöräinen", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "126", "text": "Fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "127", "text": "Hihnakuljetinauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "127", "text": "Remtransportörbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "128", "text": "Rinnekone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "128", "text": "Pistmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "129", "text": "Hitsausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "129", "text": "Svetsningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "13", "text": "Keskiakseliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "13", "text": "Släpvagn med centralaxel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "130", "text": "Kaapelikelan kuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "130", "text": "Kabeltrummasläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "131", "text": "Esteetön taksiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "131", "text": "Tillgänglig taxibil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "132", "text": "Hinattava laite", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "132", "text": "Släpanordning", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "14", "text": "Puoliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "14", "text": "Påhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "15", "text": "Erikoiskuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "15", "text": "Specialtransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "16", "text": "Erikoiskuljetusajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "16", "text": "Specialtransportfordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "17", "text": "Varsinainen säiliöperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "17", "text": "Egentlig tanksläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "18", "text": "Säiliöpuoliperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "18", "text": "Tankpåhängsvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "19", "text": "Dolly", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "19", "text": "Dolly", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "2", "text": "Kevyt veneenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "2", "text": "Lätt båttrailer", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "20", "text": "Kevytperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "20", "text": "Lätt släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "21", "text": "Museoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "21", "text": "Museifordon", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "22", "text": "Myymäläauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "925", "text": "L7e-CP tung mopedbil för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "910", "text": "L3e-A1T pienitehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "910", "text": "L3e-A1T trialmotorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "911", "text": "L3e-A2T keskitehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "911", "text": "L3e-A2T trialmotorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "912", "text": "L3e-A3T suuritehoinen trial-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "912", "text": "L3e-A3T trialmotorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "913", "text": "L4e-A1 pienitehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "913", "text": "L4e-A1 tvåhjulig motorcykel med sidovagn och med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "914", "text": "L4e-A2 keskitehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "914", "text": "L4e-A2 tvåhjulig motorcykel med sidovagn och med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "915", "text": "L4e-A3 suuritehoinen sivuvaunullinen kaksipyöräinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "915", "text": "L4e-A3 tvåhjulig motorcykel med sidovagn och med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "8", "text": "Hevosenkuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "8", "text": "Hästtransportsläpvagn, totm > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "80", "text": "Kiinteistötraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "80", "text": "Fastighetstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "81", "text": "Liikennetraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "81", "text": "Trafiktraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "82", "text": "Haarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "82", "text": "Gaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "83", "text": "Sivuhaarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "83", "text": "Sidogaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "84", "text": "Puomihaarukkanosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "84", "text": "Bomgaffeltruck", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "85", "text": "Kauhakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "85", "text": "Skoplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "86", "text": "Kourakuormain", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "86", "text": "Griplastare", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "87", "text": "Kaivukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "87", "text": "Grävmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "88", "text": "Puominosturi", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "88", "text": "Bomlyftkran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "89", "text": "Kauhakuormain-kaivukone", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "89", "text": "Skoplastare-grävmaskin", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "9", "text": "Matkailuperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "9", "text": "Husvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "69", "text": "Kahden toiminnan erikoisauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "69", "text": "Specialbil med två funktioner", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "7", "text": "Veneenkuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "7", "text": "Båttrailer, totm > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "70", "text": "Kirjastoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "70", "text": "Biblioteksbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "71", "text": "Kaksikäyttöauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "71", "text": "Bil avsedd för två ändamål", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "72", "text": "Alaluokka I (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "72", "text": "Klass I (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "73", "text": "Alaluokka II (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "73", "text": "Klass II (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "74", "text": "Alaluokka III (N1)", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "74", "text": "Klass III (N1)", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "75", "text": "Maataloustraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "75", "text": "Lantbrukstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "76", "text": "Puutarhatraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "76", "text": "Trädgårdstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "77", "text": "Maansiirtotraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "77", "text": "Jordtransporttraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "78", "text": "Metsätraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "78", "text": "Skogstraktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "79", "text": "Teollisuustraktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "516", "text": "Perävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "516", "text": "Släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "517", "text": "L5", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "517", "text": "L5", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "518", "text": "Mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "518", "text": "Moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "519", "text": "Kevyt nelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "519", "text": "Lätt fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "52", "text": "Lakaisuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "52", "text": "Sopningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "520", "text": "Kevyt nelipyörä L6e", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "520", "text": "Lätt fyrhjuling L6e", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "521", "text": "Nelipyörä L7e", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "521", "text": "Fyrhjuling L7e", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "53", "text": "Hinausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "53", "text": "Bogserbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "54", "text": "Kaivukoneauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "54", "text": "Grävmaskinsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "55", "text": "Porausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "55", "text": "Borrningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "56", "text": "Servicebil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "57", "text": "Lumilinko", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "57", "text": "Snöslunga", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "58", "text": "Pankkiauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "58", "text": "Bankbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "59", "text": "Viemärin/putkiston korjausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "59", "text": "Kloaks/rörnätetsrepareringsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "6", "text": "Tavarankuljetusperävaunu, kokm > 750kg", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "6", "text": "Varutransportsläpvagn, tom > 750 kg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "60", "text": "Rehulaitosauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "60", "text": "Foderinrättningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "61", "text": "Selväkielisenä syötettävä nimitys", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "61", "text": "Definition i klartext", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "62", "text": "Nostokoriauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "62", "text": "Bil med lyftkorg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "63", "text": "Imuauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "63", "text": "Sugningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "64", "text": "Polyuretaanin ruiskutusauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "64", "text": "Sprutbil för polyuretan", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "65", "text": "Näyttelyauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "65", "text": "Utställningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "66", "text": "Mittausauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "66", "text": "Mätningsbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "67", "text": "TV/radioauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "67", "text": "TV/radiobil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "68", "text": "Eläinlääkintäauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "68", "text": "Veterinärbil", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "96", "text": "Transportchassi", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "916", "text": "L5e-A kolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "916", "text": "L5e-A trehjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "917", "text": "L5e-B hyötykolmipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "917", "text": "L5e-B trehjuling för nyttotrafik", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "918", "text": "L6e-A kevyt maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "918", "text": "L6e-A lätt fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "919", "text": "L6e-BP henkilömopoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "919", "text": "L6e-BP lätt mopedbil för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "920", "text": "L6e-BU tavaramopoauto", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "920", "text": "L6e-BU lätt mopedbil för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "921", "text": "L7e-A1 alaluokan A1 maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "921", "text": "L7e-A1 A1 - fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "922", "text": "L7e-A2 alaluokan A2 maantiemönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "922", "text": "L7e-A2 A2 - fyrhjuling avsedd för väg", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "923", "text": "L7e-B1 tavallinen maastomönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "923", "text": "L7e-B1 terränggående fyrhjuling", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "924", "text": "L7e-B2 rinnakkain istuttava maastomönkijä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "924", "text": "L7e-B2 side-by-side buggy", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "925", "text": "L7e-CP henkilönelipyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "900", "text": "L3e-A1 pienitehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "900", "text": "L3e-A1 motorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "901", "text": "L1e-A moottorilla varustettu polkupyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "901", "text": "L1e-A motordriven cykel", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "902", "text": "L1e-B kaksipyöräinen mopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "902", "text": "L1e-B tvåhjulig moped", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "903", "text": "L2e-P kolmipyöräinen henkilömopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "903", "text": "L2e-P trehjulig moped konstruerad för passagerarbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "904", "text": "L2e-U kolmipyöräinen tavaramopo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "904", "text": "L2e-U trehjulig moped konstruerad för godsbefordran", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "905", "text": "L3e-A2 keskitehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "905", "text": "L3e-A2 motorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "906", "text": "L3e-A3 suuritehoinen moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "906", "text": "L3e-A3 motorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "907", "text": "L3e-A1E pienitehoinen enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "907", "text": "L3e-A1E enduromotorcykel med låg prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "908", "text": "L3e-A2E keskitehoinen enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "908", "text": "L3e-A2E enduromotorcykel med medelhög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "909", "text": "L3e-A3E suuritehoinen  enduro-moottoripyörä", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "909", "text": "L3e-A3E enduromotorcykel med hög prestanda", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "117", "text": "Dragbil för egentlig släpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "139", "text": "Ensihoitoajoneuvo", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "139", "text": "Prehospital akutsjukvård", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "138", "text": "Raskas moottorikelkka", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "138", "text": "Tung snöskoter", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "133", "text": "Eläintenkuljetusperävaunu", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "133", "text": "Djurtransportsläpvagn", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "927", "text": "Yli 60 km/h traktori", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "927", "text": "Över 60 km/tim traktor", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "934", "text": "Luokka T4.1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "934", "text": "Kategori T4.1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "935", "text": "Luokka T4.1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "935", "text": "Kategori T4.1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "936", "text": "Luokka T4.2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "936", "text": "Kategori T4.2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "937", "text": "Luokka T4.2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "937", "text": "Kategori T4.2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "938", "text": "Luokka T4.3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "938", "text": "Kategori T4.3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "939", "text": "Luokka T4.3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "939", "text": "Kategori T4.3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "933", "text": "Luokka T3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "933", "text": "Kategori T3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "940", "text": "Luokka C1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "940", "text": "Kategori C1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "941", "text": "Luokka C1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "941", "text": "Kategori C1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "942", "text": "Luokka C2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "942", "text": "Kategori C2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "943", "text": "Luokka C2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "943", "text": "Kategori C2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "947", "text": "Luokka C4.1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "947", "text": "Kategori C4.1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "948", "text": "Luokka C4.2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "948", "text": "Kategori C4.2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "949", "text": "Luokka C4.2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "949", "text": "Kategori C4.2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "950", "text": "Luokka C4.3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "950", "text": "Kategori C4.3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "951", "text": "Luokka C4.3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "951", "text": "Kategori C4.3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "944", "text": "Luokka C3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "944", "text": "Kategori C3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "945", "text": "Luokka C3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "945", "text": "Kategori C3b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "946", "text": "Luokka C4.1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "946", "text": "Kategori C4.1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "928", "text": "Luokka T1a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "928", "text": "Kategori T1a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "929", "text": "Luokka T1b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "929", "text": "Kategori T1b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "930", "text": "Luokka T2a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "930", "text": "Kategori T2a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "931", "text": "Luokka T2b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "931", "text": "Kategori T2b", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "932", "text": "Luokka T3a", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "932", "text": "Kategori T3a", "lng": "sv"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "952", "text": "Luokka R3b", "lng": "fi"},
  {"code": "KdTyytiAjoneuvoryhma", "codeValue": "952", "text": "Kategori R3b", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "6", "text": "Teliakselisto (4-akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "6", "text": "Boggiaxelsystem (4-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "7", "text": "Teliakselisto (5-akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "7", "text": "Boggiaxelsystem (5-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "1", "text": "Yksiakselinen", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "1", "text": "Enaxlad", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "2", "text": "Kaksoisakselisto", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "2", "text": "Dubbelaxelsystem", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "3", "text": "Kolmoisakselisto", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "3", "text": "Trippelaxelsystem", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "5", "text": "Teliakselisto (3 -akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "5", "text": "Boggiaxelsystem (3-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "4", "text": "Teliakselisto (2 -akselinen)", "lng": "fi"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "4", "text": "Boggiaxelsystem (2-axlad)", "lng": "sv"},
  {"code": "KdTyytiAkseliryhma", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1001", "text": "WVTA-anmärkning, antecknas ej på registreringsintyget", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1001", "text": "WVTA-huomautus, ei rekisteröintitodistukselle", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1002", "text": "Utrustningens inverkan på mått och massa", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1002", "text": "Varusteiden vaikutus mittoihin ja massoihin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1003", "text": "Intervall för förnyelse av säkerhetssystem", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1003", "text": "Turvajärjestelmien uusintaväli", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1004", "text": "Avvikande metoder för mätning av utsläpp", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1004", "text": "Poikkeavat päästöjen mittausmenetelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1005", "text": "Avvikande metoder för inspektion av bromsar", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1005", "text": "Poikkeavat jarrujen tarkastusmenetelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1007", "text": "LTJ: ytterligare uppgifter om modellen, antecknas ej på registreringsintyget", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1007", "text": "LTJ: mallin ylimääräiset tiedot, ei rekisteröintitodistukselle", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1008", "text": "Regenerativa system/system för efterbehandling av avgaserna", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1008", "text": "Regenerointi-/pakokaasun jälkikäsittelyjärjestelmät", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1009", "text": "Åtgärder som begränsar buller i motorutrymmet och motorn", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1009", "text": "Melua rajoittavat toimenpiteet moottoritilassa ja moottorissa", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2000", "text": "Anmärkningar som antecknas på versionens registreringsbevis", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2000", "text": "Version rekisteröintitodistukselle tulevat huomautukset", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2001", "text": "WVTA-anmärkning, antecknas på registreringsbeviset", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2001", "text": "WVTA-huomautus, rekisteröintitodistukselle tuleva", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "2003", "text": "LTJ: ytterligare uppgifter om modellen, antecknas på registreringsbeviset", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "2003", "text": "LTJ: mallin ylimääräiset tiedot, rekisteröintitodistukselle tulevat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3001", "text": "Grunduppgifter", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3001", "text": "Perustiedot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3002", "text": "Konstruktion", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3002", "text": "Rakenne", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3003", "text": "Chassi", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3003", "text": "Kori", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3004", "text": "Mått", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3004", "text": "Mitat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3005", "text": "Massa", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3005", "text": "Massat", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3006", "text": "Motor", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3006", "text": "Moottori", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3007", "text": "Bromsar", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3007", "text": "Jarrut", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3008", "text": "Komponentförteckning", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3008", "text": "Osaluettelo", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3009", "text": "Allmän", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3009", "text": "Yleinen", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3010", "text": "Draghjul", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3010", "text": "Vetopöytä", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3011", "text": "Dragkoppling", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3011", "text": "Vetokytkin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3012", "text": "Dragkoppling för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3012", "text": "Kap-vetokytkin", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3013", "text": "Kopplingsuppgifter", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3013", "text": "Kytkentätiedot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "3014", "text": "Tillverkningsseriens sista fordon", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "3014", "text": "Häntälupa", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "0001", "text": "Villkor för godkännande av version", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "0001", "text": "Version hyväksyntäehdot", "lng": "fi"},
  {"code": "KdTyytiHuomautus", "codeValue": "1000", "text": "Anmärkningar på version", "lng": "sv"},
  {"code": "KdTyytiHuomautus", "codeValue": "1000", "text": "Version huomautukset", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "1", "text": "Levyjarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "1", "text": "Skivbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "2", "text": "Rumpujarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "2", "text": "Trumbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "3", "text": "Sähköjarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "3", "text": "Elektrisk broms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "4", "text": "Nestejarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "4", "text": "Hydraulisk broms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "5", "text": "Moottorijarru", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "5", "text": "Motorbroms", "lng": "sv"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiJarrutyyppi", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiKokoonpano", "codeValue": "E", "text": "Ei", "lng": "fi"},
  {"code": "KdTyytiKokoonpano", "codeValue": "K", "text": "Kyllä", "lng": "fi"},
  {"code": "KdTyytiKokoonpano", "codeValue": "T", "text": "Ei tiedossa", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "1", "text": "Förbrukning på landsväg", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "1", "text": "Kulutus maantieajossa", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "2", "text": "Förbrukning i stadstrafik", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "2", "text": "Kulutus kaupunkiajossa", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "3", "text": "Kombinerad förbrukning", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "3", "text": "Yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "4", "text": "CO2 Kombinerat", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "4", "text": "CO2 Yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "5", "text": "CO2 Stad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "5", "text": "CO2 Kaupunki", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "6", "text": "CO2 Landsväg", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "6", "text": "CO2 Maantie", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "7", "text": "CO2 Viktat, kombinerat", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "7", "text": "CO2 Painotettu, yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "8", "text": "Förbrukning Viktad, kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "8", "text": "Kulutus Painotettu, yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "10", "text": "WLTP CO2, painotettu yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "10", "text": "WLTP CO2 viktade, blandad körning", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "11", "text": "WLTP I, yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "11", "text": "WLTP I bränsleförbrukning kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "12", "text": "WLTP I, painotettu, yhdistetty kulutus", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "9", "text": "WLTP, CO2 yhdistetty", "lng": "fi"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "9", "text": "WLTP CO2 kombinerad", "lng": "sv"},
  {"code": "KdTyytiKulutuslaji", "codeValue": "12", "text": "WLTP I bränsleförbrukning viktade, blandad körning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "01", "text": "Kappaletavaralava", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "01", "text": "Styckegodsflak", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "02", "text": "Maansiirtolava", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "02", "text": "Jordtransportflak", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "03", "text": "Kappaletavaral+irtokate", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "03", "text": "Styckegodsflak med betäckning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "04", "text": "Puutavarapankot", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "04", "text": "Timmerbanke", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "05", "text": "Umpikori+säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "05", "text": "Skåp+tank", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "06", "text": "Umpikori eristetty", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "06", "text": "Skåp, isolerat", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "07", "text": "Umpikori eristämätön", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "07", "text": "Skåp, oisolerat", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "08", "text": "Painesäiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "08", "text": "Tryckbehållare", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "09", "text": "VAK-säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "09", "text": "TFÄ-tank", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "10", "text": "Muu säiliö", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "10", "text": "Övrig behållare", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "11", "text": "Konttivaruste", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "11", "text": "Containerutrustning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "12", "text": "Jätepuristinkori", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "12", "text": "Buntningspress", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "13", "text": "Ajoneuvon kulj.telineet", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "13", "text": "Fordonstransportanordning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "14", "text": "Muu korirakenne", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "14", "text": "Övrig karosserikonstruktion", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "15", "text": "Vaihtokorilaitteet", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "15", "text": "Växelflakutrustning", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "16", "text": "Tukikaaret ja telttakatos", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "16", "text": "Stödbågar och tältskärm", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "17", "text": "Telttakatos (ee)", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "17", "text": "Tältskärm", "lng": "sv"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "18", "text": "Lämmitettävä lava (ee)", "lng": "fi"},
  {"code": "KdTyytiKuormakorinTyyppi", "codeValue": "18", "text": "Uppvärmbar flak", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "1", "text": "Dragkoppling", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "1", "text": "Vetokytkin", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "2", "text": "Draghjul", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "2", "text": "Vetopöytä", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "3", "text": "Dragkrok", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "3", "text": "Vetokoukku", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "4", "text": "SA-dragkrok", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "4", "text": "SA-vetokoukku", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "5", "text": "Dragstång", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "5", "text": "Vetoaisa", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "6", "text": "Dragbalk", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "6", "text": "Vetopalkki", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "7", "text": "Kopplingstapp", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "7", "text": "Vetotappi", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "8", "text": "Dragkoppling för medelaxelsläpvagn", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "8", "text": "KAP-vetokytkin", "lng": "fi"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "9", "text": "Kulkoppling", "lng": "sv"},
  {"code": "KdTyytiKytkentalaite", "codeValue": "9", "text": "Vetopää", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "1", "text": "Nostolava", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "2", "text": "Luiska liikuntaesteisille", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "3", "text": "Kuljettajan lepotila", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "4", "text": "Koko sivun niiaus", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "5", "text": "Invanostin", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "6", "text": "Etupään niiaus", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "7", "text": "Lisäaskelmat", "lng": "fi"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "1", "text": "Hissplattform", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "2", "text": "Ramp för rörelsehindrade", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "3", "text": "Rastutrymme för förare", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "4", "text": "Nigfunktion på hela sidan", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "5", "text": "Rullstolslyft", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "6", "text": "Nigfunktion i främre delen", "lng": "sv"},
  {"code": "KdTyytiLAnLisavaruste", "codeValue": "7", "text": "Extra steg", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "1", "text": "Tillsatsbromssystem", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "1", "text": "Hidastin", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "2", "text": "Elektromagnetisk broms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "2", "text": "Sähkömagneettinen jarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "3", "text": "Motorbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "3", "text": "Moottorijarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "4", "text": "Avgasbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "4", "text": "Pakokaasujarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "5", "text": "Transmissionsbroms", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "5", "text": "Voimansiirtojarru", "lng": "fi"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiLisajarrutyyppi", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "01", "text": "Nosturi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "01", "text": "Kran", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "02", "text": "Kippi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "02", "text": "Tipp", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "03", "text": "Takalaitanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "03", "text": "Bakgavelhiss", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "04", "text": "Sivulaitanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "04", "text": "Sidogavellyft", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "05", "text": "Vaihtokorilaitteet", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "05", "text": "Växelflakutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "06", "text": "Lämmityslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "06", "text": "Uppvärmningsmaskin", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "07", "text": "Jäähdytyslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "07", "text": "Kylanordning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "08", "text": "Vinssi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "08", "text": "Vinsch", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "09", "text": "Kitkavetolaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "09", "text": "Drivrulle", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "10", "text": "Konttilukot", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "10", "text": "Containerlåsanordning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "11", "text": "Invanostin", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "11", "text": "Invahiss", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "12", "text": "Kolmikaatokippi", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "12", "text": "Trehållstipp", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "13", "text": "Aurausvarustus", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "13", "text": "Plogutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "14", "text": "Aurauspuskuri", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "14", "text": "Plogfäste", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "15", "text": "Lumiaura", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "15", "text": "Snöplog", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "16", "text": "Sohjonkeräyslevy", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "16", "text": "Moddsamlarblad", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "17", "text": "Hiekoituslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "17", "text": "Sandspridare", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "18", "text": "Maalauslaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "18", "text": "Målningsutrustning", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "19", "text": "Harjakone", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "19", "text": "Borstmaskin", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "20", "text": "Jalkalavalaite", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "20", "text": "Flakväxlare", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "21", "text": "Aggregaatti", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "21", "text": "Aggregat", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "22", "text": "Kompressori", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "22", "text": "Kompressor", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "23", "text": "Tukijalat", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "23", "text": "Stödben", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "24", "text": "Ajosillat", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "24", "text": "Ramper", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "25", "text": "SA-vetokoukku", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "25", "text": "SA-dragkrok", "lng": "sv"},
  {"code": "KdTyytiLisalaite", "codeValue": "Z", "text": "Muu, mikä...", "lng": "fi"},
  {"code": "KdTyytiLisalaite", "codeValue": "Z", "text": "Övrig, vilken...", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "0", "text": "Musta", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "0", "text": "Svart", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "1", "text": "Ruskea (beige)", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "1", "text": "Brun (beige)", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "2", "text": "Punainen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "2", "text": "Röd", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "3", "text": "Oranssi", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "3", "text": "Orange", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "4", "text": "Keltainen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "4", "text": "Gul", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "5", "text": "Vihreä", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "5", "text": "Grön", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "6", "text": "Sininen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "6", "text": "Blå", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "7", "text": "Violetti", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "7", "text": "Violett", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "8", "text": "Harmaa", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "8", "text": "Grå", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "9", "text": "Valkoinen", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "9", "text": "Vit", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "a", "text": "Vaalea", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "a", "text": "Ljus", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "b", "text": "Tumma", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "b", "text": "Mörk", "lng": "sv"},
  {"code": "KdTyytiLisavari", "codeValue": "c", "text": "Metallinhohto", "lng": "fi"},
  {"code": "KdTyytiLisavari", "codeValue": "c", "text": "Metalliclack", "lng": "sv"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "E", "text": "Ei", "lng": "fi"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "K", "text": "Kyllä", "lng": "fi"},
  {"code": "KdTyytiLisavarusteenTila", "codeValue": "S", "text": "Keskeneräinen", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "1", "text": "Sluten förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "1", "text": "Umpiohjaamo", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "2", "text": "Skyddsram", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "2", "text": "Suojakehys", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "3", "text": "Förlängd förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "3", "text": "Jatko-ohjaamo", "lng": "fi"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "4", "text": "Öppen förarhytt", "lng": "sv"},
  {"code": "KdTyytiOhjaamotyyppi", "codeValue": "4", "text": "Avo-ohjaamo", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "01", "text": "2 V, 2 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "01", "text": "2 V, 2 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "02", "text": "2 V, 2 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "02", "text": "2 V, 2 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "03", "text": "2 V, 2 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "03", "text": "2 V, 2 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "04", "text": "1 V, 1 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "04", "text": "1 V, 1 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "05", "text": "1 V, 1 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "05", "text": "1 V, 1 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "06", "text": "1 V, 1 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "06", "text": "1 V, 1 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "07", "text": "1 V, 2 H", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "07", "text": "1 V, 2 O", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "08", "text": "1 V, 2 H, bakdörr", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "08", "text": "1 V, 2 O, takaovi", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "09", "text": "1 V, 2 H, pardörrar baktill", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "09", "text": "1 V, 2 O, pariovi takana", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "10", "text": "1 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "10", "text": "1 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "11", "text": "2 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "11", "text": "2 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "12", "text": "3 H (bussar)", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "12", "text": "3 O (linja-autot)", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "X", "text": "Ej tillämpligt", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiOvienSijainti", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "01", "text": "CO", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "02", "text": "CO<sub>2</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "03", "text": "NO<sub>x</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "04", "text": "Savutus [m<sup>-1</sup>]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "05", "text": "Lambda-arvo", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "06", "text": "HC/THC", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "07", "text": "NMHC", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "09", "text": "CH<sub>4</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "10", "text": "Hiukkaset", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "11", "text": "HC/THC+NO<sub>x</sub>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "12", "text": "Hiukkasten lkm * 10<sup>11</sup>", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "13", "text": "ELR-testin savutus [m<sup>-1</sup>]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "14", "text": "CO [g/min]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "15", "text": "HC [g/min]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "16", "text": "NH3 [ppm]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "17", "text": "CO [til%]", "lng": "fi"},
  {"code": "KdTyytiPaastolaji", "codeValue": "05", "text": "Lambdavärde", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "10", "text": "Partiklar", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "15", "text": "HC [g/min]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "16", "text": "NH3 [ppm]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "17", "text": "CO [volym-%]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "04", "text": "Rökvärde [m<sup>-1</sup>]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "01", "text": "CO", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "02", "text": "CO<sub>2</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "03", "text": "NO<sub>x</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "06", "text": "Kolväten/Kolväten totalt", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "07", "text": "Andra kolväten än metan", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "09", "text": "CH<sub>4</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "11", "text": "Kolväten/Kolväten totalt+NO<sub>x</sub>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "12", "text": "Antal partiklar * 10<sup>11</sup>", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "13", "text": "Resultat av ELR-provet rökvärde [m<sup>-1</sup>]", "lng": "sv"},
  {"code": "KdTyytiPaastolaji", "codeValue": "14", "text": "CO [g/min]", "lng": "sv"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "764", "text": "715/2007 - 2023/443AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "763", "text": "595/2009 - 2022/2383 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "761", "text": "715/2007 - 2023/443EB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "762", "text": "715/2007 - 2023/443EC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "760", "text": "715/2007 - 2023/443EA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "759", "text": "595/2009 - 2020/1181 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "485", "text": "715/2007 - 2016/646J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "486", "text": "715/2007 - 2016/646K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "487", "text": "715/2007 - 2016/646L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "488", "text": "715/2007 - 2016/646M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "489", "text": "715/2007 - 2016/646U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "490", "text": "715/2007 - 2016/646V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "491", "text": "715/2007 - 2016/646W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "492", "text": "715/2007 - 2016/646X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "493", "text": "715/2007 - 2016/646Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "494", "text": "715/2007 - 2016/646ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "495", "text": "715/2007 - 2016/646ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "496", "text": "715/2007 - 2016/646ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "497", "text": "715/2007 - 2016/646ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "498", "text": "715/2007 - 2016/646ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "499", "text": "715/2007 - 2016/646ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "500", "text": "715/2007 - 2016/646ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "501", "text": "715/2007 - 2016/646ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "502", "text": "715/2007 - 2016/646ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "503", "text": "715/2007 - 2016/646ZG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "504", "text": "715/2007 - 2016/646ZH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "505", "text": "715/2007 - 2016/646ZI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "506", "text": "715/2007 - 2016/427J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "507", "text": "715/2007 - 2016/427K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "508", "text": "715/2007 - 2016/427L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "509", "text": "715/2007 - 2016/427M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "510", "text": "715/2007 - 2016/427U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "511", "text": "715/2007 - 2016/427V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "512", "text": "715/2007 - 2016/427W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "513", "text": "715/2007 - 2016/427X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "514", "text": "715/2007 - 2016/427Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "515", "text": "715/2007 - 2016/427ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "516", "text": "715/2007 - 2016/427ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "517", "text": "715/2007 - 2016/427ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "518", "text": "715/2007 - 2016/427ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "519", "text": "715/2007 - 2016/427ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "520", "text": "715/2007 - 2016/427ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "521", "text": "715/2007 - 2016/427ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "522", "text": "715/2007 - 2016/427ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "523", "text": "715/2007 - 2016/427ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "524", "text": "595/2009 - 2016/1718 A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "525", "text": "595/2009 - 2016/1718 B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "526", "text": "595/2009 - 2016/1718 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "527", "text": "595/2009 - 2016/1718 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "529", "text": "134/2014-2016/1824A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "585", "text": "715/2007 - 2017/1154AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "586", "text": "715/2007 - 2017/1154BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "587", "text": "715/2007 - 2017/1154AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "588", "text": "715/2007 - 2017/1154BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "589", "text": "715/2007 - 2017/1154AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "590", "text": "715/2007 - 2017/1154BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "591", "text": "715/2007 - 2017/1154AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "592", "text": "715/2007 - 2017/1154AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "593", "text": "715/2007 - 2017/1154AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "594", "text": "715/2007 - 2017/1154AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "595", "text": "715/2007 - 2017/1154AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "596", "text": "715/2007 - 2017/1154AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "597", "text": "715/2007 - 2017/1221AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "598", "text": "715/2007 - 2017/1221BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "599", "text": "715/2007 - 2017/1221AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "600", "text": "715/2007 - 2017/1221BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "601", "text": "715/2007 - 2017/1221AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "602", "text": "715/2007 - 2017/1221BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "603", "text": "715/2007 - 2017/1221AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "604", "text": "715/2007 - 2017/1221AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "605", "text": "715/2007 - 2017/1221AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "606", "text": "715/2007 - 2017/1221AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "607", "text": "715/2007 - 2017/1221BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "608", "text": "715/2007 - 2017/1221AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "609", "text": "715/2007 - 2017/1221BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "610", "text": "715/2007 - 2017/1221AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "611", "text": "715/2007 - 2017/1221BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "612", "text": "715/2007 - 2017/1221AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "613", "text": "715/2007 - 2017/1221AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "614", "text": "715/2007 - 2017/1221AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "615", "text": "715/2007 - 2017/1221AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "616", "text": "715/2007 - 2017/1221AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "617", "text": "715/2007 - 2017/1221AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "584", "text": "715/2007 - 2017/1154AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "530", "text": "715/2007 - 2017/1347AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "531", "text": "715/2007 - 2017/1347BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "532", "text": "715/2007 - 2017/1347AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "533", "text": "715/2007 - 2017/1347BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "534", "text": "715/2007 - 2017/1347AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "535", "text": "715/2007 - 2017/1347BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "536", "text": "715/2007 - 2017/1347AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "537", "text": "715/2007 - 2017/1347AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "538", "text": "715/2007 - 2017/1347AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "539", "text": "715/2007 - 2017/1347AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "540", "text": "715/2007 - 2017/1347BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "541", "text": "715/2007 - 2017/1347AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "542", "text": "715/2007 - 2017/1347BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "543", "text": "715/2007 - 2017/1347AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "544", "text": "715/2007 - 2017/1347BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "545", "text": "715/2007 - 2017/1347AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "546", "text": "715/2007 - 2017/1347AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "547", "text": "715/2007 - 2017/1347AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "548", "text": "715/2007 - 2017/1347AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "549", "text": "715/2007 - 2017/1347AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "550", "text": "715/2007 - 2017/1347AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "551", "text": "595/2009 - 2017/1151 A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "552", "text": "595/2009 - 2017/1151 B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "553", "text": "595/2009 - 2017/1151 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "554", "text": "595/2009 - 2017/1151 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "555", "text": "715/2007 - 2017/1151AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "556", "text": "715/2007 - 2017/1151BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "557", "text": "715/2007 - 2017/1151AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "558", "text": "715/2007 - 2017/1151BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "559", "text": "715/2007 - 2017/1151AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "560", "text": "715/2007 - 2017/1151BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "561", "text": "715/2007 - 2017/1151AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "562", "text": "715/2007 - 2017/1151AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "563", "text": "715/2007 - 2017/1151AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "564", "text": "715/2007 - 2017/1151AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "565", "text": "715/2007 - 2017/1151BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "566", "text": "715/2007 - 2017/1151AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "567", "text": "715/2007 - 2017/1151BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "568", "text": "715/2007 - 2017/1151AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "569", "text": "715/2007 - 2017/1151BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "570", "text": "715/2007 - 2017/1151AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "571", "text": "715/2007 - 2017/1151AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "572", "text": "715/2007 - 2017/1151AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "573", "text": "715/2007 - 2017/1151AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "574", "text": "715/2007 - 2017/1151AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "575", "text": "715/2007 - 2017/1151AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "576", "text": "715/2007 - 2017/1154AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "577", "text": "715/2007 - 2017/1154BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "578", "text": "715/2007 - 2017/1154AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "579", "text": "715/2007 - 2017/1154BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "580", "text": "715/2007 - 2017/1154AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "581", "text": "715/2007 - 2017/1154BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "582", "text": "715/2007 - 2017/1154AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "583", "text": "715/2007 - 2017/1154AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "618", "text": "715/2007 - 2017/1347W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "619", "text": "715/2007 - 2017/1347X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "620", "text": "715/2007 - 2017/1347Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "621", "text": "715/2007 - 2017/1347ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "622", "text": "715/2007 - 2017/1347ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "623", "text": "715/2007 - 2017/1347ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "624", "text": "715/2007 - 2017/1347ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "625", "text": "715/2007 - 2017/1347ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "626", "text": "715/2007 - 2017/1347ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "627", "text": "715/2007 - 2017/1347ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "628", "text": "715/2007 - 2017/1347ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "629", "text": "715/2007 - 2017/1347ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "630", "text": "715/2007 - 2017/1151W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "631", "text": "715/2007 - 2017/1151X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "632", "text": "715/2007 - 2017/1151Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "633", "text": "715/2007 - 2017/1151ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "634", "text": "715/2007 - 2017/1151ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "635", "text": "715/2007 - 2017/1151ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "636", "text": "715/2007 - 2017/1151ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "637", "text": "715/2007 - 2017/1151ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "638", "text": "715/2007 - 2017/1151ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "639", "text": "715/2007 - 2017/1151ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "640", "text": "715/2007 - 2017/1151ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "641", "text": "715/2007 - 2017/1151ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "642", "text": "715/2007 - 2017/1154W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "643", "text": "715/2007 - 2017/1154X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "644", "text": "715/2007 - 2017/1154Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "645", "text": "715/2007 - 2017/1154ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "646", "text": "715/2007 - 2017/1154ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "647", "text": "715/2007 - 2017/1154ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "648", "text": "715/2007 - 2017/1154ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "649", "text": "715/2007 - 2017/1154ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "650", "text": "715/2007 - 2017/1154ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "651", "text": "715/2007 - 2017/1154ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "652", "text": "715/2007 - 2017/1154ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "653", "text": "715/2007 - 2017/1154ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "654", "text": "715/2007 - 2017/1221W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "655", "text": "715/2007 - 2017/1221X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "656", "text": "715/2007 - 2017/1221Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "657", "text": "715/2007 - 2017/1221ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "658", "text": "715/2007 - 2017/1221ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "659", "text": "715/2007 - 2017/1221ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "660", "text": "715/2007 - 2017/1221ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "661", "text": "715/2007 - 2017/1221ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "662", "text": "715/2007 - 2017/1221ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "663", "text": "715/2007 - 2017/1221ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "664", "text": "715/2007 - 2017/1221ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "665", "text": "715/2007 - 2017/1221ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "758", "text": "", "lng": "sv"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "758", "text": "134/2014 - 2016/1824A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "742", "text": "2018/985 - 2018/985 C1 NRS-vr-1b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "748", "text": "2018/985 - 2018/985 C1 NRE-v-1", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "757", "text": "134/2014 - 2020/239A3 (Euro 5+)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "746", "text": "2018/985 - 2018/985 C1 NRS-v-2b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "743", "text": "2018/985 - 2018/985 C1 NRS-vi-1a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "751", "text": "2018/985 - 2018/985 C1 NRE-v-4", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "747", "text": "2018/985 - 2018/985 C1 NRS-v-3", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "752", "text": "2018/985 - 2018/985 C1 NRE-v-5", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "754", "text": "2018/985 - 2018/985 C1 NRE-v-7", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "749", "text": "2018/985 - 2018/985 C1 NRE-v-2", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "756", "text": "134/2014 - 2020/239A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "750", "text": "2018/985 - 2018/985 C1 NRE-v-3", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "753", "text": "2018/985 - 2018/985 C1 NRE-v-6", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "741", "text": "2018/985 - 2018/985 C1 NRS-vr-1a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "744", "text": "2018/985 - 2018/985 C1 NRS-vi-1b", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "755", "text": "134/2014 - 2020/239A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "745", "text": "2018/985 - 2018/985 C1 NRS-v-2a", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "672", "text": "2016/1628 - EV1/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "673", "text": "2016/1628 - EV2/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "674", "text": "2016/1628 - EV3/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "675", "text": "2016/1628 - EV4/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "676", "text": "2016/1628 - EV5/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "677", "text": "2016/1628 - EV6/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "678", "text": "2016/1628 - EV7/D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "679", "text": "2016/1628 - SM1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "680", "text": "2016/1628 - SRA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "681", "text": "2016/1628 - SRA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "682", "text": "2016/1628 - SRA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "683", "text": "2016/1628 - SRB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "684", "text": "2016/1628 - SRB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "685", "text": "2016/1628 - SRB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "686", "text": "2016/1628 - SYA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "687", "text": "2016/1628 - SYA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "688", "text": "2016/1628 - SYA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "689", "text": "2016/1628 - SYB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "690", "text": "2016/1628 - SYB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "691", "text": "2016/1628 - SYB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "692", "text": "2016/1628 - SVA1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "693", "text": "2016/1628 - SVA2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "694", "text": "2016/1628 - SVA3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "695", "text": "2016/1628 - SVB1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "696", "text": "2016/1628 - SVB2/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "697", "text": "2016/1628 - SVB3/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "698", "text": "2016/1628 - SV31/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "699", "text": "2016/1628 - SV32/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "700", "text": "2016/1628 - SV33/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "666", "text": "595/2009 - 2017/1347 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "667", "text": "595/2009 - 2017/1347 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "668", "text": "595/2009 - 2017/2400 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "669", "text": "595/2009 - 2017/2400 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "670", "text": "134/2014-2018/295A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "671", "text": "134/2014-2018/295A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "737", "text": "715/2007 - 2017/1347DG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "738", "text": "715/2007 - 2017/1347AM (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "739", "text": "595/2009 - 2019/1939 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "740", "text": "595/2009 - 2019/1939 E (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "701", "text": "2016/1628 - AT1/P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "702", "text": "715/2007 - 2018/1832AA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "703", "text": "715/2007 - 2018/1832BA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "704", "text": "715/2007 - 2018/1832AB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "705", "text": "715/2007 - 2018/1832BB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "706", "text": "715/2007 - 2018/1832AC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "707", "text": "715/2007 - 2018/1832BC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "708", "text": "715/2007 - 2018/1832AD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "709", "text": "715/2007 - 2018/1832AE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "710", "text": "715/2007 - 2018/1832AF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "711", "text": "715/2007 - 2018/1832AG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "712", "text": "715/2007 - 2018/1832BG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "713", "text": "715/2007 - 2018/1832CG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "714", "text": "715/2007 - 2018/1832DG (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "715", "text": "715/2007 - 2018/1832AH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "716", "text": "715/2007 - 2018/1832BH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "717", "text": "715/2007 - 2018/1832CH (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "718", "text": "715/2007 - 2018/1832AI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "719", "text": "715/2007 - 2018/1832BI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "720", "text": "715/2007 - 2018/1832CI (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "721", "text": "715/2007 - 2018/1832AJ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "722", "text": "715/2007 - 2018/1832AK (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "723", "text": "715/2007 - 2018/1832AL (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "724", "text": "715/2007 - 2018/1832AM (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "725", "text": "715/2007 - 2018/1832AN (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "726", "text": "715/2007 - 2018/1832AO (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "727", "text": "715/2007 - 2018/1832AP (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "728", "text": "715/2007 - 2018/1832AQ (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "729", "text": "715/2007 - 2018/1832AR (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "730", "text": "715/2007 - 2018/1832AX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "731", "text": "715/2007 - 2018/1832AY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "732", "text": "715/2007 - 2018/1832AZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "733", "text": "595/2009 - 2019/318 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "734", "text": "595/2009 - 2019/318 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "735", "text": "595/2009 - 2018/932 C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "736", "text": "595/2009 - 2018/932 D (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "462", "text": "715/2007 - 2015/45J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "463", "text": "715/2007 - 2015/45K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "464", "text": "715/2007 - 2015/45L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "465", "text": "715/2007 - 2015/45M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "96", "text": "2005/55/EY - 2006/51E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "466", "text": "715/2007 - 2015/45T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "467", "text": "715/2007 - 2015/45U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "468", "text": "715/2007 - 2015/45V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "469", "text": "715/2007 - 2015/45W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "470", "text": "715/2007 - 2015/45X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "471", "text": "715/2007 - 2015/45Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "472", "text": "715/2007 - 2015/45ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "473", "text": "715/2007 - 2015/45ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "474", "text": "715/2007 - 2015/45ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "475", "text": "715/2007 - 2015/45ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "476", "text": "715/2007 - 2015/45ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "477", "text": "715/2007 - 2015/45ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "478", "text": "715/2007 - 2015/45ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "479", "text": "715/2007 - 2015/45ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "480", "text": "715/2007 - 2015/45ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "481", "text": "97/68/EY - 2012/46L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "482", "text": "97/68/EY - 2012/46M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "483", "text": "97/68/EY - 2012/46N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "484", "text": "97/68/EY - 2012/46P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "450", "text": "E83/06U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "451", "text": "E83/06V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "452", "text": "E83/06W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "453", "text": "E83/06X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "454", "text": "E83/06Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "455", "text": "E83/06ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "456", "text": "E83/06ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "457", "text": "E83/06ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "458", "text": "134/2014-134/2014A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "459", "text": "134/2014-134/2014A2 (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "460", "text": "E40A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "461", "text": "E47A1 (Euro 4)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "104", "text": "97/24/EY 5 luku - 2003/77A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "105", "text": "97/24/EY 5 luku - 2003/77B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "106", "text": "97/24/EY 5 luku - 2005/30", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "107", "text": "97/24/EY 5 luku - 2005/30A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "108", "text": "97/24/EY 5 luku - 2005/30B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "109", "text": "97/24/EY 5 luku - 2006/27", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "110", "text": "97/24/EY 5 luku - 2006/27A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "111", "text": "97/24/EY 5 luku - 2006/27B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "112", "text": "97/24/EY 5 luku - 2006/72", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "430", "text": "595/2009 - 133/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "431", "text": "595/2009 - 133/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "432", "text": "97/68/EY - 2002/88S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "433", "text": "97/68/EY - 2004/26S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "434", "text": "97/68/EY - 2006/105S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "435", "text": "97/68/EY - 2010/26S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "436", "text": "97/68/EY - 2011/88S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "437", "text": "97/68/EY - 2012/46S", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "438", "text": "97/24/EY 5 luku - 2002/51", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "439", "text": "595/2009 - 627/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "440", "text": "595/2009 - 627/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "441", "text": "595/2009 - 627/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "442", "text": "97/24/EY 5 luku - 2013/60", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "443", "text": "97/24/EY 5 luku - 2013/60A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "444", "text": "97/24/EY 5 luku - 2013/60B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "445", "text": "97/24/EY 5 luku - 2013/60C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "446", "text": "2000/25/EY - 2014/43C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "447", "text": "2000/25/EY - 2014/43D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "448", "text": "2000/25/EY - 2014/43E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "449", "text": "E83/06T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "351", "text": "715/2007 - 171/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "352", "text": "715/2007 - 171/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "353", "text": "715/2007 - 171/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "354", "text": "715/2007 - 171/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "355", "text": "715/2007 - 171/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "356", "text": "715/2007 - 171/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "357", "text": "715/2007 - 171/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "358", "text": "715/2007 - 171/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "359", "text": "715/2007 - 171/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "360", "text": "715/2007 - 171/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "361", "text": "715/2007 - 171/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "362", "text": "715/2007 - 171/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "363", "text": "715/2007 - 171/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "364", "text": "715/2007 - 171/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "365", "text": "715/2007 - 171/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "366", "text": "715/2007 - 171/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "367", "text": "715/2007 - 171/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "368", "text": "715/2007 - 171/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "410", "text": "715/2007 - 136/2014K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "411", "text": "715/2007 - 136/2014L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "412", "text": "715/2007 - 136/2014M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "413", "text": "715/2007 - 136/2014T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "414", "text": "715/2007 - 136/2014U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "415", "text": "715/2007 - 136/2014V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "416", "text": "715/2007 - 136/2014W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "417", "text": "715/2007 - 136/2014X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "418", "text": "715/2007 - 136/2014Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "419", "text": "715/2007 - 136/2014ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "420", "text": "715/2007 - 136/2014ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "421", "text": "715/2007 - 136/2014ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "422", "text": "715/2007 - 136/2014ZD (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "423", "text": "715/2007 - 136/2014ZE (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "424", "text": "715/2007 - 136/2014ZF (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "425", "text": "715/2007 - 136/2014ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "426", "text": "715/2007 - 136/2014ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "427", "text": "715/2007 - 136/2014ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "428", "text": "97/24/EY 5 luku - 2006/27C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "429", "text": "595/2009 - 133/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "370", "text": "715/2007 - 630/2012M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "371", "text": "715/2007 - 630/2012G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "372", "text": "715/2007 - 195/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "373", "text": "715/2007 - 195/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "374", "text": "715/2007 - 195/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "375", "text": "715/2007 - 195/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "376", "text": "715/2007 - 195/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "377", "text": "715/2007 - 195/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "378", "text": "715/2007 - 195/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "379", "text": "715/2007 - 195/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "380", "text": "715/2007 - 195/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "381", "text": "715/2007 - 195/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "382", "text": "715/2007 - 195/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "383", "text": "715/2007 - 195/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "384", "text": "715/2007 - 195/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "385", "text": "715/2007 - 195/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "386", "text": "715/2007 - 195/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "387", "text": "715/2007 - 195/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "388", "text": "715/2007 - 195/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "389", "text": "715/2007 - 195/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "390", "text": "715/2007 - 195/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "391", "text": "715/2007 - 195/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "392", "text": "715/2007 - 195/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "393", "text": "715/2007 - 195/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "394", "text": "715/2007 - 195/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "395", "text": "715/2007 - 195/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "396", "text": "715/2007 - 195/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "397", "text": "715/2007 - 195/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "398", "text": "715/2007 - 195/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "399", "text": "715/2007 - 195/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "400", "text": "715/2007 - 195/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "401", "text": "715/2007 - 195/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "402", "text": "715/2007 - 195/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "403", "text": "E49/06A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "404", "text": "E49/06B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "405", "text": "E49/06C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "406", "text": "595/2009 - 136/2014A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "407", "text": "595/2009 - 136/2014B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "408", "text": "595/2009 - 136/2014C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "528", "text": "97/68/EY - 2012/46K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "30", "text": "97/68/EY - 97/68A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "31", "text": "97/68/EY - 97/68B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "32", "text": "97/68/EY - 97/68C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "33", "text": "97/68/EY - 97/68D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "34", "text": "97/68/EY - 97/68E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "35", "text": "97/68/EY - 97/68F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "36", "text": "97/68/EY - 97/68G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "37", "text": "97/68/EY - 2001/63D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "38", "text": "97/68/EY - 2001/63E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "01", "text": "70/220/ETY - 94/12/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "02", "text": "70/220/ETY - 96/44/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "03", "text": "70/220/ETY - 96/69/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "04", "text": "70/220/ETY - 98/69/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "05", "text": "70/220/ETY - 98/69A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "06", "text": "70/220/ETY - 98/77/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "07", "text": "70/220/ETY - 1999/102/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "08", "text": "70/220/ETY - 1999/102A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "09", "text": "70/220/ETY - 1999/102B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "10", "text": "70/220/ETY - 2001/1A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "11", "text": "70/220/ETY - 2001/1B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "12", "text": "70/220/ETY - 2001/100A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "13", "text": "70/220/ETY - 2001/100B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "14", "text": "70/220/ETY - 2002/80A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "15", "text": "88/77/ETY - 96/1/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "16", "text": "88/77/ETY - 1999/96/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "17", "text": "88/77/ETY - 2001/27A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "18", "text": "97/24/EY 5 luku - 97/24A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "19", "text": "97/24/EY 5 luku - 2002/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "20", "text": "70/220/ETY - 2002/80B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "21", "text": "70/220/ETY - 98/69B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "22", "text": "70/220/ETY - 2003/76A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "23", "text": "70/220/ETY - 2003/76B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "24", "text": "E83/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "25", "text": "E83B/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "26", "text": "E83D/03", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "27", "text": "E83/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "28", "text": "E49/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "29", "text": "E49B/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "347", "text": "715/2007 - 171/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "348", "text": "715/2007 - 171/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "349", "text": "715/2007 - 171/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "350", "text": "715/2007 - 171/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "291", "text": "715/2007 - 459/2012R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "292", "text": "715/2007 - 459/2012S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "293", "text": "715/2007 - 459/2012T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "294", "text": "715/2007 - 459/2012U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "295", "text": "715/2007 - 459/2012V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "296", "text": "715/2007 - 459/2012M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "297", "text": "715/2007 - 459/2012H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "298", "text": "715/2007 - 459/2012L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "299", "text": "715/2007 - 630/2012T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "300", "text": "715/2007 - 630/2012A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "409", "text": "715/2007 - 136/2014J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "302", "text": "715/2007 - 630/2012L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "303", "text": "715/2007 - 630/2012I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "304", "text": "715/2007 - 630/2012H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "305", "text": "715/2007 - 630/2012K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "306", "text": "715/2007 - 143/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "307", "text": "715/2007 - 143/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "308", "text": "715/2007 - 143/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "309", "text": "715/2007 - 143/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "310", "text": "715/2007 - 143/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "311", "text": "715/2007 - 143/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "312", "text": "715/2007 - 143/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "313", "text": "715/2007 - 143/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "314", "text": "715/2007 - 143/2013I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "315", "text": "715/2007 - 143/2013J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "316", "text": "715/2007 - 143/2013K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "317", "text": "715/2007 - 143/2013L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "318", "text": "715/2007 - 143/2013M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "319", "text": "715/2007 - 143/2013N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "320", "text": "715/2007 - 143/2013O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "321", "text": "715/2007 - 143/2013P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "369", "text": "715/2007 - 171/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "269", "text": "715/2007 - 459/2012ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "270", "text": "715/2007 - 459/2012ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "271", "text": "715/2007 - 459/2012ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "272", "text": "715/2007 - 459/2012F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "273", "text": "715/2007 - 459/2012J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "274", "text": "715/2007 - 630/2012W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "275", "text": "715/2007 - 630/2012X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "276", "text": "715/2007 - 630/2012Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "277", "text": "715/2007 - 630/2012ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "278", "text": "715/2007 - 630/2012ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "279", "text": "715/2007 - 630/2012ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "280", "text": "715/2007 - 630/2012ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "281", "text": "715/2007 - 630/2012ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "282", "text": "715/2007 - 630/2012ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "283", "text": "715/2007 - 630/2012F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "284", "text": "715/2007 - 630/2012J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "285", "text": "2005/55/EY - 2008/74", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "286", "text": "715/2007 - 459/2012I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "287", "text": "715/2007 - 459/2012N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "288", "text": "715/2007 - 459/2012O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "289", "text": "715/2007 - 459/2012P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "290", "text": "715/2007 - 459/2012Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "229", "text": "715/2007 - 566/2011O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "230", "text": "715/2007 - 566/2011P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "231", "text": "715/2007 - 566/2011Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "232", "text": "715/2007 - 566/2011R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "233", "text": "715/2007 - 566/2011S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "234", "text": "715/2007 - 566/2011T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "235", "text": "715/2007 - 566/2011U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "236", "text": "715/2007 - 566/2011V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "237", "text": "E83/06J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "322", "text": "715/2007 - 143/2013Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "323", "text": "715/2007 - 143/2013R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "324", "text": "715/2007 - 143/2013S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "325", "text": "715/2007 - 143/2013T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "326", "text": "715/2007 - 143/2013U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "327", "text": "715/2007 - 143/2013V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "328", "text": "715/2007 - 143/2013W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "329", "text": "715/2007 - 143/2013X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "330", "text": "715/2007 - 143/2013Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "331", "text": "715/2007 - 143/2013ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "332", "text": "715/2007 - 143/2013ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "333", "text": "715/2007 - 143/2013ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "334", "text": "715/2007 - 143/2013ZX", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "335", "text": "715/2007 - 143/2013ZY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "336", "text": "715/2007 - 143/2013ZZ", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "337", "text": "97/68/EY - 2012/46Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "338", "text": "97/68/EY - 2012/46R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "339", "text": "715/2007 - 171/2013A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "340", "text": "715/2007 - 171/2013B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "341", "text": "715/2007 - 171/2013C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "342", "text": "715/2007 - 171/2013D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "343", "text": "715/2007 - 171/2013E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "344", "text": "715/2007 - 171/2013F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "345", "text": "715/2007 - 171/2013G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "346", "text": "715/2007 - 171/2013H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "191", "text": "97/68/EY - 2010/26K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "192", "text": "97/68/EY - 2010/26L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "193", "text": "97/68/EY - 2010/26M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "194", "text": "97/68/EY - 2010/26N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "195", "text": "97/68/EY - 2010/26P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "196", "text": "97/68/EY - 2010/26Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "197", "text": "97/68/EY - 2010/26R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "198", "text": "2000/25/EY - 2010/22C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "199", "text": "2000/25/EY - 2010/22D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "200", "text": "2000/25/EY - 2010/22E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "201", "text": "97/68/EY - 2006/105D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "202", "text": "97/68/EY - 2006/105E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "203", "text": "97/68/EY - 2006/105F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "204", "text": "97/68/EY - 2006/105G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "205", "text": "97/68/EY - 2006/105H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "206", "text": "97/68/EY - 2006/105I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "207", "text": "97/68/EY - 2006/105J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "208", "text": "97/68/EY - 2006/105K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "209", "text": "97/68/EY - 2006/105L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "210", "text": "97/68/EY - 2006/105M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "211", "text": "97/68/EY - 2006/105N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "212", "text": "97/68/EY - 2006/105P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "213", "text": "97/68/EY - 2006/105Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "214", "text": "97/68/EY - 2006/105R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "215", "text": "715/2007 - 566/2011A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "216", "text": "715/2007 - 566/2011B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "217", "text": "715/2007 - 566/2011C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "218", "text": "715/2007 - 566/2011D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "219", "text": "715/2007 - 566/2011E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "220", "text": "715/2007 - 566/2011F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "221", "text": "715/2007 - 566/2011G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "222", "text": "715/2007 - 566/2011H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "223", "text": "715/2007 - 566/2011I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "224", "text": "715/2007 - 566/2011J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "225", "text": "715/2007 - 566/2011K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "226", "text": "715/2007 - 566/2011L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "227", "text": "715/2007 - 566/2011M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "228", "text": "715/2007 - 566/2011N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "238", "text": "E83/06K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "239", "text": "E83/06L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "240", "text": "E83/06M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "241", "text": "2000/25/EY - 2011/72C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "242", "text": "2000/25/EY - 2011/72D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "243", "text": "2000/25/EY - 2011/72E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "244", "text": "2000/25/EY - 2011/87C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "245", "text": "2000/25/EY - 2011/87D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "246", "text": "2000/25/EY - 2011/87E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "247", "text": "97/68/EY - 2011/88H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "248", "text": "97/68/EY - 2011/88I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "249", "text": "97/68/EY - 2011/88J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "250", "text": "97/68/EY - 2011/88K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "251", "text": "97/68/EY - 2011/88L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "252", "text": "97/68/EY - 2011/88M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "253", "text": "97/68/EY - 2011/88N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "254", "text": "97/68/EY - 2011/88P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "255", "text": "97/68/EY - 2011/88Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "301", "text": "715/2007 - 459/2012K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "257", "text": "595/2009 - 582/2011A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "258", "text": "595/2009 - 582/2011B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "259", "text": "595/2009 - 582/2011C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "260", "text": "595/2009 - 64/2012A (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "261", "text": "595/2009 - 64/2012B (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "262", "text": "595/2009 - 64/2012C (Euro VI)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "263", "text": "715/2007 - 459/2012W (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "264", "text": "715/2007 - 459/2012X (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "265", "text": "715/2007 - 459/2012Y (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "266", "text": "715/2007 - 459/2012ZA (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "267", "text": "715/2007 - 459/2012ZB (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "268", "text": "715/2007 - 459/2012ZC (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "113", "text": "97/24/EY 5 luku - 2006/72A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "114", "text": "97/24/EY 5 luku - 2006/72B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "115", "text": "97/24/EY 5 luku - 97/24B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "116", "text": "97/24/EY 5 luku - 2006/72C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "117", "text": "97/24/EY 5 luku - 2006/120", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "118", "text": "97/24/EY 5 luku - 2006/120A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "119", "text": "97/24/EY 5 luku - 2006/120B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "120", "text": "97/24/EY 5 luku - 2006/120C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "121", "text": "2005/55/EY - 2006/81A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "122", "text": "2005/55/EY - 2006/81B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "123", "text": "2005/55/EY - 2006/81C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "124", "text": "2005/55/EY - 2006/81D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "125", "text": "2005/55/EY - 2006/81E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "126", "text": "2005/55/EY - 2006/81F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "127", "text": "2005/55/EY - 2006/81G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "128", "text": "2005/55/EY - 2006/81H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "129", "text": "2005/55/EY - 2006/81I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "130", "text": "2005/55/EY - 2006/81J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "131", "text": "2005/55/EY - 2006/81K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "132", "text": "2005/55/EY - 2008/74B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "133", "text": "2005/55/EY - 2008/74C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "134", "text": "2005/55/EY - 2008/74D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "135", "text": "2005/55/EY - 2008/74E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "136", "text": "2005/55/EY - 2008/74F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "137", "text": "2005/55/EY - 2008/74G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "138", "text": "2005/55/EY - 2008/74H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "139", "text": "2005/55/EY - 2008/74I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "256", "text": "97/68/EY - 2011/88R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "141", "text": "2005/55/EY - 2008/74K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "142", "text": "E49B/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "143", "text": "E49C/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "144", "text": "E49D/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "145", "text": "E49E/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "146", "text": "E49F/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "147", "text": "E49G/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "148", "text": "E49H/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "149", "text": "E49I/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "150", "text": "E49J/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "151", "text": "E49K/05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "152", "text": "E96/00", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "153", "text": "E96D/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "168", "text": "715/2007 - 692/2008G (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "169", "text": "715/2007 - 692/2008H (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "170", "text": "715/2007 - 692/2008I (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "171", "text": "715/2007 - 692/2008J (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "172", "text": "715/2007 - 692/2008K (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "173", "text": "715/2007 - 692/2008L (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "174", "text": "715/2007 - 692/2008M (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "175", "text": "715/2007 - 692/2008N (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "176", "text": "715/2007 - 692/2008O (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "177", "text": "715/2007 - 692/2008P (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "178", "text": "715/2007 - 692/2008Q (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "179", "text": "715/2007 - 692/2008R (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "180", "text": "715/2007 - 692/2008S (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "181", "text": "715/2007 - 692/2008T (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "182", "text": "715/2007 - 692/2008U (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "183", "text": "715/2007 - 692/2008V (Euro 6)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "184", "text": "97/24/EY 5 luku - 2009/108", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "185", "text": "97/24/EY 5 luku - 2009/108A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "186", "text": "97/24/EY 5 luku - 2009/108B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "187", "text": "97/24/EY 5 luku - 2009/108C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "188", "text": "97/68/EY - 2010/26H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "189", "text": "97/68/EY - 2010/26I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "190", "text": "97/68/EY - 2010/26J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "39", "text": "97/68/EY - 2001/63F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "40", "text": "97/68/EY - 2001/63G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "41", "text": "2000/25/EY - 2000/25A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "42", "text": "2000/25/EY - 2000/25B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "43", "text": "97/24/EY 5 luku - 2002/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "66", "text": "97/68/EY - 2004/26P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "44", "text": "97/24/EY 5 luku - 2002/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "45", "text": "97/24/EY 5 luku - 2002/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "46", "text": "70/220/ETY - 2006/96B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "47", "text": "97/24/EY 5 luku - 97/24A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "48", "text": "97/24/EY 5 luku - 97/24B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "49", "text": "E83RII-05", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "50", "text": "88/77/ETY - 2001/27B1/EY", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "51", "text": "97/68/EY - 2002/88D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "52", "text": "97/68/EY - 2002/88E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "53", "text": "97/68/EY - 2002/88F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "54", "text": "97/68/EY - 2002/88G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "55", "text": "97/68/EY - 2004/26D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "56", "text": "97/68/EY - 2004/26E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "57", "text": "97/68/EY - 2004/26F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "58", "text": "97/68/EY - 2004/26G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "59", "text": "97/68/EY - 2004/26H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "60", "text": "97/68/EY - 2004/26I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "61", "text": "97/68/EY - 2004/26J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "62", "text": "97/68/EY - 2004/26K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "63", "text": "97/68/EY - 2004/26L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "64", "text": "97/68/EY - 2004/26M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "65", "text": "97/68/EY - 2004/26N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "67", "text": "97/68/EY - 2004/26Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "68", "text": "97/68/EY - 2004/26R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "69", "text": "88/77/ETY - 2001/27B2", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "154", "text": "E96E/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "155", "text": "E96F/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "156", "text": "E96G/01", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "157", "text": "E96H/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "158", "text": "E96I/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "159", "text": "E96J/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "160", "text": "E96K/02", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "161", "text": "Euro 5", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "162", "text": "715/2007 - 692/2008A (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "163", "text": "715/2007 - 692/2008B (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "164", "text": "715/2007 - 692/2008C (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "165", "text": "715/2007 - 692/2008D (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "166", "text": "715/2007 - 692/2008E (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "167", "text": "715/2007 - 692/2008F (Euro 5)", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "70", "text": "88/77/ETY - 2001/27C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "71", "text": "2000/25/EY - 2005/13H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "72", "text": "2000/25/EY - 2005/13I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "73", "text": "2000/25/EY - 2005/13J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "74", "text": "2000/25/EY - 2005/13K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "75", "text": "2000/25/EY - 2005/13L", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "76", "text": "2000/25/EY - 2005/13M", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "77", "text": "2000/25/EY - 2005/13N", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "78", "text": "2000/25/EY - 2005/13P", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "79", "text": "2000/25/EY - 2005/13Q", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "80", "text": "2000/25/EY - 2005/13R", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "81", "text": "2005/55/EY - 2005/78A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "82", "text": "2005/55/EY - 2005/78B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "83", "text": "2005/55/EY - 2005/78C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "84", "text": "2005/55/EY - 2005/78D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "85", "text": "2005/55/EY - 2005/78E", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "86", "text": "2005/55/EY - 2005/78F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "87", "text": "2005/55/EY - 2005/78G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "88", "text": "2005/55/EY - 2005/78H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "89", "text": "2005/55/EY - 2005/78I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "90", "text": "2005/55/EY - 2005/78J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "91", "text": "2005/55/EY - 2005/78K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "92", "text": "2005/55/EY - 2006/51A", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "93", "text": "2005/55/EY - 2006/51B", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "94", "text": "2005/55/EY - 2006/51C", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "95", "text": "2005/55/EY - 2006/51D", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "140", "text": "2005/55/EY - 2008/74J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "97", "text": "2005/55/EY - 2006/51F", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "98", "text": "2005/55/EY - 2006/51G", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "99", "text": "2005/55/EY - 2006/51H", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "100", "text": "2005/55/EY - 2006/51I", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "101", "text": "2005/55/EY - 2006/51J", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "102", "text": "2005/55/EY - 2006/51K", "lng": "fi"},
  {"code": "KdTyytiPaastolajinTestausmenetelma", "codeValue": "103", "text": "97/24/EY 5 luku - 2003/77", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "01", "text": "Trevägskatalysator", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "01", "text": "Kolmitoimikatalysaattori", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "02", "text": "OBD-system", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "02", "text": "OBD-järjestelmä", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "03", "text": "Katalysator", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "03", "text": "Katalysaattori", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "04", "text": "Syredetektor", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "06", "text": "Återcirkulation av avgaserna", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "06", "text": "Pakokaasun takaisinkierrätys", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "07", "text": "Övervakningssystem för avdunstningsutsläpp", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "07", "text": "Haihtumispäästöjen valvontajärjestelmä", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "08", "text": "Partikelfälla", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "08", "text": "Hiukkasloukku", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "09", "text": "Lågemission", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "09", "text": "Vähäpäästöinen", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "10", "text": "Ureainsprutning", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "04", "text": "Happitunnistin", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "05", "text": "Luftinsprutning eller luftpump", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "05", "text": "Ilman suihkutus tai ilmapumppu", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "10", "text": "Urearuiskutus", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "11", "text": "Vridmomentsbegränsare", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "11", "text": "Vääntömomentin rajoitin", "lng": "fi"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "Z", "text": "Annan, vilken?", "lng": "sv"},
  {"code": "KdTyytiPakokaasujenPuhdistuslaite", "codeValue": "Z", "text": "Muu, mikä...", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "0", "text": "Maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "0", "text": "Naturgas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "1", "text": "Bensiini", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "1", "text": "Bensin", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "2", "text": "Moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "2", "text": "Motorpetrol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "3", "text": "Dieselöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "3", "text": "Dieselölja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "4", "text": "Nestekaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "4", "text": "Flytgas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "57", "text": "L-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "57", "text": "Naturgastyp L", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "58", "text": "HL-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "58", "text": "Naturgastyp HL", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "59", "text": "CNG/Biometaani", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "59", "text": "CNG/Biometan", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "60", "text": "Biometaani", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "60", "text": "Biometan", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "61", "text": "Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "61", "text": "Träd", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "62", "text": "Etanoli (ED95)", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "62", "text": "Etanol (ED95)", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "63", "text": "Etanoli (E85)", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "63", "text": "Etanol (E85)", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "64", "text": "Vety-maakaasuseos", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "64", "text": "H2NG-blandning", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "65", "text": "LNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "65", "text": "LNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "66", "text": "LNG20", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "66", "text": "LNG20", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "67", "text": "Diesel/LNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "67", "text": "Diesel/LNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "68", "text": "Diesel/LNG20", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "68", "text": "Diesel/LNG20", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "45", "text": "Diesel/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "45", "text": "Diesel/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "46", "text": "Diesel/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "46", "text": "Diesel/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "47", "text": "Diesel/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "47", "text": "Diesel/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "48", "text": "Diesel/Biodiesel", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "48", "text": "Diesel/Biodiesel", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "49", "text": "Diesel/Biodiesel/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "49", "text": "Diesel/Biodiesel/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "50", "text": "Diesel/Biodiesel/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "50", "text": "Diesel/Biodiesel/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "51", "text": "Diesel/Biodiesel/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "51", "text": "Diesel/Biodiesel/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "52", "text": "Diesel/Biodiesel/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "52", "text": "Diesel/Biodiesel/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "53", "text": "Diesel/Biodiesel/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "53", "text": "Diesel/Biodiesel/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "54", "text": "Hydrogen/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "54", "text": "Vety/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "55", "text": "Dieselolja/Övrig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "10", "text": "Biodiesel", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "10", "text": "Biodiesel", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "11", "text": "LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "11", "text": "LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "13", "text": "CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "13", "text": "CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "31", "text": "Moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "31", "text": "Motorfotogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "32", "text": "Diesel/Trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "32", "text": "Diesel/Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "55", "text": "Dieselöljy/Muu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "33", "text": "Bensiini/Puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "33", "text": "Bensin/Trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "34", "text": "Bensiini + moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "34", "text": "Bensin + motorfotogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "37", "text": "Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "37", "text": "Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "38", "text": "Bensiini/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "38", "text": "Bensin/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "39", "text": "Bensiini/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "39", "text": "Bensin/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "40", "text": "Bensiini/Etanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "40", "text": "Bensin/Etanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "41", "text": "Bensiini/Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "41", "text": "Bensin/Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "42", "text": "Bensiini/LPG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "42", "text": "Bensin/LPG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "43", "text": "Diesel/CNG", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "43", "text": "Diesel/CNG", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "44", "text": "Diesel/Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "44", "text": "Diesel/Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "5", "text": "Diesel/puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "5", "text": "Diesel/trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "6", "text": "Bensiini/puu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "6", "text": "Bensin/trä", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "7", "text": "Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "7", "text": "El", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "8", "text": "Bensiini+moottoripetroli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "8", "text": "Bensin+motorpetrol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "9", "text": "Se anmärkn.", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "9", "text": "Ks. huom.", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "01", "text": "Bensiini", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "01", "text": "Bensin", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "02", "text": "Dieselöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "02", "text": "Dieselolja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "03", "text": "Polttoöljy", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "03", "text": "Brännolja", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "04", "text": "Sähkö", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "04", "text": "Elektricitet", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "05", "text": "Vety", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "05", "text": "Hydrogen", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "06", "text": "Kaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "06", "text": "Gas", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "07", "text": "Metanol", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "07", "text": "Metanoli", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "56", "text": "H-ryhmän maakaasu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "56", "text": "Naturgastyp H", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "X", "text": "Ej tillämplig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "Y", "text": "Övrig", "lng": "sv"},
  {"code": "KdTyytiPolttoaine", "codeValue": "69", "text": "Seos", "lng": "fi"},
  {"code": "KdTyytiPolttoaine", "codeValue": "69", "text": "Mix", "lng": "sv"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "1", "text": "Kokotela", "lng": "fi"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "2", "text": "Puolitela", "lng": "fi"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "1", "text": "Helt banddriven", "lng": "sv"},
  {"code": "KdTyytiTelatyyppi", "codeValue": "2", "text": "Delvis banddriven", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "8", "text": "Fast förhållande", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "10", "text": "Hjulnav", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "9", "text": "Automatisoitu", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "10", "text": "Pyörännapa", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "8", "text": "Kiinteä välityssuhde", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "9", "text": "Automatiserad", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "1", "text": "Manuell", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "1", "text": "Käsivalintainen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "2", "text": "Automatisk", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "2", "text": "Automaattinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "3", "text": "Steglös", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "3", "text": "Portaaton", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "4", "text": "Manuell/automatisk", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "4", "text": "Käsivalintainen/automaattinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "5", "text": "Variator", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "5", "text": "Variaattori", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "6", "text": "Variabel utväxling", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "6", "text": "Muuttuvavälityksinen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "7", "text": "Fotväxlad", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "7", "text": "Jalkavalintainen", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "X", "text": "Ej tillämpligt", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "X", "text": "Ei sovellettavissa", "lng": "fi"},
  {"code": "KdTyytiVaihteisto", "codeValue": "Y", "text": "Annan", "lng": "sv"},
  {"code": "KdTyytiVaihteisto", "codeValue": "Y", "text": "Muu", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "1", "text": "Brun (beige)", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "2", "text": "Punainen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "0", "text": "Musta", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "0", "text": "Svart", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "1", "text": "Ruskea (beige)", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "2", "text": "Röd", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "3", "text": "Oranssi", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "3", "text": "Orange", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "4", "text": "Keltainen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "4", "text": "Gul", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "5", "text": "Vihreä", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "5", "text": "Grön", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "6", "text": "Sininen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "6", "text": "Blå", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "7", "text": "Violetti", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "7", "text": "Violett", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "8", "text": "Harmaa", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "8", "text": "Grå", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "9", "text": "Valkoinen", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "9", "text": "Vit", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "X", "text": "Monivär.", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "X", "text": "Flerfärg", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "Y", "text": "Hopea", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "Y", "text": "Silver", "lng": "sv"},
  {"code": "KdTyytiVari", "codeValue": "Z", "text": "Turkoosi", "lng": "fi"},
  {"code": "KdTyytiVari", "codeValue": "Z", "text": "Turkos", "lng": "sv"},

  {"code": "Maat", "codeValue": "040", "text": "Itävalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "040", "text": "Österrike", "lng": "sv"},
  {"code": "Maat", "codeValue": "056", "text": "Belgia", "lng": "fi"},
  {"code": "Maat", "codeValue": "056", "text": "Belgien", "lng": "sv"},
  {"code": "Maat", "codeValue": "070", "text": "Bosnia-Hertsegovina", "lng": "fi"},
  {"code": "Maat", "codeValue": "070", "text": "Bosnien-Hercegovina", "lng": "sv"},
  {"code": "Maat", "codeValue": "076", "text": "Brasilia", "lng": "fi"},
  {"code": "Maat", "codeValue": "076", "text": "Brasilien", "lng": "sv"},
  {"code": "Maat", "codeValue": "100", "text": "Bulgaria", "lng": "fi"},
  {"code": "Maat", "codeValue": "100", "text": "Bulgarien", "lng": "sv"},
  {"code": "Maat", "codeValue": "124", "text": "Canada", "lng": "sv"},
  {"code": "Maat", "codeValue": "124", "text": "Kanada", "lng": "fi"},
  {"code": "Maat", "codeValue": "156", "text": "Kiinan kansantasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "156", "text": "Kina", "lng": "sv"},
  {"code": "Maat", "codeValue": "158", "text": "Taiwan", "lng": "fi"},
  {"code": "Maat", "codeValue": "158", "text": "Taiwan", "lng": "sv"},
  {"code": "Maat", "codeValue": "191", "text": "Kroatia", "lng": "fi"},
  {"code": "Maat", "codeValue": "191", "text": "Kroatien", "lng": "sv"},
  {"code": "Maat", "codeValue": "200", "text": "Tjeckoslovakien", "lng": "sv"},
  {"code": "Maat", "codeValue": "200", "text": "Tsekkoslovakia", "lng": "fi"},
  {"code": "Maat", "codeValue": "203", "text": "Tjeckien", "lng": "sv"},
  {"code": "Maat", "codeValue": "203", "text": "Tsekki", "lng": "fi"},
  {"code": "Maat", "codeValue": "208", "text": "Danmark", "lng": "sv"},
  {"code": "Maat", "codeValue": "208", "text": "Tanska", "lng": "fi"},
  {"code": "Maat", "codeValue": "233", "text": "Estland", "lng": "sv"},
  {"code": "Maat", "codeValue": "233", "text": "Viro", "lng": "fi"},
  {"code": "Maat", "codeValue": "246", "text": "Finland", "lng": "sv"},
  {"code": "Maat", "codeValue": "246", "text": "Suomi", "lng": "fi"},
  {"code": "Maat", "codeValue": "248", "text": "Ahvenanmaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "248", "text": "Åland", "lng": "sv"},
  {"code": "Maat", "codeValue": "249", "text": "", "lng": "sv"},
  {"code": "Maat", "codeValue": "249", "text": "Ranskan emämaa", "lng": "fi"},
  {"code": "Maat", "codeValue": "250", "text": "Frankrike", "lng": "sv"},
  {"code": "Maat", "codeValue": "250", "text": "Ranska", "lng": "fi"},
  {"code": "Maat", "codeValue": "276", "text": "Saksa", "lng": "fi"},
  {"code": "Maat", "codeValue": "276", "text": "Tyskland", "lng": "sv"},
  {"code": "Maat", "codeValue": "278", "text": "Saksan demokraattinen tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "278", "text": "Tyska demokratiska republiken", "lng": "sv"},
  {"code": "Maat", "codeValue": "300", "text": "Grekland", "lng": "sv"},
  {"code": "Maat", "codeValue": "300", "text": "Kreikka", "lng": "fi"},
  {"code": "Maat", "codeValue": "348", "text": "Ungern", "lng": "sv"},
  {"code": "Maat", "codeValue": "348", "text": "Unkari", "lng": "fi"},
  {"code": "Maat", "codeValue": "356", "text": "Indien", "lng": "sv"},
  {"code": "Maat", "codeValue": "356", "text": "Intia", "lng": "fi"},
  {"code": "Maat", "codeValue": "360", "text": "Indonesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "360", "text": "Indonesien", "lng": "sv"},
  {"code": "Maat", "codeValue": "372", "text": "Irland", "lng": "sv"},
  {"code": "Maat", "codeValue": "372", "text": "Irlanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "376", "text": "Israel", "lng": "fi"},
  {"code": "Maat", "codeValue": "376", "text": "Israel", "lng": "sv"},
  {"code": "Maat", "codeValue": "380", "text": "Italia", "lng": "fi"},
  {"code": "Maat", "codeValue": "380", "text": "Italien", "lng": "sv"},
  {"code": "Maat", "codeValue": "392", "text": "Japan", "lng": "sv"},
  {"code": "Maat", "codeValue": "392", "text": "Japani", "lng": "fi"},
  {"code": "Maat", "codeValue": "410", "text": "Korean tasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "410", "text": "Republiken Korea", "lng": "sv"},
  {"code": "Maat", "codeValue": "428", "text": "Latvia", "lng": "fi"},
  {"code": "Maat", "codeValue": "428", "text": "Lettland", "lng": "sv"},
  {"code": "Maat", "codeValue": "440", "text": "Liettua", "lng": "fi"},
  {"code": "Maat", "codeValue": "440", "text": "Litauen", "lng": "sv"},
  {"code": "Maat", "codeValue": "442", "text": "Luxemburg", "lng": "fi"},
  {"code": "Maat", "codeValue": "442", "text": "Luxemburg", "lng": "sv"},
  {"code": "Maat", "codeValue": "458", "text": "Malaysia", "lng": "sv"},
  {"code": "Maat", "codeValue": "458", "text": "Malesia", "lng": "fi"},
  {"code": "Maat", "codeValue": "470", "text": "Malta", "lng": "fi"},
  {"code": "Maat", "codeValue": "470", "text": "Malta", "lng": "sv"},
  {"code": "Maat", "codeValue": "484", "text": "Meksiko", "lng": "fi"},
  {"code": "Maat", "codeValue": "484", "text": "Mexico", "lng": "sv"},
  {"code": "Maat", "codeValue": "499", "text": "Montenegro", "lng": "fi"},
  {"code": "Maat", "codeValue": "499", "text": "Montenegro", "lng": "sv"},
  {"code": "Maat", "codeValue": "504", "text": "Marocko", "lng": "sv"},
  {"code": "Maat", "codeValue": "504", "text": "Marokko", "lng": "fi"},
  {"code": "Maat", "codeValue": "528", "text": "Alankomaat", "lng": "fi"},
  {"code": "Maat", "codeValue": "528", "text": "Nederländerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "554", "text": "Nya Zeeland", "lng": "sv"},
  {"code": "Maat", "codeValue": "554", "text": "Uusi-Seelanti", "lng": "fi"},
  {"code": "Maat", "codeValue": "578", "text": "Norge", "lng": "sv"},
  {"code": "Maat", "codeValue": "578", "text": "Norja", "lng": "fi"},
  {"code": "Maat", "codeValue": "608", "text": "Filippiinit", "lng": "fi"},
  {"code": "Maat", "codeValue": "608", "text": "Filippinerna", "lng": "sv"},
  {"code": "Maat", "codeValue": "616", "text": "Polen", "lng": "sv"},
  {"code": "Maat", "codeValue": "616", "text": "Puola", "lng": "fi"},
  {"code": "Maat", "codeValue": "620", "text": "Portugal", "lng": "sv"},
  {"code": "Maat", "codeValue": "620", "text": "Portugali", "lng": "fi"},
  {"code": "Maat", "codeValue": "642", "text": "Romania", "lng": "fi"},
  {"code": "Maat", "codeValue": "642", "text": "Rumänien", "lng": "sv"},
  {"code": "Maat", "codeValue": "643", "text": "Ryssland", "lng": "sv"},
  {"code": "Maat", "codeValue": "643", "text": "Venäjä", "lng": "fi"},
  {"code": "Maat", "codeValue": "688", "text": "Serbia", "lng": "fi"},
  {"code": "Maat", "codeValue": "688", "text": "Serbien", "lng": "sv"},
  {"code": "Maat", "codeValue": "703", "text": "Slovakia", "lng": "fi"},
  {"code": "Maat", "codeValue": "703", "text": "Slovakien", "lng": "sv"},
  {"code": "Maat", "codeValue": "705", "text": "Slovenia", "lng": "fi"},
  {"code": "Maat", "codeValue": "705", "text": "Slovenien", "lng": "sv"},
  {"code": "Maat", "codeValue": "710", "text": "Etelä-Afrikka", "lng": "fi"},
  {"code": "Maat", "codeValue": "710", "text": "Sydafrika", "lng": "sv"},
  {"code": "Maat", "codeValue": "724", "text": "Espanja", "lng": "fi"},
  {"code": "Maat", "codeValue": "724", "text": "Spanien", "lng": "sv"},
  {"code": "Maat", "codeValue": "752", "text": "Ruotsi", "lng": "fi"},
  {"code": "Maat", "codeValue": "752", "text": "Sverige", "lng": "sv"},
  {"code": "Maat", "codeValue": "756", "text": "Schweiz", "lng": "sv"},
  {"code": "Maat", "codeValue": "756", "text": "Sveitsi", "lng": "fi"},
  {"code": "Maat", "codeValue": "788", "text": "Tunisia", "lng": "fi"},
  {"code": "Maat", "codeValue": "788", "text": "Tunisien", "lng": "sv"},
  {"code": "Maat", "codeValue": "792", "text": "Turkiet", "lng": "sv"},
  {"code": "Maat", "codeValue": "792", "text": "Turkki", "lng": "fi"},
  {"code": "Maat", "codeValue": "804", "text": "Ukraina", "lng": "fi"},
  {"code": "Maat", "codeValue": "804", "text": "Ukraina", "lng": "sv"},
  {"code": "Maat", "codeValue": "807", "text": "Makedonia", "lng": "fi"},
  {"code": "Maat", "codeValue": "807", "text": "Makedonien", "lng": "sv"},
  {"code": "Maat", "codeValue": "810", "text": "Neuvostoliitto", "lng": "fi"},
  {"code": "Maat", "codeValue": "810", "text": "Sovjetunionen", "lng": "sv"},
  {"code": "Maat", "codeValue": "826", "text": "Iso-Britannia", "lng": "fi"},
  {"code": "Maat", "codeValue": "826", "text": "Storbritannien", "lng": "sv"},
  {"code": "Maat", "codeValue": "840", "text": "Förenta Staterna", "lng": "sv"},
  {"code": "Maat", "codeValue": "840", "text": "Yhdysvallat", "lng": "fi"},
  {"code": "Maat", "codeValue": "851", "text": "Förenade kungariket", "lng": "sv"},
  {"code": "Maat", "codeValue": "851", "text": "Yhdistynyt kuningaskunta", "lng": "fi"},
  {"code": "Maat", "codeValue": "890", "text": "Jugoslavia", "lng": "fi"},
  {"code": "Maat", "codeValue": "890", "text": "Jugoslavien", "lng": "sv"},
  {"code": "Maat", "codeValue": "901", "text": "Jugoslavian liittotasavalta", "lng": "fi"},
  {"code": "Maat", "codeValue": "901", "text": "Jugoslavian liittotasavalta", "lng": "sv"},
  {"code": "Maat", "codeValue": "902", "text": "EU-land", "lng": "sv"},
  {"code": "Maat", "codeValue": "902", "text": "EU-maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "903", "text": "EES-land", "lng": "sv"},
  {"code": "Maat", "codeValue": "903", "text": "ETA-maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "904", "text": "muu EUn ulkopuolinen maa", "lng": "fi"},
  {"code": "Maat", "codeValue": "904", "text": "övrigt land utanför EU", "lng": "sv"},
  {"code": "Maat", "codeValue": "905", "text": "Kosovo", "lng": "fi"},
  {"code": "Maat", "codeValue": "905", "text": "Kosovo", "lng": "sv"},
  {"code": "Maat", "codeValue": "997", "text": "Ei tiedossa", "lng": "fi"},
  {"code": "Maat", "codeValue": "997", "text": "Okänd", "lng": "sv"},
  {"code": "Maat", "codeValue": "998", "text": "Tieto selväkielisenä", "lng": "fi"},
  {"code": "Maat", "codeValue": "998", "text": "Uppgifterna i klartext", "lng": "sv"},
  {"code": "Maat", "codeValue": "999", "text": "Ei käytettävissä", "lng": "fi"},
  {"code": "Maat", "codeValue": "999", "text": "Ej användbar", "lng": "sv"},

]

// 7124 code matches found

export default TCODES
